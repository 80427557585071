

import { KTCard } from '../../../../../_metronic/helpers'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { RelatorioListHeader } from './components/header/RelatorioListHeader'
import { RelatorioTable } from './table/RelatorioTable'

const RelatorioList = () => {
  // const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <RelatorioListHeader />
        <RelatorioTable />
      </KTCard>
    </>
  )
}

const RelatorioListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <RelatorioList />
    </QueryResponseProvider>
  </QueryRequestProvider>

)

export { RelatorioListWrapper }
