import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { CargoCliente, CargosClienteQueryResponse, ColaboradorPcmso, ColaboradorPcmsoQueryResponse, Empresa, EmpresaCliente, EmpresaClienteQueryResponse, EmpresasClienteAsoQueryResponse, EmpresasClienteQueryResponse, EmpresasQueryResponse, Endereco, ExameCliente, ExamesClienteQueryResponse, RiscoCliente, RiscosClienteQueryResponse, SetorCliente, SetoresClienteQueryResponse, TipoEndereco } from './_models'
import { AsoEmitido } from '../../../atendimento/core/_models'
import { adicionaFatorRisco } from '../../../atendimento/core/_requests'

const API_URL = process.env.REACT_APP_API_URL
const TIPO_ENDERECO_URL = `${API_URL}/endereco/tipos`
const EMPRESA_URL = `${API_URL}/empresas`
const GET_EMPRESAS_URL = `${API_URL}/empresas`
const GET_EMPRESAS_CLIENTE_URL = `${API_URL}/empresas/cliente`
const GET_CARGOS_CLIENTE_URL = `${API_URL}/empresas/cliente/cargo`
const GET_CARGOS_SETOR_URL = `${API_URL}/empresas/cliente/setor`
const GET_EXAMES_URL = `${API_URL}/empresas/cliente/exame`
const GET_RISCOS_CLIENTE_URL = `${API_URL}/empresas/cliente/risco`
const GET_ASO_AUTENTICADO_URL = `${API_URL}/relatorio`

const getEmpresas = (query: string): Promise<EmpresasQueryResponse> => {
  return axios
    .get(`${GET_EMPRESAS_URL}?${query}`)
    .then((d: AxiosResponse<EmpresasQueryResponse>) => d.data)
}
const getEmpresaById = (id: ID): Promise<Empresa | undefined> => {
  return axios
    .get(`${EMPRESA_URL}/${id}`)
    .then((response: AxiosResponse<Empresa>) => response.data)
}
const getClienteById = (id: ID): Promise<Empresa | undefined> => {
  return axios
    .get(`${EMPRESA_URL}/cliente/${id}`)
    .then((response: AxiosResponse<Empresa>) => response.data)
}
export const getEnderecoByIdEmpresa = (id: ID): Promise<Endereco | undefined> => {
  return axios
    .get(`${EMPRESA_URL}/endereco/${id}`)
    .then((response: AxiosResponse<Endereco>) => response.data)
}
export function getTiposEndereco() {
  return axios.get<TipoEndereco[]>(TIPO_ENDERECO_URL)
    .then((response: AxiosResponse<TipoEndereco[]>) => response.data)
}
const createEmpresa = (
  nome: string,
  nomeFantasia: string,
  cnpj: string,
  tiposAtendimento: String[],
  permiteNovoPaciente: boolean,
  grupoTelegram: string
): Promise<undefined> => {
  return axios
    .post(EMPRESA_URL, {
      nome,
      nomeFantasia,
      cnpj,
      permiteNovoPaciente: permiteNovoPaciente,
      tiposAtendimento,
      grupoTelegram
    }).then((response) => {
      return response.data
    }) 
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}
function removeAllSpaces(str: string) {
  return str.replace(/\s+/g, ''); // Remove todos os espaços em branco
}
const createEmpresaClienteAso = (idEmpresa: ID, nome: string, cnpj: string | null, tipo: string, cpf: string | null, caepf: string | null): Promise<EmpresaCliente | undefined> => {
  return axios.post(`${EMPRESA_URL}/cliente/${idEmpresa}`, {
    nome: nome,
    cnpj: tipo !== 'CPF' && tipo !== 'CAEPF' ? removeAllSpaces(cnpj as string) : null,
    cpf: tipo !== 'CNPJ' && tipo !== 'CAEPF' ? removeAllSpaces(cpf as string) : null,
    caepf: tipo !== 'CPF' && tipo !== 'CNPJ' ? removeAllSpaces(caepf as string) : null,
    tipo: tipo
  })
}
const EditEmpresaClienteAso = (idEmpresaCliente: ID, nome: string, cnpj: string | null, tipo: string, cpf: string | null, caepf: string | null): Promise<EmpresaCliente | undefined> => {
  return axios.put(`${EMPRESA_URL}/cliente/${idEmpresaCliente}`, {
    nome: nome,
    cnpj: tipo !== 'CPF' && tipo !== 'CAEPF' ? removeAllSpaces(cnpj as string) : null,
    cpf: tipo !== 'CNPJ' && tipo !== 'CAEPF' ? removeAllSpaces(cpf as string) : null,
    caepf: tipo !== 'CPF' && tipo !== 'CNPJ' ? removeAllSpaces(caepf as string) : null,
    tipo: tipo
  })
}
export const getEmpresasCliente = (query: string): Promise<EmpresasClienteQueryResponse> => {
  return axios
    .get(`${GET_EMPRESAS_CLIENTE_URL}?${query}`)
    .then((d: AxiosResponse<EmpresasClienteQueryResponse>) => d.data)
}
export const getEmpresaClienteById = (idEmpresaCliente: ID): Promise<EmpresaCliente | undefined> => {
  return axios
    .get(`${GET_EMPRESAS_CLIENTE_URL}/${idEmpresaCliente}`)
    .then((response: AxiosResponse<EmpresaCliente>) => response.data)
}
const deleteEmpresaClienteAso = (idEmpresaCliente: ID) => {
  return axios.delete(`${EMPRESA_URL}/cliente/${idEmpresaCliente}`);
}
export const deleteSelectedEmpresaCliente = (empresaIds: Array<ID>): Promise<void> => {
  const requests = empresaIds.map((id) => axios.delete(`${EMPRESA_URL}/cliente/${id}`))
  return axios.all(requests).then(() => { })
}
export const getEmpresaClienteAso = (idEmpresa: ID, query: string): Promise<EmpresasClienteAsoQueryResponse> => {
  //MUDAR PARA MANDAR POR SEARCH O IDMEPRESA
  return axios
    .get(`${GET_EMPRESAS_CLIENTE_URL}?idEmpresa=${idEmpresa}&${query}`)
    .then((d: AxiosResponse<EmpresasClienteAsoQueryResponse>) => d.data)
}
export const getColaboradoresPCMSO = (idEmpresa: ID,): Promise<ColaboradorPcmsoQueryResponse> => {
  return axios
    .get(`${GET_EMPRESAS_CLIENTE_URL}/colaborador/${idEmpresa}`)
    .then((d: AxiosResponse<ColaboradorPcmsoQueryResponse>) => d.data)
}
export const getColaboradorPcmso = (idEmpresa: ID) => {
  return axios
    .get(`${GET_EMPRESAS_URL}/cliente/colaborador/${idEmpresa}`)
    .then((response: AxiosResponse<ColaboradorPcmso[] | undefined>) => response.data)
}
export const createColaboradorPcmso = (idColaborador: ID, nome: string, crm: string, rqe: string, uf: string) => {
  return axios.post(`${EMPRESA_URL}/cliente/colaborador/${idColaborador}`, {
    nome: nome,
    crm: crm,
    rqe: rqe,
    uf: uf
  })
}
export const alteraCoordenadorPcmso = (idEmpresaCliente: ID, idColaborador: ID, nome: string, crm: string, rqe: string, uf: string): Promise<ColaboradorPcmso | undefined> => {
  return axios.put(`${EMPRESA_URL}/cliente/colaborador/${idEmpresaCliente}`, {
    id: idColaborador,
    nome: nome,
    crm: crm,
    rqe: rqe,
    uf: uf
  })
}
export const deleteColaboradorCliente = (idEmpresaCliente: ID, idCoordenador: ID) => {
  return axios.delete(`${EMPRESA_URL}/cliente/colaborador/${idEmpresaCliente}`, {
    data: {
      id: idCoordenador
    }
  });
}
export const getAsoAutenticado = (hashAuth: string) => {
  return axios.get(`${GET_ASO_AUTENTICADO_URL}/${hashAuth}`)
    .then((response: AxiosResponse<AsoEmitido>) => response.data)
}

//Cargos empresas clientes
export const getCargosCliente = (idEmpresaCliente: ID, query: string): Promise<CargosClienteQueryResponse> => {
  return axios
    .get(`${GET_CARGOS_CLIENTE_URL}?idEmpresa=${idEmpresaCliente}&${query}`)
    .then((d: AxiosResponse<CargosClienteQueryResponse>) => d.data)
}
export const getCargoClienteById = (idCargo: ID): Promise<CargoCliente | undefined> => {
  return axios
    .get(`${GET_CARGOS_CLIENTE_URL}/${idCargo}`)
    .then((response: AxiosResponse<CargoCliente>) => response.data)
}
export const createCadastroCargo = (idEmpresaCliente: ID, nome: string, codigo: string): Promise<CargoCliente | undefined> => {
  return axios.post(`${GET_CARGOS_CLIENTE_URL}/${idEmpresaCliente}`, {
    codigo: codigo,
    nome: nome
  })
}
export const EditCargoClienteAso = (idEmpresaCliente: ID, nome: string, codigo: string): Promise<CargoCliente | undefined> => {
  return axios.put(`${GET_CARGOS_CLIENTE_URL}/${idEmpresaCliente}`, {
    codigo: codigo,
    nome: nome
  })
}
export const deleteCargoCliente = (idCargo: ID) => {
  return axios.delete(`${GET_CARGOS_CLIENTE_URL}/${idCargo}`);
}
export const deleteSelectedCargosCliente = (cargoIds: Array<ID>): Promise<void> => {
  const requests = cargoIds.map((id) => axios.delete(`${GET_CARGOS_CLIENTE_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

//Setores empresas clientes
export const getSetoresCliente = (idEmpresaCliente: ID, query: string): Promise<SetoresClienteQueryResponse> => {
  return axios
    .get(`${GET_CARGOS_SETOR_URL}?idEmpresa=${idEmpresaCliente}&${query}`)
    .then((d: AxiosResponse<CargosClienteQueryResponse>) => d.data)
}
export const getSetorClienteById = (idSetor: ID): Promise<SetorCliente | undefined> => {
  return axios
    .get(`${GET_CARGOS_SETOR_URL}/${idSetor}`)
    .then((response: AxiosResponse<SetorCliente>) => response.data)
}
export const createCadastroSetor = (idEmpresaCliente: ID, nome: string): Promise<SetorCliente | undefined> => {
  return axios.post(`${GET_CARGOS_SETOR_URL}/${idEmpresaCliente}`, {
    nome: nome
  })
}
export const EditSetorClienteAso = (idEmpresaCliente: ID, nome: string): Promise<SetorCliente | undefined> => {
  return axios.put(`${GET_CARGOS_SETOR_URL}/${idEmpresaCliente}`, {
    nome: nome
  })
}
export const deleteSetorCliente = (idSetor: ID) => {
  return axios.delete(`${GET_CARGOS_SETOR_URL}/${idSetor}`);
}
export const deleteSelectedSetoresCliente = (setorIds: Array<ID>): Promise<void> => {
  const requests = setorIds.map((id) => axios.delete(`${GET_CARGOS_SETOR_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

//Exames empresas clientes
export const getExamesCliente = (idEmpresaCliente: ID, query: string): Promise<ExamesClienteQueryResponse> => {
  return axios
    .get(`${GET_EXAMES_URL}?idEmpresa=${idEmpresaCliente}&${query}`)
    .then((d: AxiosResponse<ExamesClienteQueryResponse>) => d.data)
}
export const getExameClienteById = (idExame: ID): Promise<ExameCliente | undefined> => {
  return axios
    .get(`${GET_EXAMES_URL}/${idExame}`)
    .then((response: AxiosResponse<ExameCliente>) => response.data)
}
export const createCadastroExame = (idEmpresaCliente: ID, idCargo: ID, idSetor: ID, exame: string): Promise<ExameCliente | undefined> => {
  return axios.post(`${GET_EXAMES_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    exame: exame
  })
}
export const EditExameClienteAso = (idEmpresaCliente: ID, idCargo: ID, idSetor: ID, exame: string, ativo: boolean): Promise<ExameCliente | undefined> => {
  return axios.put(`${GET_EXAMES_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    exame: exame,
    ativo: ativo
  })
}
export const deleteExameCliente = (idSetor: ID) => {
  return axios.delete(`${GET_EXAMES_URL}/${idSetor}`);
}
export const deleteSelectedExamesCliente = (examesIds: Array<ID>): Promise<void> => {
  const requests = examesIds.map((id) => axios.delete(`${GET_EXAMES_URL}/${id}`))
  return axios.all(requests).then(() => { })
}


//Riscos empresas clientes
export const getRiscosCliente = (idEmpresaCliente: ID, query: string, cliente: ID): Promise<RiscosClienteQueryResponse> => {
  return axios
    .get(`${GET_RISCOS_CLIENTE_URL}?empresa=${idEmpresaCliente}&search=cliente.id:${cliente}&${query}`)
    .then((d: AxiosResponse<RiscosClienteQueryResponse>) => d.data)
}
export const getRiscoClienteById = (idRisco: ID): Promise<RiscoCliente | undefined> => {
  return axios
    .get(`${GET_RISCOS_CLIENTE_URL}/${idRisco}`)
    .then((response: AxiosResponse<RiscoCliente>) => response.data)
}
export const createRisco = (idEmpresaCliente: ID, idCargo: ID, idSetor: ID, fatorRisco: string, descricao: string): Promise<RiscoCliente | undefined> => {
  return axios.post(`${GET_RISCOS_CLIENTE_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    fatorRisco: fatorRisco,
    descricao: descricao
  })
}
export const vincularRiscosClienteAtendimento = (idAtendimento: ID, riscos: Array<RiscoCliente>): Promise<void> => {
  const requests = riscos.map((risco) => adicionaFatorRisco(idAtendimento, String(risco.fatorRisco), '0'))
  return axios.all(requests).then(() => { })
}
export const editRiscoAso = (idEmpresaCliente: ID, idCargo: ID, idSetor: ID, fatorRisco: string, descricao: string): Promise<RiscoCliente | undefined> => {
  return axios.put(`${GET_RISCOS_CLIENTE_URL}/${idEmpresaCliente}`, {
    cargo: idCargo,
    setor: idSetor,
    fatorRisco: fatorRisco,
    descricao: descricao
  })
}
export const deleteRiscoCliente = (idRisco: ID) => {
  return axios.delete(`${GET_RISCOS_CLIENTE_URL}/${idRisco}`);
}
export const deleteSelectedRiscosCliente = (riscoIds: Array<ID>): Promise<void> => {
  const requests = riscoIds.map((id) => axios.delete(`${GET_RISCOS_CLIENTE_URL}/${id}`))
  return axios.all(requests).then(() => { })
}



export const createEnderecoEmpresa = (idEmpresa: ID,
  tipo: Number,
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  uf: string
): Promise<Endereco | undefined> => {
  return axios
    .post(`${EMPRESA_URL}/endereco/${idEmpresa}`, {
      tipo: tipo,
      cep: cep,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      uf: uf
    })

}
export const updateEnderecoEmpresa = (idEmpresa: ID,
  idEndereco: Number,
  tipo: Number,
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  uf: string
): Promise<Endereco | undefined> => {
  return axios
    .put(`${EMPRESA_URL}/endereco/${idEmpresa}`, {
      id: idEndereco,
      tipo: tipo,
      cep: cep,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      uf: uf
    })

}

const updateEmpresa = (
  idEmpresa: ID,
  nome: string,
  nomeFantasia: string,
  cnpj: string,
  tiposAtendimento: String[],
  permiteNovoPaciente: boolean,
  grupoTelegram: string
): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      id: idEmpresa,
      nome,
      nomeFantasia,
      cnpj,
      permiteNovoPaciente: permiteNovoPaciente,
      tiposAtendimento,
      grupoTelegram,
    }).then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}

const deleteEmpresa = (empresaId: ID): Promise<void> => {
  return axios.delete(`${EMPRESA_URL}/${empresaId}`);
}
export const inativarEmpresa = (
  idEmpresa: ID,
): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      ativa: false
    }).then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}

export const ativarEmpresa = (
  idEmpresa: ID,
): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      ativa: true
    }).then((response) => {
      return response.data
    })
  // .then((response: AxiosResponse<Response<Empresa>>) => response.data)
  // .then((response: Response<Empresa>) => response.data)
}
const deleteSelectedEmpresa = (empresaIds: Array<ID>): Promise<void> => {
  const requests = empresaIds.map((id) => axios.delete(`${EMPRESA_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getEmpresas, createEmpresaClienteAso, EditEmpresaClienteAso, deleteEmpresaClienteAso, deleteEmpresa, deleteSelectedEmpresa, getEmpresaById, createEmpresa, updateEmpresa, getClienteById }
