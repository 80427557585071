import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getExameClienteById } from '../../empresas-list/core/_requests'
import { ExameClienteEditModalForm } from './ExameClienteEditModalForm'


const ExameClienteEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: setor,
    error,
  } = useQuery(
    `${QUERIES.EMPRESAS_LIST}-empresa-${itemIdForUpdate}`,
    () => {
      return getExameClienteById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <ExameClienteEditModalForm isEmpresaLoading={isLoading} exame={{ id: undefined }} />
  }

  if (!isLoading && !error && setor) {
    return <ExameClienteEditModalForm isEmpresaLoading={isLoading} exame={setor} />
  }

  return null
}

export { ExameClienteEditModalFormWrapper }
