import { type } from 'os'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { Pessoa } from '../../../funcionarios-management/funcionarios-list/core/_models'

export type Empresa = {
  id?: ID
  nome?: string
  nomeFantasia?: string
  responsavel?: Responsavel
  cnpj?: string
  tiposAtendimento?: string[]
  permiteNovoPaciente?: boolean
  ativa?: boolean
  endereco?: Endereco
  tiposAtendimentoMap?: TipoAtendimentoMap[]
  grupoTelegram?: string
  empresa?: Empresa
}
export type TipoAtendimentoMap = {
  valor?: string
  name?: string
  descricao?: string
}
export type Responsavel = {
  id?: ID,
  nome?: string,
  primeiroNome?: string,
  sobreNome?: string
  cpf?: string
}

export type EmpresaCliente = {
  id?: ID,
  nome?: string,
  colaboradores: ColaboradorPcmso[]
  empresa?: Empresa,
  cnpj?: string
  cpf?: string,
  caepf: string,
  documento?: string,
  tipoDocumento?: string,
}
export type CargoCliente = {
  id?: ID,
  nome?: string,
  codigo?: string,
  empresa?: Empresa,
  cliente?: EmpresaCliente
}
export type SetorCliente = {
  id?: ID,
  nome?: string,
  ativo?: boolean,

}
export type ExameCliente = {
  id?: ID,
  exame?: string,
  cliente?: EmpresaCliente,
  cargo?: CargoCliente,
  setor?: SetorCliente,
  ativo?: boolean
}
export type RiscoCliente = {
  id?: ID,
  nome?: string,
  descricao?: string,
  cargo?: CargoCliente,
  setor?: SetorCliente,
  fatorRisco?: string
  ativo?: boolean
}
export type ColaboradorPcmso = {
  id?: ID,
  nome?: string,
  crm?: string,
  rqe?: string,
  uf?: string
}

export type Endereco = {
  id?: ID
  tipo?: TipoEndereco
  pessoa?: Pessoa
  estado?: Estado
  empresa?: Empresa
  ativo?: boolean
  cep?: string
  logradouro?: string
  numero?: string
  complemento?: string
  bairro?: string
  cidade?: string
  uf?: string
}
export type TipoEndereco = {
  id: ID
  descricao: string
}
export type Estado = {
  id: ID
  descricao: string
  sigla: string
}
export type EmpresasQueryResponse = Response<Array<Empresa>>
export type EmpresasClienteQueryResponse = Response<Array<EmpresaCliente>>
export type EmpresaClienteQueryResponse = Response<EmpresaCliente>
export type CargosClienteQueryResponse = Response<Array<CargoCliente>>
export type SetoresClienteQueryResponse = Response<Array<SetorCliente>>
export type ExamesClienteQueryResponse = Response<Array<ExameCliente>>
export type RiscosClienteQueryResponse = Response<Array<RiscoCliente>>
export type EmpresasClienteAsoQueryResponse = Response<Array<EmpresaCliente>>
export type ColaboradorPcmsoQueryResponse = Response<Array<ColaboradorPcmso>>

export const initialEmpresa: Empresa = {
  nome: '',
  nomeFantasia: '',
  cnpj: '',
  tiposAtendimento: ['PA'],
  permiteNovoPaciente: true
}
export const initialEndereco: Endereco = {
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  uf: ''
}
