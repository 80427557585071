import React, {FC, useEffect, useState} from 'react'
import {Formik, Form} from 'formik'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {
  FormControl,
  FormSelect,
  Button,
  Modal,
  FormGroup,
  FormCheck,
  FormLabel,
} from 'react-bootstrap'
import TextEditorInput from '../../../../../inputs/TextEditorInput/TextEditor'
import {
  createFormulario,
  deleteCampos,
  updateCamposOrder,
  updateFormulario,
} from '../../core/_requests'
import Swal from 'sweetalert2'
import {FieldConfig, FieldConfigUpdate, Formulario} from '../../core/_models'
import Select from 'react-select'
import {useQueryResponse, useQueryResponseData} from '../../core/QueryResponseProvider'
import {Input} from '../inputs/Input'
import FieldConfigModal from '../inputs/InputConfigModal'
import {ListLoading} from '../../../../commons/components/ListLoading'
import { useNavigate} from 'react-router-dom'
import {validationSchema} from './validationSchema'

type Props = {
  formulario?: Formulario
  isLoading?: boolean
}

const formFields: {name: string; type: FieldConfig['tipo']; class: string}[] = [
  {name: 'Editor de Texto', type: 'EDITOR', class: 'bi-file-earmark-text'},
  {name: 'Texto', type: 'TEXTO', class: 'bi-text-paragraph'},
  {name: 'Marcar', type: 'RADIO', class: 'bi-check-circle'},
  {name: 'Número', type: 'NUMERO', class: 'bi-123'},
  {name: 'Selecionar', type: 'SELECT', class: 'bi-list-check'},
]

export const FormularioEdit: FC<Props> = ({isLoading, formulario: form}) => {
  const navigate = useNavigate()

  const [deletedFields, setDeletedFields] = useState<number[]>([])
  const [orderHasChanged, setOrderHasChanged] = useState<boolean>(false)

  const [selectedField, setSelectedField] = useState<FieldConfig | null>(null)
  const [initialFormulario, setInitialFormulario] = useState<Formulario>(
    form
      ? form
      : {
          descricao: '',
          ativo: true,
          campos: [],
          nome: '',
        }
  )
  const [showModal, setShowModal] = useState(false)

  useEffect(() => console.log(form), [])

  const handleAddField = (
    setFieldValue: (field: string, value: any) => void,
    fields: FieldConfig[],
    tipo: FieldConfig['tipo']
  ) => {
    const newField: FieldConfig = {
      ordem: fields.length + 1,
      rotulo: 'Pergunta',
      instrucoes: '',
      tipo,
      obrigatorio: false,
      multiplo: false,
      respostaPadrao: '',
      opcoes: tipo === 'SELECT' || tipo === 'RADIO' ? [{ordem: 1, descricao: ''}] : [],
    }

    setFieldValue('campos', [...fields, newField])
  }
  const handleEditField = (field: FieldConfig) => {
    setSelectedField(field)
    setShowModal(true)
  }

  const handleUpdateField = (
    updatedConfig: FieldConfig,
    fields: FieldConfig[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    const updatedFields = fields.map((field) =>
      field.ordem === updatedConfig.ordem ? {...field, ...updatedConfig} : field
    )

    setFieldValue('campos', updatedFields)
    setSelectedField(null)
  }

  const closeModal = () => setShowModal(false)

  const renderField = (field: FieldConfig) => {
    switch (field.tipo) {
      case 'SELECT':
        const options = field.opcoes?.map((option) => ({
          value: option.descricao,
          label: option.descricao,
        }))

        return (
          <Select
            className='d-flex w-100'
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
              }),
            }}
            isMulti={field.multiplo}
            options={options}
            required={field.obrigatorio}
            isDisabled={true}
            value={{label: field.respostaPadrao, value: field.respostaPadrao}}
          />
        )
      case 'EDITOR':
        return (
          <TextEditorInput anotacao={field.respostaPadrao || ''} value={() => {}} disabled={true} />
        )
      case 'RADIO':
        return (
          <div className='d-flex flex-column w-100 my-2'>
            <FormGroup controlId='formRadioGroup'>
              {field.opcoes?.map((option, index) => (
                <FormCheck
                  type='radio'
                  id={`radioOption${index}`}
                  label={option.descricao}
                  name='radioOptions'
                  value={option.descricao}
                  className='rounded-pill border-0 mb-2'
                  key={index}
                  disabled
                  checked={option.descricao === field.respostaPadrao}
                />
              ))}
            </FormGroup>
          </div>
        )
      default:
        return (
          <Input
            type={field.tipo === 'NUMERO' ? 'number' : 'text'}
            name={field.rotulo}
            required={field.obrigatorio}
            disabled={true}
            value={field.tipo === 'NUMERO' ? Number(field.respostaPadrao) : field.respostaPadrao}
          />
        )
    }
  }

  const handleDragEnd = (
    result: DropResult,
    fields: FieldConfig[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (!result.destination) return

    const items = Array.from(fields)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setOrderHasChanged(true)
    setFieldValue('campos', items)
  }

  const formatErrors = (error: any) => {
    let formattedErrors = ''

    if (error.errors) {
      for (let field in error.errors) {
        if (error.errors.hasOwnProperty(field)) {
          formattedErrors += `${field}: ${error.errors[field].join(', ')}\n`
        }
      }
    }

    return formattedErrors
  }

  const onSubmit = async (formulario: Formulario) => {
    try {
      formulario.campos.forEach((field, index) => {
        field.ordem = index + 1
      })

      if (formulario.id) {
        await updateFormulario({
          ativo: formulario.ativo,
          nome: formulario.nome,
          descricao: formulario.descricao,
          id: formulario.id,
        })
        if (orderHasChanged || deletedFields.length > 0) updateCamposOrder(formulario.campos)
      } else {
        const campos = formulario.campos.map((campo) => ({
          ...campo,
          opcoes: campo.opcoes?.map((opcao) => opcao.descricao),
        }))

        const formUpdated = {...formulario, campos}

        await createFormulario(formUpdated)
      }

      if (deletedFields.length > 0) deleteCampos(deletedFields)

      Swal.fire({
        text: 'Formulário cadastrado com sucesso',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => navigate('/apps/formularios'))
    } catch (error: any) {
      console.log(error)
      Swal.fire({
        text: formatErrors(error.response.data.data),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }
  }

  return (
    <Formik
      initialValues={initialFormulario}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const {values, setFieldValue, errors, touched, dirty} = formik
        return (
          <div className='container d-flex flex-row justify-content-between shadow-sm p-4 bg-red'>
            <Form className='col-md-8 p-4'>
              {errors.campos && touched.campos && typeof errors.campos === 'string' && (
                <div className='alert alert-danger' role='alert'>
                  {errors.campos}
                </div>
              )}
              <Input
                type='text'
                name='Nome do formulário'
                onChange={(value) => formik.setFieldValue('nome', value)}
                value={values.nome}
                required={true}
              />

              <Input
                type='text'
                name='Descrição'
                onChange={(value) => formik.setFieldValue('descricao', value)}
                value={values.descricao}
                required={true}
              />

              <DragDropContext
                onDragEnd={(result) => handleDragEnd(result, values.campos, setFieldValue)}
              >
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {values.campos.map((field, index) => (
                        <Draggable
                          key={field.ordem}
                          draggableId={String(field.ordem)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='mb-4'
                            >
                              <div className='d-flex flex-column w-100 p-3'>
                                {field.tipo === 'TEXTO' || field.tipo === 'NUMERO' ? null : (
                                  <label
                                    className={
                                      field.obrigatorio
                                        ? 'form-label fw-bolder mb-1 required'
                                        : 'form-label fw-bolder mb-1'
                                    }
                                  >
                                    {field.rotulo}
                                  </label>
                                )}

                                <div className='d-flex align-items-center '>
                                  {renderField(field)}
                                  <div
                                    className='d-flex ms-2 align-items-center'
                                    style={{height: '100%'}}
                                  >
                                    <Button
                                      className='me-2 p-0 d-flex align-items-center justify-content-center'
                                      variant='link'
                                      onClick={() => handleEditField(field)}
                                    >
                                      <i className='bi bi-gear' style={{fontSize: '20px'}}></i>
                                    </Button>
                                    <Button
                                      className='p-0 d-flex align-items-center justify-content-center'
                                      variant='link'
                                      onClick={() => {
                                        setDeletedFields((prev) =>
                                          field.id ? [...prev, field.id] : [...prev]
                                        )
                                        setFieldValue(
                                          'campos',
                                          values.campos.filter((f) => f.ordem !== field.ordem)
                                        )
                                      }}
                                    >
                                      <i className='bi bi-trash' style={{fontSize: '20px'}}></i>
                                    </Button>
                                  </div>
                                </div>
                                <span className='text-muted small'>{field.instrucoes}</span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className='d-flex justify-content-end mt-10'>
                <Button type='submit' className='btn btn-primary'>
                  {form ? 'Salvar Alterações' : 'Criar Formulário'}
                </Button>
              </div>
            </Form>
            <div
              className='col-md-4 d-flex flex-column align-items-center justify-content-between rounded h-50 p-4'
              style={{maxHeight: '500px'}}
            >
              <h2>Campos</h2>

              {formFields.map((field) => (
                <Button
                  onClick={() => handleAddField(setFieldValue, values.campos, field.type)}
                  className='mb-2 w-50 btn btn-secondary'
                  key={field.type}
                >
                  <i className={`bi me-2 ${field.class}`}></i>
                  <span>{field.name}</span>
                </Button>
              ))}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Configurar Campo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedField && (
                  <FieldConfigModal
                    field={selectedField}
                    formId={initialFormulario.id}
                    closeModal={closeModal}
                    onUpdate={(updatedConfig: FieldConfig) =>
                      handleUpdateField(updatedConfig, values.campos, setFieldValue)
                    }
                  />
                )}
              </Modal.Body>
            </Modal>
            {(formik.isSubmitting || isLoading) && <ListLoading />}
          </div>
        )
      }}
    </Formik>
  )
}
