/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { initialQueryState,  useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'


const EmpresasListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ search: debouncedSearchTerm, sort: 'nomeFantasia', order: 'asc', ...initialQueryState })
      }
    },
    [debouncedSearchTerm] 
  )
  const handleChangeNome = (e: any) => {
    setSearchTerm((String(e.target.value) !== '' ? 'nome~*' + String(e.target.value) + '*' + ",'nomeFantasia~*" + String(e.target.value) + '*' : ''))
  }

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='mb-5'>
        <label className='form-label fw-bold ps-1'>Empresa</label>
        <input
          type='text'
          data-kt-user-table-filter='nome'
          className='form-control form-control-solid w-550px ps-1'
          placeholder='Digite o nome'

          onChange={handleChangeNome}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export { EmpresasListSearchComponent }
