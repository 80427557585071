/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { url } from 'inspector'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <a href="../../demo1/dist/index.html" className="d-block d-lg-none mx-auto py-20">
        <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-white.png')} className="theme-light-show h-50px" />
        <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-dark.png')} className="theme-dark-show h-50px" />
      </a>
      <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
        <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
          {/* <div className="d-flex  flex-row-auto" > */}
            <Outlet />
          {/* </div> */}
        </div>
      </div>
      {/* begin::Aside */}
      <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{backgroundImage: 'url("media/misc/auth-bg.png")'}}></div>
      {/* end::Aside */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='confirm-account' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
