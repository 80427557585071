/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { useAuth } from '../../../../../auth'
import { downloadProntuario } from '../../../../atendimento/core/_requests'
type Props = {
  id: ID
}

const HistoricoActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const [IdEmpresa, setIdEmpresa] = useState<string>("");
  const isADM = currentUser?.grupo?.nome === 'SUPER_MEDICO' || currentUser?.grupo?.nome === 'SUPER_ADMIN'

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const inciarAtendimentoVideo = () => {
    window.location.href = "/apps/pacientes-management/pacientes?empresa=" + id
  }

  const handleDownloadProntuario = () => {
    downloadProntuario(id).then((anexo) => {
      if (anexo !== undefined) {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + (anexo !== undefined ? anexo.contentBase64 : ''); //Image Base64 Goes here
        a.download = String(anexo.name); //File name Here
        a.click();
      }
    }).catch((error) => {
      const errors = error.response.data.data.message

      Swal.fire({
        text: `Não foi possível realizar o download: ${errors}.`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
    })
  }


  return (
    <>
      {/* begin::Menu item */}

      <button type='button' className='btn btn-primary btn-sm' onClick={openEditModal}>
        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
        Visualizar
      </button>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
        data-kt-menu='true'
      >
        {isADM && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => handleDownloadProntuario()}>
              Baixar atendimento
            </a>
          </div>
        )}
      </div>
      {/* end::Menu item */}

    </>
  )
}

export { HistoricoActionsCell }
