import FullCalendar from "@fullcalendar/react";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import { getAvatarPaciente, getPacienteById } from "../../paciente-management/pacientes-list/core/_requests";
import { AtendimentoIniciado, Paciente } from "../../atendimento/core/_models";
import { getAtendimentoById, getAtendimentosEmpresaAguardando, getAtendimentosHistorico,  } from "../../atendimento/core/_requests";
import Swal from "sweetalert2";
import { Picture, useAuth } from "../../../auth";
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Modal } from "react-bootstrap";
import { HistoricoDetalhes } from "./historicoDetalhes";

type Eventos = {
    id: string,
    title: string,
    date: string,
    backgroundColor: string
}
type Props = {
    idPaciente: String | undefined
}
const HistoricoClinico: FC<Props> = ({ idPaciente }) => {
    const [searchParams] = useSearchParams();
    const user = useAuth().currentUser
    const [paciente, setPaciente] = useState<Paciente | undefined>(undefined)
    const [eventos, setEventos] = useState<Eventos[]>([])
    const [atendimentos, setAtendimentos] = useState<AtendimentoIniciado[] | undefined>(undefined)
    const [atendimentoSelecionado, setAtendimentoSelecionado] = useState<AtendimentoIniciado | undefined>(undefined)
    const [avatar, setAvatar] = useState<String | undefined>(undefined)
    const [idAtendimentoSelecionado, setIdAtendimentoSelecionado] = useState<Number | undefined>(undefined)
    const [show, setShow] = useState(false);
    if (idPaciente === undefined) {
        idPaciente = String(searchParams.get("paciente"))
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const getPaciente = () => {
        getPacienteById(Number(idPaciente)).then((pacienteApi) => {
            setPaciente(pacienteApi)
        }).catch((error) => {
            console.log("ERRO PACIENTE:" + error)
        })

        getAvatarPaciente(Number(idPaciente)).then((result) => {
            if (result?.data !== null) {
                setAvatar(result?.data)
            } else {
                setAvatar('/media/avatars/AVATARES_05.png')
            }
        }).catch((error) => {
            setAvatar('/media/avatars/AVATARES_05.png')
        })

    }

    const mapResponseToValuesAndLabels = (data: AtendimentoIniciado) => ({
        id: data.id,
        title: data.estado?.descricao,
        date: '2023-02-01T10:30:00',
        backgroundColor: data.estado?.id === 2 ? 'green' : 'yellow',
        borderColor: 'green'
    });
    const getAtendimentosAPi = () => {
        var options: any = []
        getAtendimentosHistorico(Number(idPaciente),'?size=1000')
            .then((response) => {
                setAtendimentos(response.data)
                atendimentos?.map(data => options.push({
                    id: String(data.id),
                    title: String(data.estado?.descricao),
                    date: data.inicio !== null ? String(data.inicio) : String(data.dataSolicitacao),
                    backgroundColor: data.estado?.id === 2 ? 'green' : data.estado?.id === 3 ? 'red' : 'yellow',
                    extendedProps: {
                        responsavel: data.estado?.id === 2 ? data.responsavel?.nome : 'Não definido',
                        cid: (data.cids !== null ? data.cids : 'Não definido')
                    }
                })
                )
            }
            ).then((response) => setEventos(options))
            .catch((error) => {
                Swal.fire({
                    text: 'Não foi possível verificar o histório do paciente!',
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            })

    }
    useEffect(() => {
        // callApi() { ":", "!", ">", "<", "~" }
        if (eventos.length === 0) {
            getPaciente()
            // getAtendimentos()
            getAtendimentosAPi()
        }
    }, [eventos]);
    function eventClick(arg: any) {
        console.error(arg)
        setIdAtendimentoSelecionado(arg.event.id)
        setShow(true)
        // Swal.fire({
        //     text: 'Aqui terá as informações do paciente! ' + id.event._def.title,
        //     icon: 'success',
        //     buttonsStyling: false,
        //     confirmButtonText: "Ok",
        //     width: "600px",
        //     customClass: {
        //         confirmButton: "btn btn-primary"
        //     }
        // })
    }
    function renderEventContent(eventInfo: any) {

        return (
            <>
                <div className='me-n2'>
                    <b>{eventInfo.event.title} </b>
                    {/*begin::Action*/}
                    <a
                        href='#'
                        className='btn btn-icon btn-sm btn-color-primary mt-n2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-start'
                        data-kt-menu-overflow='false'

                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen067.svg'
                            className='svg-icon-muted svg-icon-2'

                        />
                    </a>

                    {/*end::Action*/}
                </div>
                <i>CID: {eventInfo.event.extendedProps.cid}</i>
                <br />
                <i>Responsável: {eventInfo.event.extendedProps.responsavel}</i>
            </>
        )

    }


    return (
        <div>
            <div className="col-lg-12 col-xl-12 mb-5 mb-xl-0">
                <div className="card h-md-100" style={{ backgroundColor: '#F4F4F4' }}>
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-1 text-dark">Dados cadastrais</span>
                        </h3>
                    </div>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                        <div className='me-7 mb-6 p-4'>
                            <div className='symbol symbol-100px symbol-lg-100px symbol-fixed position-relative'>
                                <img src={toAbsoluteUrl(String(avatar))} alt='Avatar Paciente' />
                                <div className='position-absolute translate-middle bottom-0 start-100 mb-6'></div>
                            </div>

                        </div>

                        <div className='flex-grow-1 my-3'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <a href='#' className='text-gray-800 text-hover-primary fs-3 fw-bolder me-1'>
                                            {paciente?.nome}
                                        </a>
                                    </div>

                                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                        <a
                                            href='#'
                                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                        >

                                            {paciente?.pessoa?.dataNascimentoFormatada}
                                        </a>
                                        <a
                                            href='#'
                                            className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                        >

                                            {paciente?.pessoa?.sexo}
                                        </a>

                                    </div>
                                    <div
                                        className=''
                                        role='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#kt_alergias'
                                        aria-expanded='true'
                                        aria-controls='kt_alergias'
                                    >
                                        <div className='card-title m-0'>
                                            <div className='fw-bold'>
                                                <span className='menu-icon'>
                                                    <KTSVG path='/media/icons/duotune/general/medical2.svg' className='svg-icon-2' />
                                                </span>
                                                Alergias
                                            </div>
                                        </div>
                                    </div>

                                    <div id='kt_alergias' className='collapse'>
                                        <ul>
                                        {paciente?.alergias?.map( a =>
                                            <li>
                                                {a.descricao}
                                            </li>
                                        )}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-xl-12 mb-xl-0 my-5">
                <FullCalendar
                    plugins={[listPlugin, dayGridPlugin]}
                    initialView='listYear'
                    weekends={false}
                    events={eventos}
                    eventOrder={function(a:any, b:any) {
                        var result = 0;
                        if (a.id < b.id) result = -1;
                        else if (a.id > b.id) result = 1;
                        return result;
                      }}
                    locale={'pt-br'}
                    // eventContent={renderEventContent}
                    eventClick={eventClick}
                    headerToolbar={{
                        start: 'title', // will normally be on the left. if RTL, will be on the right
                        center: '',
                        end: 'prev,next' // will normally be on the right. if RTL, will be on the left
                    }}
                    height={'auto'}
                    eventContent={renderEventContent}
                />
            </div>
            <Modal className="modal-xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Visualização Atendimento</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <HistoricoDetalhes idAtendimento={idAtendimentoSelecionado} />

                </Modal.Body>
            </Modal>
        </div>
    )
}

// a custom render function
function renderEventContent(eventInfo: any) {
    return (
        <>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </>
    )

}
export { HistoricoClinico }