import {KTCard} from '../../../../../_metronic/helpers'
import {ListViewProvider} from '../formularios-list/core/ListViewProvider'
import {QueryRequestProvider} from '../formularios-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../formularios-list/core/QueryResponseProvider'
import {FormulariosTable} from '../formularios-list/table/FormulariosTable'
import {FormulariosListHeader} from './component/header/FormulariosListHeader'

const FormulariosList = () => {
  return (
    <>
      <KTCard>
        <FormulariosListHeader />
        <FormulariosTable />
      </KTCard>
    </>
  )
}

const FormulariosListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <FormulariosList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {FormulariosListWrapper}
