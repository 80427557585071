/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { ID, initialQueryState, KTSVG, toAbsoluteUrl, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'

import { getEstados } from '../../../../atendimento/core/_requests'
import Select from 'react-select'
import { getEmpresas } from '../../../../empresa/empresas-list/core/_requests'
import { Paciente } from '../../../../atendimento/core/_models'
import { useSearchParams } from 'react-router-dom'
import { downloadProntuario, getAvatarPaciente, getPacienteById } from '../../../../paciente-management/pacientes-list/core/_requests'
import Swal from 'sweetalert2'


function getOptionsEmpresas() {
  const results: any = []

  getEmpresas('page=0&size=30').then((data) => {
    if (data !== undefined) {
      results.push({
        label: 'Todas as empresas',
        value: undefined
      });

      data.data?.forEach((value) => {
        if (value.ativa) { // Filtra somente as empresas ativas
          results.push({
            label: value?.nomeFantasia,
            value: value.id
          });
        }
      });
    }
  }).catch((error) => {
    console.error("Erro ao obter empresas:", error);
  });

  return results;
}
function getOptionsEstados() {
  const results: any = []
  getEstados().then((data) => {
    if (data !== undefined) {
      data.data?.forEach((value) => {
        results.push({
          label: value.descricao,
          value: value.id
        });
      });
    }
  })
  return results
}
const HistoricoListSearchComponent = () => {
  const [idEmpresaConsulta, setIdEmpresaConsulta] = useState<Number | undefined>(undefined)
  const { updateState } = useQueryRequest()
  const [searchParams] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [protocoloSel, setProtocoloSel] = useState<string>('')
  const [dataSel, setDataSel] = useState<string>('')
  const [isChance, setIschange] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(getOptionsEstados());
  const [estadoSel, setEstadoSel] = useState<String>('');
  const [paciente, setPaciente] = useState<Paciente | undefined>(undefined)
  const idPaciente = String(searchParams.get("paciente"))
  const [avatar, setAvatar] = useState<String | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const [filters, setFilters] = useState<Array<String>>(idEmpresaConsulta ? new Array(String(idEmpresaConsulta)) : [])
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  const getPaciente = () => {
    getPacienteById(Number(idPaciente)).then((pacienteApi) => {
      setPaciente(pacienteApi)
    }).catch((error) => {
      console.log("ERRO PACIENTE:" + error)
    })

    getAvatarPaciente(Number(idPaciente)).then((result) => {
      if (result?.data !== null) {
        setAvatar(result?.data)
      } else {
        setAvatar('/media/avatars/AVATARES_05.png')
      }
    }).catch((error) => {
      setAvatar('/media/avatars/AVATARES_05.png')
    })

  }

  const handleDownloadProntuario = () => {
    downloadProntuario(idPaciente).then((anexo) => {
      if (anexo !== undefined) {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + (anexo !== undefined ? anexo.contentBase64 : ''); //Image Base64 Goes here
        a.download = String(anexo.name); //File name Here
        a.click();
      }
    }).catch((error) => {
      const errors = error.response.data.data.message

      Swal.fire({
        text: `Não foi possível realizar o download: ${errors}.`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
    })
  }
  function defineOptionEstado() {
    setFilters(idEmpresaConsulta ? new Array(String(idEmpresaConsulta)) : [])

    let search = '';
    if (estadoSel) search += 'estado.id:' + estadoSel;
    if (protocoloSel) search += (search && ',') + 'protocolo~*' + protocoloSel + '*';
    if (dataSel) search += (search && ',') + 'solicitacao>' + dataSel;
  
    setSearchTerm(search);
  }
  useEffect(() => {
    defineOptionEstado();
    setIschange(false);
  }, []);
  useEffect(
    () => {
      if (paciente === undefined) {
        getPaciente()
      }
      if (debouncedSearchTerm !== undefined && (searchTerm !== undefined)) {
        updateState({ filter: filters, search: searchTerm, sort: 'solicitacaoFormatada', order: 'asc', ...initialQueryState })
      }
    },
    [searchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const handleChangeEstado = (e: any) => {
    setEstadoSel(e.value);
  
    let search = '';
    if (e.value) search += 'estado.id:' + e.value;
    if (protocoloSel) search += (search && ',') + 'protocolo~*' + protocoloSel + '*';
    if (dataSel) search +=(search && ',') + 'solicitacao>' + dataSel;
  
    setSearchTerm(search);
  }
  
  const handleChangeProtocolo = (e: any) => {
    if (e.target.value !== undefined) {
      setProtocoloSel(String(e.target.value));
      
      let search = '';
      if (String(e.target.value)) search += 'protocolo~*' + String(e.target.value) + '*';
      if (estadoSel) search += (search && ',') + 'estado.id:' + estadoSel;
      if (dataSel) search += (search && ',') + 'solicitacao>' + dataSel;
  
      setSearchTerm(search);
    }
  }
  
  const handleChangeData = (e: any) => {
    setIschange(true);
    setDataSel(String(e.target.value) + ':00');
  
    let search = '';
    if (String(e.target.value)) search += 'solicitacao>' + String(e.target.value) + ':00';
    if (estadoSel) search += (search && ',') + 'estado.id:' + estadoSel;
    if (protocoloSel) search += (search && ',') + 'protocolo~*' + protocoloSel + '*';
  
    setSearchTerm(search);
  }
  return (
    <div className='row p-6'>
      <div className="col-lg-12 col-xl-12 mb-5 mb-xl-0">
        <div className="card h-md-100" style={{ backgroundColor: '#F4F4F4' }}>
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-1 text-dark">Dados cadastrais</span>
            </h3>
          </div>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-6 p-4'>
              <div className='symbol symbol-100px symbol-lg-100px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl(String(avatar))} alt='Avatar Paciente' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6'></div>
              </div>

            </div>

            <div className='flex-grow-1 my-3'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-3 fw-bolder me-1'>
                      {paciente?.nome}
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >

                      {paciente?.pessoa?.dataNascimentoFormatada}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >

                      {paciente?.pessoa?.sexo}
                    </a>

                  </div>
                  <div
                    className=''
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_alergias'
                    aria-expanded='true'
                    aria-controls='kt_alergias'
                  >
                    <div className='card-title m-0'>
                      <div className='fw-bold'>
                        <span className='menu-icon'>
                          <KTSVG path='/media/icons/duotune/general/medical2.svg' className='svg-icon-2' />
                        </span>
                        Alergias
                      </div>
                    </div>
                  </div>

                  <div id='kt_alergias' className='collapse'>
                    <ul>
                      {paciente?.alergias?.map(a =>
                        <li>
                          {a.descricao}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    {/* begin::Search */}
<div className='p-0 mt-5'>
  <div className='row mb-0'>
    <div className='col-lg-3 col-md-6 mb-2'>
      <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Estado</label>
      <Select
        placeholder='Selecione um estado'
        onChange={handleChangeEstado}
        options={options}
        className='form-control form-control-solid fs-7'
        // defaultValue={{ label: "Selecione um", value: "" }}
      />
    </div>
    <div className='col-lg-3 col-md-6 mb-2'>
      <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Protocolo</label>
      <input
        type='text'
        data-kt-user-table-filter='nome'
        className='form-control form-control-solid fs-7'
        placeholder='Digite o protocolo'
        value={protocoloSel}
        onChange={handleChangeProtocolo}
      />
    </div>
    <div className='col-lg-3 col-md-6 mb-2'>
      <label className='form-label fs-6 fw-bolder mb-1 ps-3'>A partir</label>
      <input
        type='datetime-local'
        data-kt-user-table-filter='nome'
        max={new Date().toISOString().split("T")[0]}
        className='form-control form-control-solid'
        placeholder='Digite data inicial'
        value={dataSel}
        onChange={handleChangeData}
      />
    </div>
    <div className='col-lg-3 col-md-6 mb-2 mt-1'>
    <label className='form-label fs-6 fw-bolder mb-1 ps-3'></label>
      <button
        type='button'
        className='btn btn-primary w-100'
        onClick={handleDownloadProntuario}
      >
       {loading ? (
       <p> Carregando...</p>
       ) : (
        <>
         Baixar prontuário
         <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-5 ms-2' />
        </>
       )}
      </button>
    </div>
  </div>
</div>
{/* <NotificaSocket idEmpresa='35' /> */}
{/* end::Search */}

    </div>

  )
}

export { HistoricoListSearchComponent }
