/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  ID,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { SetorCliente } from '../../empresas-list/core/_models'
import { getRiscosCliente, getSetoresCliente } from '../../empresas-list/core/_requests'
import { useSearchParams } from 'react-router-dom'

const QueryResponseContext = createResponseContext<SetorCliente>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [idEmpresa] = useState<string>(useQueryResponseIdEmpresa())
  const [idPaciente] = useState<string>(useQueryResponseIdEmpresa())
  const [searchParams] = useSearchParams();
  const idCliente = urlParams.get('cliente');

  const empresa = Number(searchParams.get("empresa"))
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.EMPRESAS_LIST}-${query}`,
    () => getRiscosCliente(Number(empresa), query, idCliente as ID),
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, idEmpresa, idPaciente }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements
  }

  return responsePaginationState;
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

const useQueryResponseIdEmpresa = (): string => {
  const { idEmpresa } = useQueryResponse()
  return idEmpresa
}
export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseIdEmpresa,
}
