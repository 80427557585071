/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  stringifyRequestQueryEspecialidade,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {  getEspecialidades } from './_requests'
import { useQueryRequest } from './QueryRequestProvider'
import { Especialidade } from './_models'

const QueryResponseContext = createResponseContext<Especialidade>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQueryEspecialidade(state))
  const updatedQuery = useMemo(() => stringifyRequestQueryEspecialidade(state), [state])
  const [idEmpresa] = useState<string>(useQueryResponseIdEmpresa())
  const [idPaciente] = useState<string>(useQueryResponseIdEmpresa())
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ESPECIALIDADES_LIST}-${query}`,
    () => getEspecialidades(query),
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, idEmpresa, idPaciente }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements
  }

  return responsePaginationState;
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

const useQueryResponseIdEmpresa = (): string => {
  const { idEmpresa } = useQueryResponse()
  return idEmpresa
}
export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseIdEmpresa,
}
