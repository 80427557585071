import { FC, useEffect } from "react";
import { AtendimentoIniciado } from "../../../core/_models";



type Props = {
  atendimento: AtendimentoIniciado
}

const OnlineColumn: FC<Props> = ({atendimento}) => {

  const cor =  atendimento?.pacienteOnline  ? 'bg-success' : 'bg-secondary';

  useEffect(() => {
    if (atendimento?.pacienteOnline) {
      const audio = document.querySelector('audio')
      audio?.play();
    }
  }, [atendimento?.pacienteOnline, cor]);

  return (
    <div className="d-inline-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px', borderRadius: '50%' }}>
      <div className={`rounded-circle ${cor}`} style={{ width: '30%', height: '30%' }}></div>
      <audio
        src="/media/notifications/notification.mp3"
      />
    </div>
  );
}

export {OnlineColumn}
