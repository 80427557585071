import { AxiosError } from 'axios'
import { useQueryClient, useMutation } from 'react-query'
import Swal from 'sweetalert2'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteSelectedCargosCliente } from '../../../empresas-list/core/_requests'

const SetorClienteListGrouping = () => {
  const { selected, clearSelected } = useListView()
  const queryClient = useQueryClient()
  const { query } = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedCargosCliente(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
      clearSelected()
    },
    onError(error, variables, context) {
      const err = error as AxiosError;
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selecionado(s)
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Remover selecionado(s)
      </button>
    </div>
  )
}

export { SetorClienteListGrouping }
