// @ts-nocheck
import { Column } from 'react-table'
import { RiscoSelectionHeader } from './RiscoSelectionHeader'
import { RiscoSelectionCell } from './RiscoSelectionCell'
import { RiscoCustomHeader } from './RiscoCustomHeader'
import { RiscoActionsCell } from './RiscoActionsCell'


const RiscoColumns: ReadonlyArray<Column<RiscoCliente>> = [
  {
    Header: (props) => <RiscoSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <RiscoSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <RiscoCustomHeader tableProps={props} title='Risco' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  }, 
  {
    Header: (props) => <RiscoCustomHeader tableProps={props} title='Setor' className='min-w-125px' />,
    id: 'setor.nome',
    accessor: 'setor.nome'
  }, 
  {
    Header: (props) => <RiscoCustomHeader tableProps={props} title='Cargo' className='min-w-125px' />,
    id: 'cargo.nome',
    accessor: 'cargo.nome'
  }, 
  {
    Header: (props) => (
      <RiscoCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <RiscoActionsCell id={props.data[props.row.index].id} />,
  },
]

export { RiscoColumns }
