import React, { useState, useEffect } from 'react';
import { ID, initialQueryState, KTSVG, useDebounce } from '../../../../../../../_metronic/helpers';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import Select from 'react-select';
import { FuncionariosQueryResponse } from '../../../../funcionarios-management/funcionarios-list/core/_models';
import { EmpresasQueryResponse } from '../../../../empresa/empresas-list/core/_models';
import { Button } from 'react-bootstrap';
import { EstadoQueryResponse } from '../../../../atendimento/core/_models';
import { getEstados } from '../../../../atendimento/core/_requests';
import { getCsv, getMedicos, getPdf } from '../../core/_requests';
import { getEmpresas } from '../../../../empresa/empresas-list/core/_requests';
import { useAuth } from '../../../../../auth';
import { getEspecialidadesSemQuery } from '../../../../especialidades/especialidades-list/core/_requests';
import { Especialidade } from '../../../../especialidades/especialidades-list/core/_models';

interface PDFObject {
  id: string;
  name: string;
  type: string;
  length: number;
  contentBase64: string;
}

type tiposEspecialidadeOptions = {
  value: undefined,
  label: string
}

const RelatorioListSearchComponent = () => {
  const { updateState } = useQueryRequest();
  const {currentUser} = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  const [dataInicial, setDataInicial] = useState<string>();
  const [dataFinal, setDataFinal] = useState<string>();
  const [especialidade, setEspecialidade] = useState<ID>();
  const [selectEspecialidade, setSelectEspecialidade] = useState()
  const [optionsEmpresa, setOptionsEmpresa] = useState<any>();
  const [optionsMedicos, setOptionsMedicos] = useState<any>();
  const [optionsEspecialidades, setOptionsEspecialidades] = useState<tiposEspecialidadeOptions[]>([]) 
  const [loadingOptionsMedicos, setLoadingOptionsMedicos] = useState<boolean>(false);

  const [optionsSolicitante, setOptionsSolicitante] = useState<any>();
  const [idEmpresa, setIdEmpresa] = useState<ID | null>(null);
  const [idMedico, setIdMedico] = useState<ID | null>(null);
  const [idSolicitante, setIdSolicitante] = useState<ID | null>(null);
  const [idEstado, setIdEstado] = useState<ID | null>(null);
  const [tipoAtendimento, setTipoAtendimento] = useState<string>();
  const [filePdf, setFilePdf] = useState<PDFObject>();
  const [fileCsv, setFileCsv] = useState<PDFObject>();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);

  const getTiposAtendimento = () => {
    const tiposSet = new Set();
    currentUser?.vinculos?.forEach((vinculo) => {
      if (vinculo.empresa && vinculo.empresa.tiposAtendimento) {
        vinculo.empresa.tiposAtendimento.forEach(tipo => tiposSet.add(tipo));
      }
    });
    return Array.from(tiposSet).map(tipo => ({ value: tipo, label: tipo }));
  };


  const getOptionsMedicos = async () => {
    setLoadingOptionsMedicos(true)
    try {
      const results: { label: string; value: ID }[] = [];
  
      const seenIds = new Set<ID>();
      let page = 0;
      let hasMoreData = true;
  
      while (hasMoreData) {
        const data: FuncionariosQueryResponse | undefined = await getMedicos(`search=cargo:MEDICO&page=${page}&size=20`);
  
        if (!data || !data.data || data.data.length === 0) {
          hasMoreData = false;
          break;
        }
  
        data.data.forEach((value) => {
          if (!seenIds.has(value?.pessoa?.id)) {
            results.push({ label: value.nome as string, value: value?.pessoa?.id });
            seenIds.add(value?.pessoa?.id);
          }
        });
  
        page++;
      }
  
      results.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
  
      results.push({ label: 'Todos os Profissionais', value: undefined });
  
      setOptionsMedicos(results);
    } catch (error) {
      console.error("Erro ao obter médicos:", error);
      setOptionsMedicos([]);
    } finally {
      setLoadingOptionsMedicos(false)
    }
  };

  const handleEspecialidades = async () => {
    try {
      const data = await getEspecialidadesSemQuery()
      return data?.data as unknown as Especialidade[]
    } catch (error) {
      console.error('Erro ao obter os tipos de atendimentos:', error)
      return []
    }
  }

  const mapEspecialidades = ( fetchedOptions: Especialidade[]): tiposEspecialidadeOptions[] => {  
    return fetchedOptions
      .map(especialidade => ({
        value: especialidade.id as undefined,
        label: especialidade.nome,
      }));
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const fetchedOptions = await handleEspecialidades()
      setOptionsEspecialidades(mapEspecialidades( fetchedOptions));
    }
    
    fetchOptions()
  }, [])

  const getOptionsSolicitante = async () => {
    try {
      const results: { label: string; value: ID }[] = [];
      const seenIds = new Set<ID>();
      const data: FuncionariosQueryResponse | undefined = await getMedicos('&page=0&size=30');
      

      const funcTemp: { label: string; value: ID }[] = [];
  
      data?.data?.forEach((value) => {
        if (!seenIds.has(value?.pessoa?.id)) {
          funcTemp.push({ label: value.nome as string, value: value.id });
          seenIds.add(value?.pessoa?.id);
        }
      });

      funcTemp.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
  

      results.push({ label: 'Todos os Solicitantes', value: undefined });
      results.push(...funcTemp);
  
      setOptionsSolicitante(results);
    } catch (error) {
      console.error("Erro ao obter médicos:", error);
      setOptionsSolicitante([]);
    }
  };
  
  const getOptionsEstados = async () => {
      try {
        const results: { label: string; value: ID }[] = [];
        const data: EstadoQueryResponse | undefined = await getEstados()
        data?.data?.forEach((value) => {
          results.push({ label: value.nome as string, value: value.id });
        });
        setOptions([...results]);
      } catch (error) {
        console.error("Erro ao obter estados:", error);
        setOptionsMedicos([]);
      }
  };

  const getOptionsEmpresas = async () => {
    try {
      const results: { label: string; value: ID }[] = [];
      const data: EmpresasQueryResponse | undefined = await getEmpresas('page=0&size=30');
    
      const empresasTemp: { label: string; value: ID }[] = [];
    
      data?.data?.forEach((value) => {
        if (value.ativa) { 
          empresasTemp.push({ label: value.nomeFantasia as string, value: value.id });
        }
      });
    
      empresasTemp.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
    
      results.push({ label: 'Todas as empresas', value: undefined });
      results.push(...empresasTemp);
    
      setOptionsEmpresa(results);
    } catch (error) {
      console.error("Erro ao obter empresas:", error);
      setOptionsEmpresa([]);
    }
  };

  

  const updateFilter = () => {
    const search = [
      idEmpresa ? `paciente.empresa:${idEmpresa}` : '',
      tipoAtendimento ? `tipo:${tipoAtendimento}` : '',
      dataInicial ? `inicio>${dataInicial}` : '',
      dataFinal ? `termino<'${dataFinal}` : '',
      idMedico ? `responsavel.pessoa.id:${idMedico}` : '',
      idEstado ? `estado:${idEstado}` : '',
      idSolicitante ? `criador.id:${idSolicitante}` : '',
      especialidade ?  `especialidade:${especialidade}` : '',
    ].filter(Boolean).join(',');
    setSearchTerm(search);
  };

  const handleGetFiles = async () => {
    setLoading(true);
    try {
      setFilePdf(await getPdf(`search=${searchTerm}`));
      setFileCsv(await getCsv(`search=${searchTerm}`));
    } catch (error) {
      console.error("Erro ao obter arquivos:", error);
    } finally {
      setLoading(false);
    }
  };

  const base64ToBlob = (b64Data: string, contentType: string = '', sliceSize: number = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const downloadPdf = () => {
    if (filePdf) {
      const blob = base64ToBlob(filePdf.contentBase64, 'application/pdf');
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filePdf.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const downloadCsv = () => {
    if (fileCsv) {
      const blob = base64ToBlob(fileCsv.contentBase64, 'text/csv');
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileCsv.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    getOptionsMedicos();
    getOptionsEmpresas();
    getOptionsEstados();
    getOptionsSolicitante();
  }, []); 
  
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ search: searchTerm, sort: 'solicitacaoFormatada', order: 'asc', ...initialQueryState });
    }
  }, [debouncedSearchTerm, updateState]); 

  useEffect(() => {
    updateFilter();
  }, [idEmpresa, tipoAtendimento, dataInicial, dataFinal, idMedico, idEstado, idSolicitante, especialidade]);

  useEffect(() => {
    handleGetFiles()
  }, [searchTerm])


  return (
    <div className='row p-6 col-lg-12 justify-content-center'>
      <div className='p-0 justify-content-center'>
        <div className='row mb-0'>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Empresa</label>
            <Select
              placeholder='Todas as empresas'
              onChange={(e: { value: ID } | null) => setIdEmpresa(e?.value ?? null)}
              options={optionsEmpresa}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Profissional de Saúde</label>
            <Select
              placeholder='Todos os Profissionais'
              onChange={(e: { value: ID } | null) => setIdMedico(e?.value ?? null)}
              options={optionsMedicos}
              isLoading={loadingOptionsMedicos} 
              loadingMessage={() => 'Carregando opções...'}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Estado</label>
            <Select
              placeholder='Selecione um estado'
              onChange={(e: { value: ID } | null) => setIdEstado(e?.value ?? null)}
              options={options}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Tipo atendimento</label>
            <Select
              placeholder='Tipo de atendimento'
              onChange={(selectedOption) => selectedOption && setTipoAtendimento(selectedOption?.value as string)}
              options={getTiposAtendimento()}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-4'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Data de início</label>
            <input
              type='datetime-local'
              min={new Date().toISOString().split("T")[0]}
              className='form-control form-control-solid fs-7'
              placeholder='Selecione a data inicial'
              value={dataInicial || ''}
              onChange={(e) => setDataInicial(e.target.value)}
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-4'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Data de final</label>
            <input
              type='datetime-local'
              className='form-control form-control-solid fs-7'
              placeholder='Selecione a data final'
              value={dataFinal || ''}
              onChange={(e) => setDataFinal(e.target.value)}
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Solicitante</label>
            <Select
              placeholder='Todos os solicitantes'
              onChange={(e: { value: ID } | null) => 
                setIdSolicitante(e?.value)}
              options={optionsSolicitante}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
          <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Especialidade</label>
          <Select
            placeholder='Selecione uma especialidade'
            onChange={(data: any) => {
              setSelectEspecialidade(data)
              setEspecialidade(data.value)
            }}
            options={optionsEspecialidades}
            className='form-control form-control-solid'
            // defaultValue={{ label: "Selecione um valor", value: "0" }}
            value={selectEspecialidade}

          />
        </div>
        </div>
      </div>
      {(searchTerm && filePdf?.contentBase64 !== null) && (
        <div className="d-flex justify-content-end mb-3 mt-2">
          {loading ? (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <>
              <Button onClick={downloadPdf}>
                <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-3' />
                Baixar em PDF
              </Button>
              &nbsp; 
              <Button onClick={downloadCsv}>
                <KTSVG path='/media/icons/duotune/files/fil002.svg' className='svg-icon-3' />
                Baixar em csv
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export { RelatorioListSearchComponent };
