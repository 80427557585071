import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { ListLoading } from '../../../commons/components/ListLoading'
import { CustomHeaderColumn } from '../../../commons/table/CustomHeaderColumn'
import { CustomRow } from '../../../commons/table/CustomRow'
import { RiscoColumns } from './columns/_columns'
import { RiscoClienteListPagination } from '../component/pagination/RiscoClienteListPagination'
import { RiscoCliente } from '../../empresas-list/core/_models'


const RiscoClienteTable = () => {
  const empresas = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => (Array.isArray(empresas) ? empresas : [empresas]), [empresas]);
  const columns = useMemo(() => RiscoColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });


  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_empresas'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<RiscoCliente>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<RiscoCliente>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RiscoClienteListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { RiscoClienteTable }
