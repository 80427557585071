import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {ListLoading} from '../../../commons/components/ListLoading'
import {CustomHeaderColumn} from '../../../commons/table/CustomHeaderColumn'
import {CustomRow} from '../../../commons/table/CustomRow'
import {especialidadesColumns} from './columns/_columns'
import {FormulariosListPagination} from '../component/pagination/FormulariosListPagination'
import {Formulario} from '../../formularios-create/core/_models'

const FormulariosTable = () => {
  const formularios = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => formularios, [formularios])
  const columns = useMemo(() => especialidadesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4 w-100'>
      <div className='table-responsive w-100'>
        <table
          id='kt_table_formularios'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer w-100'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Formulario>) => (
                //@ts-ignore
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Formulario>, i) => {
                prepareRow(row)
                //@ts-ignore
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <FormulariosListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {FormulariosTable}
