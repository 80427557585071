import { KTCard } from "../../../../../_metronic/helpers"
import { CargoClienteEditModal } from "./cargo-edit-modal/CargoClienteEditModal"
import { CargoClienteListHeader } from "./component/header/CargoClienteListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { CargoClienteTable } from "./table/CargoClienteTable"


const CargoClienteList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <CargoClienteListHeader />
        <CargoClienteTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CargoClienteEditModal />}
    </>
  )
}

const CargoClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CargoClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { CargoClienteListWrapper }