
import { useListView } from '../../core/ListViewProvider'
import { AtendimentoMedicoListGrouping } from './AtendimentoMedicoListGrouping'
import { AtendimentoMedicoListSearchComponent } from './AtendimentoMedicoListSearchComponent'
import { AtendimentoMedicoListToolbar } from './AtendimentoMedicoListToolbar'

const AtendimentoMedicoListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='border-0 pt-6'>
      <AtendimentoMedicoListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <AtendimentoMedicoListGrouping /> : <AtendimentoMedicoListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { AtendimentoMedicoListHeader }
