import {KTSVG} from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'

const AtendimentoEditModalHeader = () => {
  const {setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Visualizar Atendimento</h2>
      {/* end::Modal title */}
      
      {/* begin::Close */}
      <div
        className='btn btn-icon '
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {AtendimentoEditModalHeader}
