import axios, { AxiosResponse } from 'axios'
import { ID } from '../../../../_metronic/helpers'
import { AuthModel, UserModel, Picture, UsuariosQueryResponse, Message } from './_models'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/usuarios/me`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const CONFIRM_ACCOUNT_URL = `${API_URL}/auth/confirm-account`
export const USUARIO_URL = `${API_URL}/usuarios`
export const USUARIO_PICTURE_URL = `${API_URL}/usuarios/picture`
export const USUARIO_AVATAR_URL = `${API_URL}/usuarios/avatar`
export const USUARIO_CERTIFICADO_URL = `${API_URL}/usuarios/certificado`

// Server should return AuthModel
export function login(email: string, senha: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    senha,
  })
}
export const getUsuarios = (query: string): Promise<UsuariosQueryResponse> => {
  return axios
    .get(`${USUARIO_URL}?${query}`)
    .then((d: AxiosResponse<UsuariosQueryResponse>) => d.data)
}
// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email: email,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function resetPasswordUser(
  id: String,
  senhaAtual: string,
  novaSenha: string,
  confirmaNovaSenha: string

) {
  return axios.put<{ result: boolean }>(USUARIO_URL + "/password", {
    id,
    senhaAtual,
    novaSenha,
    confirmaNovaSenha,
  })
}

export function resetPasswordUserConfirm(
  token: String,
  senha: string,
  confirmaSenha: string

) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    token: token,
    senha: senha,
    confirmaSenha: confirmaSenha,
  })
}
export function confirmAccount(
  token: String,
) {
  return axios.post<{ result: boolean }>(CONFIRM_ACCOUNT_URL, {
    token: token,
  })
}

export function updateUser(
  id: number,
  nome: string,
  email: string,
  dataNascimento: String,
  sexo: String,
) {
  return axios.put<{ result: boolean }>(USUARIO_URL + "/", {
    id: id,
    nome: nome,
    sexo: sexo,
    nascimento: dataNascimento,
    email: email,
  })
}
export function updateEmailUser(
  id: ID,
  email: string
) {
  return axios.put<{ result: boolean }>(USUARIO_URL + "/" + { id }, {
    email: email
  })
}
export function persistCertificadoAdmin(idFuncionario: ID, certificadoValue?: File) {
  if (certificadoValue) {
    var reader = new FileReader();
    reader.readAsDataURL(certificadoValue);
    reader.onload = function () {
      editCertificadoAdm('u', reader.result, certificadoValue.name, certificadoValue.type, idFuncionario)
        .then((a) => {
          Swal.fire({
            text: 'Certificado cadastrado com sucesso!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        })
        .catch((error) => {
          Swal.fire({
            text: error.response?.data.data.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        })
    };

  } else {
    Swal.fire({
      text: "Arquivo não informado",
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: "Ok",
      customClass: {
        confirmButton: "btn btn-primary"
      }
    })
  }
}
export async function persistCertificado(password: string, certificadoValue?: File) {
  if (certificadoValue) {
    var reader = new FileReader();
    reader.readAsDataURL(certificadoValue);
    reader.onload = async function () {
      await editCertificado('u', reader.result, certificadoValue.name, certificadoValue.type, password)
        .catch((error) => {
          Swal.fire({ 
            text: error.response?.data.data.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        })
    }
  } else {
    Swal.fire({
      text: "Arquivo não informado",
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: "Ok",
      customClass: {
        confirmButton: "btn btn-primary"
      }
    })
  }
}
function editCertificadoAdm(
  action: string,
  fileContent: any,
  fileName: string,
  fileType: string,
  idFuncionario: ID
) {
  return axios.post<{ result: boolean }>(USUARIO_URL + '/' + idFuncionario + '/certificado', {
    action: action,
    fileContent: fileContent,
    fileName: fileName,
    fileType: fileType
  })
}
function editCertificado(
  action: string,
  fileContent: any,
  fileName: string,
  fileType: string,
  password: string
) {
  return axios.post<{ result: boolean }>(USUARIO_CERTIFICADO_URL, {
    action: action,
    fileContent: fileContent,
    fileName: fileName,
    fileType: fileType,
    senha: password

  })
}
export function editAvatarUser(
  action: string,
  fileContent: any,
  fileName: string,
  fileType: string
) {
  return axios.post<{ result: boolean }>(USUARIO_AVATAR_URL, {
    action: action,
    fileContent: fileContent,
    fileName: fileName,
    fileType: fileType
  })
}
export function editImageUser(
  data: any,
) {
  return axios.post<{ result: boolean }>(USUARIO_PICTURE_URL + 'action=u', {
    file: data
  },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
}
export function getAvatarUser() {
  return axios.get<Picture>(USUARIO_AVATAR_URL)
}
export function reenvioTokenConfirm(id: ID) {
  return axios.put(USUARIO_URL + '/' + id + '/confirma')
}

export const getAvatarUserById = (id: ID): Promise<Picture | undefined> => {
  return axios
    .get(`${USUARIO_URL}/${id}/avatar`)
    .then((response: AxiosResponse<Picture>) => response.data)
}

export const getUserById = (id: ID): Promise<UserModel | undefined> => {
  return axios
    .get(`${USUARIO_URL}/${id}`)
    .then((response: AxiosResponse<UserModel>) => response.data)
}
export function getUserByCPF(cpf: string) {
  return axios.get<UserModel>(USUARIO_URL + '/cpf/' + cpf)
}
export function getUserByPessoa(idPessoa: ID) {
  return axios.get<UserModel>(USUARIO_URL + '/pessoa/' + idPessoa)
}
export function getLoggedUser() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

