import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { AtendimentoMedicoListWrapper } from "./atendimentos-medico-list/AtendimentoMedicoList"
import { TeleAtendimento } from "./atendimento-video/tele-atendimento"
import { HistoricoClinico } from "./atendimento-historico/historico"
import {  PrescricaoWrapper } from "./prescricao/MemedReceita"
import { AtendimentoAsoListWrapper } from "../atendimento-ASO/aso-list/AtendimentoAsoList"
import { HistoricoListWrapper } from "./atendimento-historico/HistoricoList"


const atendimentosBreadcrumbs: Array<PageLink> = [
    {
      title: 'Atendimentos',
      path: '/apps/atendimentosMedicos/lista',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '/apps/atendimentosMedicos/teleAtendimento',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '/apps/atendimentosMedicos/historicoClinico',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '/apps/atendimentosMedicos/prescricao',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
  const AtendimentosMedicosPage = () => {
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <PageTitle breadcrumbs={atendimentosBreadcrumbs}>Lista</PageTitle>
                <AtendimentoMedicoListWrapper />
              </>
            }
          />
          <Route
            path='teleAtendimento'
            element={
              <>
                <PageTitle breadcrumbs={atendimentosBreadcrumbs}>Tele Atendimento</PageTitle>
                < TeleAtendimento/>
              </>
            }
          />
          <Route
            path='historicoClinico'
            element={
              <>
                <PageTitle breadcrumbs={atendimentosBreadcrumbs}>Histórico Clínico</PageTitle>
                <HistoricoListWrapper />
              </>
            }
          />
        </Route>
        <Route
            path='prescricao'
            element={
              <>
                <PageTitle>Prescrição</PageTitle>
                <PrescricaoWrapper />
              </>
            }
          />
        <Route index element={<Navigate to='/apps/atendimentosMedicos/lista' />} />
      </Routes>
    )
  }
  
  export default AtendimentosMedicosPage
  