/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { useDebounce, initialQueryState } from '../../../../../../../_metronic/helpers';


interface DownloadAsoListSearchComponentProps {
}


const DownloadAsoListSearchComponent: React.FC<DownloadAsoListSearchComponentProps> = () => {
  const [idEmpresaConsulta, setIdEmpresaConsulta] = useState<number | undefined>(undefined);
  const { updateState } = useQueryRequest();
  const [searchTerm, setSearchTerm] = useState<string>('situacaoAso:FINALIZADO');
  const [dataSel, setDataSel] = useState<string>('')
  const [nomeSel, setNomeSel] = useState<string>('')
  const [filters, setFilters] = useState<Array<String>>(idEmpresaConsulta ? new Array(String(idEmpresaConsulta)) : []);
  const debouncedSearchTerm = useDebounce(searchTerm, 150);


  const handleChangePaciente = (e: any) => {
    const nome = e.target.value;
    setNomeSel(nome);
  
    let search = '';
    if (dataSel) search += 'inicio>' + dataSel + "T00:00:00";
    if(nome) search += (search ? ',' : '') + 'paciente.pessoa.nome~*' + nome + '*';
    search += (search ? ',' : '') + 'situacaoAso:FINALIZADO';
  
    setSearchTerm(search);
  }
  
  const handleChangeData = (e: any) => {
    console.log(e.target.value)
    let dateValue = e.target.value;

    setDataSel(dateValue);
  
    let search = '';
    if (String(e.target.value)) search += 'inicio>' + dateValue + "T00:00:00";
    if(nomeSel) search += (search ? ',' : '') + 'paciente.pessoa.nome~*' + nomeSel + '*';
    search += (search ? ',' : '') + 'situacaoAso:FINALIZADO';
  
    setSearchTerm(search);
  }
  


  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ filter: filters, search: debouncedSearchTerm, ...initialQueryState });
    }
  }, [debouncedSearchTerm]);

  return (
    <div className='container'>
      <div className='row mb-3'>
        <div className='col-lg-6'>
          <div className='mb-3'>
            <label className='form-label fs-6 fw-bolder'>Paciente</label>
            <input
              type='text'
              data-kt-user-table-filter='nome'
              className='form-control form-control-solid w-100'
              placeholder='Buscar Pacientes'
              onChange={handleChangePaciente}
            />
          </div>
        </div>
        <div className='col-lg-4'>
          <label className='form-label fs-6 fw-bolder mb-1 ps-5'>Consulta ASO finalizada a partir de:</label>
          <input
              type='date'
              data-kt-user-table-filter='nome'
              max={new Date().toISOString().split("T")[0]}
              className='form-control form-control-solid'
              placeholder='Digite data inicial'
              value={dataSel}
              onChange={handleChangeData}
            />
        </div>
      </div>
    </div>
  );
}

export { DownloadAsoListSearchComponent };
