import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { DownloadAsoListWrapper } from './download-aso-list/DownloadAsoList'


const DownloadAsoBreadcrumbs: Array<PageLink> = [
  {
    title: 'Baixar ASOs',
    path: '/apps/downloadAso/download',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DownloadAsoPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='download'
          element={
            <>
              <PageTitle breadcrumbs={DownloadAsoBreadcrumbs}>Baixar ASOs</PageTitle>
              <DownloadAsoListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/downloadAso/download' />} />
    </Routes>
  )
}

export default DownloadAsoPage
