
import { useListView } from '../../core/ListViewProvider'
import { EmpresasClienteListGrouping } from './EmpresasClienteListGrouping'
import { EmpresasClienteListSearchComponent } from './EmpresasClienteListSearchComponent'
import { EmpresasClienteListToolbar } from './EmpresasClienteListToolbar'

const EmpresasClienteListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <EmpresasClienteListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <EmpresasClienteListGrouping /> : <EmpresasClienteListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {EmpresasClienteListHeader}
