// @ts-nocheck
import { Column } from 'react-table'
import { AtendimentoIniciado } from '../../../atendimento/core/_models'
import { AtendimentoAsoSelectionHeader } from './AtendimentoAsoSelectionHeader'
import { AtendimentoAsoSelectionCell } from './AtendimentoAsoSelectionCell'
import { AtendimentoAsoCustomHeader } from './AtendimentoAsoCustomHeader'
import { AtendimentoAsoDownloadCell } from './AtendimentoAsoDownloadCell'


const AtendimentosAsoDownloadColumns: ReadonlyArray<Column<AtendimentoIniciado>> = [
  {
    Header: (props) => <AtendimentoAsoSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <AtendimentoAsoSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <AtendimentoAsoCustomHeader tableProps={props} title='Protocolo' className='min-w-125px' />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => <AtendimentoAsoCustomHeader tableProps={props} title='Nome Paciente' />,
    id: 'paciente.pessoa.nome',
    accessor: 'paciente.pessoa.nome'
  },
  {
    Header: (props) => <AtendimentoAsoCustomHeader tableProps={props} title='Empresa' className='min-w-125px' />,
    id: 'empresa.nomeFantasia',
    accessor: 'empresa.nomeFantasia'
  },
  {
    Header: (props) => (
      <AtendimentoAsoCustomHeader tableProps={props} title='Estado' className='min-w-125px' />
    ),
    id: 'estado.descricao',
    accessor: 'estado.descricao',
  },
  {
    Header: (props) => (
      <AtendimentoAsoCustomHeader tableProps={props} title='Data finalização' className='min-w-125px' />
    ),
    id: 'terminoFormatado',
    accessor: 'terminoFormatado',
  },
  // {
  //   Header: (props) => (
  //     <EmpresaCustomHeader tableProps={props} title='CNPJ' className='min-w-125px' />
  //   ),
  //   accessor: 'cnpj',
  // },
  {
    Header: (props) => (
      <AtendimentoAsoCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <AtendimentoAsoDownloadCell id={props.data[props.row.index].id} />,

  },
]

export { AtendimentosAsoDownloadColumns }
