/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { ID, initialQueryState, KTSVG, useDebounce } from '../../../../../../../_metronic/helpers'
import { Empresa } from '../../../empresas-list/core/_models'
import { getClienteById, getEmpresaById } from '../../../empresas-list/core/_requests'
import { useQueryRequest } from '../../core/QueryRequestProvider'


const RiscoClienteListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [nomeSel, setNomeSel] = useState<string>('')
  const [situacaoSel, setSituacaoSel] = useState<string>('')
  const [empresa, setEmpresa] = useState<Empresa | null>(null)

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const urlParams = new URLSearchParams(window.location.search);
  const idCliente = urlParams.get('cliente');
  const idEmpresa = urlParams.get('empresa');
  const title = empresa?.nome
    ? <span className="fs-4">Risco do Empregador ASO <strong>{empresa?.nome}</strong></span>
    : 'Setor';
  const [filters, setFilters] = useState<Array<String>>([])
  // Effect for API call
  // useEffect(
  //   () => {
  //     if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
  //       updateState({ search: debouncedSearchTerm, sort: 'nome', order: 'asc', ...initialQueryState })
  //     }
  //   },
  //   [debouncedSearchTerm] // Only call effect if debounced search term changes
  //   // More details about useDebounce: https://usehooks.com/useDebounce/
  // )
  const handleChangeNome = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setNomeSel(String(e.target.value))
    setSearchTerm((String(e.target.value) !== '' ? 'nome~*' + String(e.target.value) + '*' : ''))
  }
  const handleChangeSituacao = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setSituacaoSel(String(e.target.value))
    setSearchTerm((String(e.target.value) !== '' ? 'ativa:' + String(e.target.value) + ',' : '') + (nomeSel !== '' ? 'nome~*' + nomeSel + '*' + ",'nomeFantasia~*" + nomeSel + '*' : ''))
  }
  const limparCampos = () => {
    setNomeSel('')
    setSearchTerm('')
  }

  useEffect(() => {

    const fetchData = async () => {
      try {
        const respostaEmpresa = await getClienteById(idCliente as ID);
        setEmpresa(respostaEmpresa as Empresa);
      } catch (error) {
        console.error('Erro ao obter dados da empresa:', error);
      }
    };
    if (idCliente) {
      fetchData();
    }

  }, [idCliente]);

  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='mb-5'>
        <label className='form-label  ps-1'>{title}</label>
        {/* <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        /> */}
        <input
          type='text'
          data-kt-user-table-filter='nome'
          className='form-control form-control-solid w-550px ps-1'
          placeholder='Digite o nome'
          value={nomeSel}
          onChange={handleChangeNome}
        />
      </div>
      <div className='d-flex align-items-left position-relative'>
        <a
          className='btn btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={limparCampos}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1 m-1' />
        </a>
      </div>
      {/* end::Search */}
    </div>
  )
}

export { RiscoClienteListSearchComponent }
