// @ts-nocheck
import { Column } from 'react-table'
import { AtendimentoIniciado } from '../../../../atendimento/core/_models'
import { AtendimentoMedicoActionsCell } from './AtendimentoMedicoActionsCell'
import { AtendimentoMedicoCustomHeader } from './AtendimentoMedicoCustomHeader'
import { AtendimentoMedicoSelectionCell } from './AtendimentoMedicoSelectionCell'
import { AtendimentoMedicoSelectionHeader } from './AtendimentoMedicoSelectionHeader'
import { PresencaColumn } from '../../../../atendimento/atendimento-list/table/columns/PresencaColumn'
import { OnlineColumn } from '../../../../atendimento/atendimento-list/table/columns/OnlineColumn'
import { DataColumn } from '../../../../atendimento/atendimento-list/table/columns/DataColumn'
import { useQueryRequest } from '../../core/QueryRequestProvider'


const atendimentosMedicosColumns: ReadonlyArray<Column<AtendimentoIniciado>> = [
  {
    Header: (props) => <AtendimentoMedicoSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <AtendimentoMedicoSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <AtendimentoMedicoCustomHeader tableProps={props} title='Protocolo'  />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => <AtendimentoMedicoCustomHeader tableProps={props} title='Nome Paciente'  />,
    id: 'paciente.pessoa.nome',
    accessor: 'paciente.pessoa.nome'
  },
  {
    Header: (props) => (
      <AtendimentoMedicoCustomHeader tableProps={props} title='Sinais'  />
    ),
    id: 'sinais',
    accessor: 'sinais',
  },
  {
    Header: (props) => (
      <AtendimentoMedicoCustomHeader tableProps={props} title='Estado' />
    ),
    id: 'estado.descricao',
    accessor: 'estado.descricao',
  },
  {
    Header: (props) => <AtendimentoMedicoCustomHeader tableProps={props} title='Especialidade'  />,
    id: 'especialidade',
    accessor: 'especialidade.nome'
  },
  {
    Header: (props) => (
      <AtendimentoMedicoCustomHeader tableProps={props} title='Data' />
    ),
    id: 'data',
    Cell: ({...props}) => <DataColumn atendimento={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <AtendimentoMedicoCustomHeader tableProps={props} title='Presença' />,
    id: 'presenca',
    Cell: ({...props}) => <PresencaColumn atendimento={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <AtendimentoMedicoCustomHeader tableProps={props} title='Chamada Online'  />,
    id: 'online',
    Cell: ({...props}) => <OnlineColumn atendimento={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <EmpresaCustomHeader tableProps={props} title='CNPJ' className='min-w-125px' />
  //   ),
  //   accessor: 'cnpj',
  // },
  {
    Header: (props) => (
      <AtendimentoMedicoCustomHeader tableProps={props} title='Ações'  />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <AtendimentoMedicoActionsCell atendimento={props.data[props.row.index]} />,

  },
]

export { atendimentosMedicosColumns }
