import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Empresa, EmpresasQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const ATENDIMENTO_URL = `${API_URL}/atendimentos`


const getEmpresas = (query: string): Promise<EmpresasQueryResponse> => {
  return axios
    .get(`${ATENDIMENTO_URL}?${query}`)
    .then((d: AxiosResponse<EmpresasQueryResponse>) => d.data)
}
const getEmpresaById = (id: ID): Promise<Empresa | undefined> => {
  return axios
    .get(`${ATENDIMENTO_URL}/${id}`)
    .then((response: AxiosResponse<Empresa>) => response.data)
}

const createEmpresa = (empresa: Empresa): Promise<Empresa | undefined> => {
  return axios
    .post(ATENDIMENTO_URL, empresa)
    .then((response: AxiosResponse<Response<Empresa>>) => response.data)
    .then((response: Response<Empresa>) => response.data)
}

const updateEmpresa = (empresa: Empresa): Promise<Empresa | undefined> => {
  return axios
    .put(`${ATENDIMENTO_URL}/${empresa.id}`, empresa)
    .then((response: AxiosResponse<Response<Empresa>>) => response.data)
    .then((response: Response<Empresa>) => response.data)
}

const deleteEmpresa = (empresaId: ID): Promise<void> => {
  return axios.delete(`${ATENDIMENTO_URL}/${empresaId}`);
}

const deleteSelectedAtendimento = (empresaIds: Array<ID>): Promise<void> => {
  const requests = empresaIds.map((id) => axios.delete(`${ATENDIMENTO_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getEmpresas, deleteEmpresa, deleteSelectedAtendimento, getEmpresaById, createEmpresa, updateEmpresa}
