import { ID, Response } from "../../../../../_metronic/helpers";
import { UserModel } from "../../../auth";
import { ColaboradorPcmso, EmpresaCliente } from "../../empresa/empresas-list/core/_models";
import { DadoAso } from "../../paciente-management/pacientes-list/core/_models";
export type Atendimento = {
  id?: ID,
  paciente?: Paciente,
  empressa?: Empresa,
  prontuario?: string,
  ativo?: boolean
}

export type Paciente = {
  id?: ID,
  pessoa?: Pessoa,
  prontuario?: string,
  nome?: string
  alergias?: Alergia[]
}
export type Alergia = {
  id?: ID,
  descricao?: String
}
export type Pessoa = {
  id?: ID,
  nome?: string,
  cpf?: string,
  sexo?: string,
  telefone?: string,
  cns?: string,
  dataNascimento?: string,
  dataNascimentoFormatada?: string,
  avatar?: string
}

export type Empresa = {
  id?: ID,
  nome?: string,
  nomeFantasia?: string,
  cnpj?: string,
  ativa?: boolean,
  responsavel?: Pessoa,
}
export enum TipoAtendimento {
  ASO = 'ASO',
  PA = 'PA'
}

export type TipoAtendimentoItem = {
  descricao: string,
  name: string,
  valor: string
}



export type AtendimentoIniciado = {
  id: ID,
  paciente?: Paciente,
  protocolo?: string,
  dispositivos?: Dispositivo[],
  inicio?: string,
  termino?: string,
  estado?: Estado,
  criador?: UserModel,
  anotacoes?: Anotacoes[],
  mensagens?: Mensagem[],
  urlSalaVideo?: string,
  inicioFormatado?: string,
  terminoFormatado?: string,
  dataSolicitacaoFormatada?: string,
  solicitacaoFormatada?: string,
  dataSolicitacao?: string,
  informacoes?: Informacoes[],
  responsavel?: Profissional,
  empresa?: Empresa,
  tipo?: TipoAtendimento,
  cids?: Array<{
    id?: ID,
    ordem?: number,
    cid: CID,
  }>,
  pacienteOnline?: boolean,
  prescricoes?: Prescricao[],
  procedimentos?: Procedimento[],
  fatoresRisco?: FatorRisco[],
  resultado?: string,
  tipoAso?: string,
  cliente?: EmpresaCliente,
  pcmsoCliente?: ColaboradorPcmso,
  situacaoAso?: string
  data?: any,
  anexos?: Anexo[]
  dadosAso?: DadoAso
  presencaPacienteFormatada?: string
}

export type Anexo = {
  id?: ID,
  data?: String,
  url?: String,
  tipo?: Tipo,
  arquivo?: ArquivoAtendimento,
  dataFormatada?: String
}
export type TipoAnexo = {
  id?: ID,
  descricao?: String
}
export type ArquivoAtendimento = {
  id?: ID,
  name: String,
  type: String,
  length: Number,
  contentBase64: String
}
export type AsoEmitido = {
  id?: ID,
  name?: string,
  type?: string,
  contentBase64?: String,
  length?: Number,
}
export type FatorRisco = {
  id?: ID,
  fatorRisco?: string,
  descricaoFatorRisco: string,
  valor?: string
}
export type TipoASO = {
  name?: string,
  valor?: string,
  descricao?: string
}
export type Procedimento = {
  id?: ID,
  data?: String,
  nome?: String,
  descricao?: String,
  anexo?: String | ID | ArquivoAtendimento | null
}
export type PendenciaAso = {
  atendimento?: string[],
  responsavel?: string[],
  paciente?: string[]
}
export type SituacaoAso = {
  name?: string,
  valor?: string,
  descricao?: string
}
export type Prescricao = {
  identificadorPrescricao?: String,
  identificadoMedico?: String,
  data?: String,
  medicamentos?: Medicamento[]
}
export type Medicamento = {
  identificador?: String,
  nome?: String,
  descricao?: String,
  titulo?: String,
  subTitulo?: String,
  posologia?: String
}
export type Informacoes = {
  id?: ID,
  anexo?: ID,
  data?: string,
  profissional?: Profissional,
  tipo?: Tipo,
  tiposInformacoes?: TipoInformacao[],
  descricao?: string,
  unidade?: string,
  valor?: string,
  dataFormatada?: string,
  dispositivo?: Dispositivo
}
export type InformacaoDispositivo = {
  id: ID | null | undefined;
  dispositivo?: Dispositivo,
  informacoes?: TipoInformacao[]
}
export type TipoAtendimentoEmpresa = {
  descricao?: string,
  name?: string,
  valor?: string,
}
export type TipoInformacao = {
  id?: ID,
  anexo?: ID,
  descricao?: String,
  unidade?: String,
  valor?: Number,
  data?: String,
  dataFormatada?: String
}
export type Profissional = {
  id?: ID,
  nome?: string,
  // token?: string,
  cpf?: string,
  primeiroNome?: string,
  sobreNome?: string
}

export type TiposDispositivos = "OXIMETRO" | "BALANCA" | "PRESSAO" | "TERMOMETRO" | "INSPECAO" | "CAMERA" | "OTOSCOPIO" | "GLICOSIMETRO";

export type Dispositivo = {
  leituras: any;
  id: Number,
  tipo: TiposDispositivos,
  serie?: string,
  descricao?: string
}
export type Tipo = {
  id?: ID,
  descricao?: string,
  unidade?: string,
  siglaUnidade?: string
}

export type Estado = {
  id?: ID,
  nome?: string,
  descricao?: string,
  ativo?: string,
  estadoInicial?: boolean,
  estadoFinal?: boolean,
  proximosEstados?: string[],
}

export type Anotacoes = {
  id?: ID,
  tipo?: string,
  anotacao?: string,
  dataFormatada?: string,
  data?: string,
  profissional?: Profissional
}

export type Mensagem = {
  mensagem?: string
}
export type CID = {
  id?: ID,
  codigo?: string
  descricao?: string
  sexo?: string,
  obito?: boolean,
  classificacao?: string,
  categoria?: string,
  grupo?: string,
  capitulo?: string
}

export type AtendimentoQueryResponse = Response<Array<AtendimentoIniciado>>
export type AtendimentoIniciadoQueryResponse = Response<Array<AtendimentoIniciado>>
export type CIDQueryResponse = Response<Array<CID>>
export type EstadoQueryResponse = Response<Array<Estado>>
export type SituacaoAsoQueryResponse = Response<Array<SituacaoAso>>
export type TipoResultadoASOQueryResponse = Response<Array<TipoASO>>

export const initialAtendimento: AtendimentoIniciado = {
  id: -1
}
