// @ts-nocheck
import {Column} from 'react-table'
import {EmpresaInfoCell} from './EmpresaInfoCell'
import {EmpresaSelectionCell} from './EmpresaSelectionCell'
import {EmpresaCustomHeader} from './EmpresaCustomHeader'
import {EmpresaSelectionHeader} from './EmpresaSelectionHeader'
import {EmpresasActionsCell} from './EmpresaActionsCell'

const empresasColumns: ReadonlyArray<Column<Empresa>> = [
  {
    Header: (props) => <EmpresaSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <EmpresaSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <EmpresaCustomHeader tableProps={props} title='Razão social' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='Nome fantasia' className='min-w-125px' />
    ),
    id: 'nomeFantasia',
    accessor: 'nomeFantasia',
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='CNPJ' className='min-w-125px' />
    ),
    accessor: 'cnpj',
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'ativa',
    Cell: ({ value }) => (
      <span className={`badge rounded-pill p-2 ${value ? 'bg-success text-white' : 'bg-secondary'} badge-lg fs-5`}>
        {value ? 'Ativa' : 'Inativa'}
      </span>
    ),
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <EmpresasActionsCell id={props.data[props.row.index].id} row={props.row} />,
  },
]

export {empresasColumns}
