
import { RelatorioListSearchComponent } from './RelatorioSearchComponent'

const RelatorioListHeader = () => {
  // const { selected } = useListView()
  return (
    <div className='card-header'>
      <RelatorioListSearchComponent />
    </div>
  )
}

export { RelatorioListHeader }
