import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import {FieldConfig, Formulario, FormulariosQueryResponse, Options} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const FORMULARIOS_URL = `${API_URL}/formularios`
const CAMPOS_URL = `${API_URL}/formularios/campo`
const OPCOES_URL = `${API_URL}/formularios/opcao`

export const getFormularioById = (id: ID): Promise<Formulario | undefined> => {
  return axios.get(`${FORMULARIOS_URL}/${id}`).then((resp: AxiosResponse<Formulario>) => resp.data)
}

export const createFormulario = (formulario: any): Promise<any> => {
  return axios.post(`${FORMULARIOS_URL}`, formulario)
}

export const updateFormulario = (formulario: any): Promise<any> => {
  console.log('updateformulario', formulario)
  return axios.put(`${FORMULARIOS_URL}`, formulario)
}

export const createCampo = (field: any, formId: ID): void => {
  console.log('create campo', field, formId)
  axios.post(`${CAMPOS_URL}/${formId}`, field)
}

export const updateCampo = (field: FieldConfig, id: ID): void => {
  console.log('updateCampos', field, id)
  axios.put(`${CAMPOS_URL}/${id}`, field)
}

export const updateCamposOrder = (fields: FieldConfig[]): void => {
  console.log('updateCampoOrder', fields)
  const campos = fields.map((field) => ({...field, opcoes: []}))
  campos.forEach((field) => axios.put(`${CAMPOS_URL}/${field.id}`, field))
}

export const deleteCampos = (ids: number[]): void => {
  ids.forEach((id) => axios.delete(`${CAMPOS_URL}/${id}`))
}

export const createOpcao = (option: Options, fieldID: ID): void => {
  console.log('create option', option, fieldID)
  axios.post(`${OPCOES_URL}/${fieldID}`, option)
}

export const updateOpcao = (option: Options, fieldID: ID): void => {
  console.log('updateOpcaos', option, fieldID)
  axios.put(`${OPCOES_URL}/${fieldID}`, option)
}

export const deleteOpcoes = (ids: ID[], fieldID: ID): void => {
  ids.forEach((id) => axios.delete(`${OPCOES_URL}/${fieldID}?idOpcao=${id}`))
}

// conversar sobre inconsistencias do codigo
