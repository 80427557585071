import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { useListView } from '../../core/ListViewProvider'

import { downloadAso, generateAso, getAtendimentoById } from '../../../../atendimento/core/_requests'

const DownloadAsoListGrouping = () => {
  const { selected, clearSelected } = useListView()


  const handleEmitirAso = async () => {
    const downloadPromises = selected.map((id) => {
      return getAtendimentoById(id).then((atendimento) => {
        if (atendimento?.situacaoAso !== 'GERADO' && atendimento?.estado?.nome !== 'Finalizado') {
          return generateAso(id).catch((error) => {
            throw error;
          });
        } else {
          return downloadAso(id).catch((error) => {
            throw error;
          });
        }
      });
    });

    Promise.all(downloadPromises)
      .then((asos) => {
        asos.forEach((aso) => {
          if (aso !== undefined) {
            const a = document.createElement("a");
            a.href = "data:application/pdf;base64," + aso.contentBase64;
            a.download = String(aso.name);
            a.click();
          }
        });
        clearSelected();
      })
      .catch((error) => {
        const err = error as AxiosError;
        Swal.fire({
          text: err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });

        clearSelected();
      });
  };


  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selecionado(s)
      </div>

      <button
        type='button'
        className='btn btn-success'
        onClick={async () => await handleEmitirAso()}
      >
        Baixar Aso(s)
      </button>
    </div>
  )
}

export { DownloadAsoListGrouping }
