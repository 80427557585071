/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { initialQueryState, KTSVG, useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'


const EmpresasListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [nomeSel, setNomeSel] = useState<string>('')
  const [situacaoSel, setSituacaoSel] = useState<string>('')
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const [filters, setFilters] = useState<Array<String>>(new Array())
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ search: debouncedSearchTerm, sort: 'nomeFantasia', order: 'asc', ...initialQueryState })
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  const handleChangeNome = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setNomeSel(String(e.target.value))
    setSearchTerm((situacaoSel !== '' ? 'ativa:' + situacaoSel + ',' : '') + (String(e.target.value) !== '' ? 'nome~*' + String(e.target.value) + '*' + ",'nomeFantasia~*" + String(e.target.value) + '*' : ''))
  }
  const handleChangeSituacao = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setSituacaoSel(String(e.target.value))
    setSearchTerm((String(e.target.value) !== '' ? 'ativa:' + String(e.target.value) + ',' : '') + (nomeSel !== '' ? 'nome~*' + nomeSel + '*' + ",'nomeFantasia~*" + nomeSel + '*' : ''))
  }
  const limparCampos = () => {
    setNomeSel('')
    setSearchTerm('')
    setSituacaoSel('')
  }
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='mb-5'>
        <label className='form-label fw-bold ps-1'>Empresa</label>
        {/* <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        /> */}
        <input
          type='text'
          data-kt-user-table-filter='nome'
          className='form-control form-control-solid w-550px ps-1'
          placeholder='Digite o nome'

          onChange={handleChangeNome}
        />
      </div>
      <div className='mb-5 mx-1'>
        <label className='form-label fw-bold ps-1'>Situação</label>
        <select
          name='situacao'
          aria-label='Selecione uma situação'
          data-control='select2'
          className='form-select ps-1'
          onChange={handleChangeSituacao}
          value={situacaoSel}
        >
          <option value=''>Todas</option>
          <option value='true'>Ativas</option>
          <option value='false'>Inativas</option>
        </select>
      </div>
      <div className='d-flex align-items-left position-relative'>
        <a
          className='btn btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={limparCampos}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-1 m-1' />
        </a>
      </div>
      {/* end::Search */}
    </div>
  )
}

export { EmpresasListSearchComponent }
