import {FC, Suspense, lazy, useEffect, useState} from 'react'
import {useAuth} from '../modules/auth'
import {Navigate, Route, RouteProps, Routes} from 'react-router-dom'

import {AtendimentosListWrapper} from '../modules/apps/atendimento/atendimento-list/AtendimentoList'
import AtendimentosMedicosPage from '../modules/apps/atendimento_medicos/AtendimentoMedicoPage'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {DashboardAdmin} from '../pages/dashboard/DashboardAdmin'
import {DashboardEmpresa} from '../pages/dashboard/DashboardEmpresa'
import {DashboardProfissional} from '../pages/dashboard/DashboardProfissional'
import EmpresasPage from '../modules/apps/empresa/EmpresaPage'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {MenuTestPage} from '../pages/MenuTestPage'
import TopBarProgress from 'react-topbar-progress-indicator'
import {WithChildren} from '../../_metronic/helpers'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {getLoggedUser} from '../modules/auth/core/_requests'
import Swal from 'sweetalert2'
import EmpresasClientePage from '../modules/apps/empresa/EmpresaPageCliente'
import DownloadAsoPage from '../modules/apps/atendimento-ASO/DownloadAsoPage'
import RelatorioPage from '../modules/apps/relatorios/RelatorioPage'
import ApiPage from '../modules/apps/api/ApiPage'
import EspecialidadePage from '../modules/apps/especialidades/EspecialidadesPage'
import FormulariosPage from '../modules/apps/formularios/FormulariosPage'

interface RoutesPropsData extends RouteProps {
  role?: string[]
}

const PrivateRoutes: React.FC<RoutesPropsData> = ({role, ...rest}) => {
  const [permissions, setPermissions] = useState([] as string[])
  const [permissionUser] = useState([] as string[])
  const permissaoMedico = 'EMPLOYEE'
  const permissaoUsuario = 'USER'
  const permissaoAdmin = 'ADMIN'
  const permissaoSuperAdmin = 'SUPER_ADMIN'
  const permissaoSuperMedico = 'SUPER_MEDICO'
  const permissaoSuperGerente = 'SUPER_GERENTE'

  permissionUser.push('USER')
  const [permissionEmployee] = useState([] as string[])
  permissionEmployee.push(permissaoMedico, permissaoUsuario)
  const [permissionAdm] = useState([] as string[])
  permissionAdm.push(permissaoMedico, permissaoUsuario, permissaoAdmin, permissaoSuperGerente)
  const [permissionSuperAdm] = useState([] as string[])
  permissionSuperAdm.push(permissaoMedico, permissaoUsuario, permissaoAdmin, permissaoSuperAdmin)
  const {currentUser} = useAuth()

  function loadRoles() {
    getLoggedUser()
      .then((responseData) => {
        if (responseData.data.grupo !== undefined) {
          const findRole = responseData.data.grupo.nome
          if (findRole === 'USER') {
            setPermissions(permissionUser)
          } else if (findRole === 'EMPLOYEE') {
            setPermissions(permissionEmployee)
          } else if (findRole === 'ADMIN') {
            setPermissions(permissionAdm)
          } else if (findRole === 'SUPER_ADMIN') {
            setPermissions(permissionSuperAdm)
          } else if (findRole === 'SUPER_MEDICO') {
            setPermissions([permissaoSuperMedico])
          } else if (findRole === 'SUPER_GERENTE') {
            setPermissions([permissaoSuperGerente])
          }
        } else {
          Swal.fire({
            text: 'Erro ao processar os dados do usuário',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    loadRoles()
  }, [])

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const PacientePage = lazy(() => import('../modules/apps/paciente-management/PacientesPage'))
  const AtendimentosAsoPage = lazy(
    () => import('../modules/apps/atendimento-ASO/AtendimentoAsoPage')
  )
  const AtendimentosPage = lazy(() => import('../modules/apps/atendimento/AtendimentosPage'))
  const FuncionarioPage = lazy(
    () => import('../modules/apps/funcionarios-management/FuncionariosPage')
  )
  const ResetPasswordPage = lazy(() => import('../modules/auth/components/ResetPasswordPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Pages */}

        <Route
          path='confirm-account'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <>
          {/* <Route
            path='apps/atendimentos/*'
            element={
              <SuspensedView>
                <AtendimentosPage />
              </SuspensedView>
            }
          /> */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          {/* <Route path='*' element={<Navigate to='/apps/atendimentos/paciente' />} /> */}
        </>
        {permissions.find(
          (r) => r === permissaoMedico || r === permissaoSuperMedico || r === permissaoSuperGerente
        ) ? (
          <>
            <Route
              path='apps/pacientes-management/*'
              element={
                <SuspensedView>
                  <PacientePage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/atendimentosAso/*'
              element={
                <SuspensedView>
                  <AtendimentosAsoPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/downloadAso/*'
              element={
                <SuspensedView>
                  <DownloadAsoPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/relatorios/*'
              element={
                <SuspensedView>
                  <RelatorioPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/atendimentosMedicos/*'
              element={
                <SuspensedView>
                  <AtendimentosMedicosPage />
                </SuspensedView>
              }
            />
            <Route path='auth/*' element={<Navigate to='/apps/atendimentosMedicos/lista' />} />
            {permissions.find((r) => r === permissaoSuperGerente) ? (
              <Route index element={<Navigate to='/apps/relatorios' />} />
            ) : (
              <Route index element={<Navigate to='/apps/atendimentosMedicos/lista' />} />
            )}
          </>
        ) : (
          <>{/* <Route path='*' element={<Navigate to='/error/404' />} /> */}</>
        )}

        {permissions.find(
          (r) =>
            r === permissaoAdmin ||
            r === permissaoSuperMedico ||
            r === permissaoSuperAdmin ||
            r === permissaoSuperGerente
        ) ? (
          <>
            <Route
              path='apps/funcionarios-management/*'
              element={
                <SuspensedView>
                  <FuncionarioPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/empresas/*'
              element={
                <SuspensedView>
                  <EmpresasPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/api'
              element={
                <SuspensedView>
                  <ApiPage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/especialidades'
              element={
                <SuspensedView>
                  <EspecialidadePage />
                </SuspensedView>
              }
            />
            <Route
              path='apps/formularios/*'
              element={
                <SuspensedView>
                  <FormulariosPage />
                </SuspensedView>
              }
            />

            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/admin' />} />
            <Route path='admin' element={<DashboardAdmin />} />
            <Route path='empresa' element={<DashboardEmpresa />} />
            <Route path='profissional' element={<DashboardProfissional />} />
            <Route index element={<DashboardProfissional />} />
          </>
        ) : (
          <>{/* <Route path='*' element={<Navigate to='/error/404' />} /> */}</>
        )}
        {permissions.find(
          (r) =>
            r === permissaoAdmin ||
            r === permissaoMedico ||
            r === permissaoSuperMedico ||
            r === permissaoSuperAdmin
        ) ? (
          <Route
            path='apps/empresasCliente/*'
            element={
              <SuspensedView>
                <EmpresasClientePage />
              </SuspensedView>
            }
          />
        ) : (
          <></>
        )}
        {permissions.find((r) => r === permissaoUsuario) ? (
          <>
            <Route
              path='apps/atendimentos/*'
              element={
                <SuspensedView>
                  <AtendimentosPage />
                </SuspensedView>
              }
            />
            <Route path='paciente' element={<AtendimentosListWrapper />} />
            <Route path='auth/*' element={<Navigate to='/apps/atendimentos/paciente' />} />
            <Route index element={<Navigate to='/apps/atendimentos/paciente' />} />
          </>
        ) : (
          <></>
        )}
        {permissions.find((r) => r === permissaoUsuario) &&
        currentUser?.vinculos?.find((r) => r.cargo === 'COLABORADOR') ? (
          <>
            <Route
              path='apps/empresasCliente/*'
              element={
                <SuspensedView>
                  <EmpresasClientePage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          <></>
        )}
        {/* Page PAciente */}
        {/* {permissions.find(r => r === permissaoPaciente) ? (
          <Route path='*' element={<Navigate to='/apps/atendimentos/paciente' />} />
        ) : (
          <></>
        )} */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
