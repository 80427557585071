// @ts-nocheck
import { Column } from 'react-table'
import { CargoSelectionCell } from './CargoSelectionCell'
import { CargoCustomHeader } from './CargoCustomHeader'
import { CargoActionsCell } from './CargoActionsCell'
import { CargoCliente } from '../../../empresas-list/core/_models'
import { CargoSelectionHeader } from './CargoSelectionHeader'


const CargoColumns: ReadonlyArray<Column<CargoCliente>> = [
  {
    Header: (props) => <CargoSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <CargoSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CargoCustomHeader tableProps={props} title='Cargo' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  },
  {
    Header: (props) => (
      <CargoCustomHeader tableProps={props} title='Código' className='min-w-125px' />
    ),
    accessor: 'codigo',
  },
  {
    Header: (props) => (
      <CargoCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <CargoActionsCell id={props.data[props.row.index].id} />,
  },
]

export { CargoColumns }
