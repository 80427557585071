/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useState } from 'react'

import { KTSVG } from '../../../../../../_metronic/helpers'
import { PacienteFindModalForm } from './PacienteFindModalForm'

const PacienteFindModal: FC = () => {
  //kt_upload_imagem_perfil
  return (
    <div className='modal fade' id='kt_paciente_find_modal' aria-hidden='true'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <div
              className='btn btn-icon '
              data-modal_add_paciente='close'
              style={{ cursor: 'pointer' }}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            </div>
          </div>
          <div className='modal-body pt-0 pb-15 px-15 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Localizar Paciente</h1>              
            </div>
          
            <div className=''>
              <PacienteFindModalForm />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export { PacienteFindModal }
