// @ts-nocheck
import {Column} from 'react-table'
import { Atendimento } from '../../../core/_models'
import { AtendimentoSelectionHeader } from './AtendimentoSelectionHeader'
import { AtendimentoCustomHeader } from './AtendimentoCustomHeader'
import { AtendimentoSelectionCell } from './AtendimentoSelectionCell'
import { PresencaColumn } from './PresencaColumn'


const AtendimentoColumns: ReadonlyArray<Column<Atendimento>> = [
  {
    Header: (props) => <AtendimentoSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <AtendimentoSelectionCell id={props.data[props.row.index].id} />,
  },
  // {
  //   Header: (props) => <PacienteCustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
  //   id: 'avatar',
  //   Cell: ({...props}) => <PacienteInfoCell user={props.data[props.row.index]} />,
  // },
  
  {
    Header: (props) => <AtendimentoCustomHeader tableProps={props} title='Protocolo' className='min-w-40px' />,
    id: 'protocolo',
    accessor: 'protocolo'
  },
  {
    Header: (props) => <AtendimentoCustomHeader tableProps={props} title='Data início' className='min-w-125px' />,
    id: 'inicioFormatado',
    accessor: 'inicioFormatado'
  },
  {
    Header: (props) => <AtendimentoCustomHeader tableProps={props} title='Estado' className='min-w-40px' />,
    id: 'estado.descricao',
    accessor: 'estado.descricao'
  },
  {
    Header: (props) => <AtendimentoCustomHeader tableProps={props} title='Médico' className='min-w-40px' />,
    id: 'responsavel.pessoa.nome',
    accessor: 'responsavel.nome'
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
  //   ),
  //   id: 'last_login',
  //   Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Two steps' className='min-w-125px' />
  //   ),
  //   id: 'two_steps',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={props.data[props.row.index].two_steps} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  {
    Header: (props) => <AtendimentoCustomHeader tableProps={props} title='Presença' className='min-w-40px' />,
    id: 'presenca',
    Cell: ({...props}) => <PresencaColumn atendimento={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <AtendimentoCustomHeader tableProps={props} title='Ações' className=' min-w-60px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <AtendimentoActionsCell atendimento={props.data[props.row.index]} />,
  // },
]

export {AtendimentoColumns}
