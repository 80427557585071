import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {FormulariosListWrapper} from './formularios-list/FormulariosList'
import {FormularioCreateWrapper} from './formularios-create/FormularioCreate'

const apiBreadcrumbs: Array<PageLink> = [
  {
    title: 'Formulários',
    path: '/apps/formularios',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Novo Formulário',
    path: '/apps/formularios/novo',
    isSeparator: true,
    isActive: false,
  },
]

const FormulariosPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={apiBreadcrumbs}>Lista</PageTitle>
              <FormulariosListWrapper />
            </>
          }
        />
        <Route
          path='criar'
          element={
            <>
              <PageTitle breadcrumbs={apiBreadcrumbs}>Novo Formulário</PageTitle>
              <FormularioCreateWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default FormulariosPage
