import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { updateEspecialidadeStatus } from '../especialidades-list/core/_requests';  
import { Especialidade } from '../especialidades-list/core/_models';
import { ID } from '../../../../../_metronic/helpers';
import { useQueryResponse } from '../especialidades-list/core/QueryResponseProvider';

interface EspecialidadeStatusModalProps {
  show: boolean;
  onHide: () => void;
  especialidade: Especialidade;
}

const EspecialidadeStatusModal: FC<EspecialidadeStatusModalProps> = ({
  show,
  onHide,
  especialidade,
}) => {
    const { refetch } = useQueryResponse();

  const handleStatusChange = async (ativa: boolean) => {
    if (especialidade) {
      try {
        await updateEspecialidadeStatus(especialidade.id, ativa);  
        Swal.fire({
          text: `Especialidade ${ativa ? 'ativada' : 'desativada'} com sucesso!`,
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });
        refetch()
        onHide();  
      } catch (error) {
        console.error('Erro ao atualizar status da especialidade:', error);
        Swal.fire({
          text: `Erro ao ${ativa ? 'ativar' : 'desativar'} especialidade`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{especialidade?.ativa ? 'Desativar Especialidade' : 'Ativar Especialidade'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você tem certeza de que deseja {especialidade?.ativa ? 'desativar' : 'ativar'} a especialidade "{especialidade?.nome}"?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => handleStatusChange(!especialidade?.ativa)}>
          {especialidade?.ativa ? 'Desativar' : 'Ativar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EspecialidadeStatusModal;
