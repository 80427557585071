import {  useMemo, } from "react";
import { useQueryResponseData, useQueryResponseLoading } from "../core/QueryResponseProvider";
import { ColumnInstance, Row, useTable } from "react-table";
import { KTCardBody } from "../../../../../../_metronic/helpers";
import { AtendimentoIniciado } from "../../../atendimento/core/_models";
import { CustomHeaderColumn } from "../../../paciente-management/pacientes-list/table/columns/CustomHeaderColumn";
import { CustomRow } from "../../../paciente-management/pacientes-list/table/columns/CustomRow";
import { ListLoading } from "../../../commons/components/ListLoading";
import { AtendimentosAsoDownloadColumns } from "./columns/_columnsDownload";
import { DownloadAsoListPagination } from "../component/pagination/DownloadAsoListPagination";

interface DownloadAsoTableProps {
}

const DownloadAsoTable: React.FC<DownloadAsoTableProps> = () => {
  const atendimentosMedicos = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => atendimentosMedicos, [atendimentosMedicos]);
  const downloadCololumns = useMemo(() => AtendimentosAsoDownloadColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns: downloadCololumns,
    data: data as readonly AtendimentoIniciado[],
  });




  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_atendimento_medico'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<AtendimentoIniciado>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<AtendimentoIniciado>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Nenhum registro encontrado
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <DownloadAsoListPagination />
        {isLoading && <ListLoading />}
      </KTCardBody>
    </>
  );
};

export { DownloadAsoTable };
