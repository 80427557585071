import { FC, useState } from "react"
import * as Yup from 'yup'
import { useFormik } from "formik"
import Swal from "sweetalert2"
import clsx from "clsx"
import { ID, KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers"
import { AtendimentoIniciado } from "../atendimento/core/_models"
import { adicionarAnotacao, deleteAnotacao } from "../atendimento/core/_requests"
import HTMLReactParser from "html-react-parser"

type Props = {
    atendimento: AtendimentoIniciado | undefined
    defineAtendimento: Function
}

const Parecer: FC<Props> = ({ atendimento, defineAtendimento }) => {
    const [loading, setLoading] = useState(false)
    const parecerSchema = Yup.object().shape({
        parecer: Yup.string()
            .required('Parecer é obrigatório')
    })
    const parecerAsoForEdit = {
        parecer: ''
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: parecerAsoForEdit,
        validationSchema: parecerSchema,
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true)
            setLoading(true)
            try {
                adicionarAnotacao(atendimento?.id, values.parecer, "PARECER").then((result) => {
                    defineAtendimento()
                    Swal.fire({
                        text: 'Parecer adicionado com sucesso!',
                        icon: 'success',
                        buttonsStyling: false,
                        confirmButtonText: "Ok",
                        customClass: {
                            confirmButton: "btn btn-primary"
                        }
                    })
                        .then(() => {
                            formik.resetForm()
                        })
                }).catch((error) => {
                    Swal.fire({
                        text: 'Você não tem permissão para alterar esse atendimento!',
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: "Ok",
                        customClass: {
                            confirmButton: "btn btn-primary"
                        }
                    })
                })
            } catch (ex: any) {
                const errors = ex.response.data.data.errors
                setFieldError('fatorRisco', errors['fatorRisco'] !== undefined ? errors.fatorRisco[0] : '')
                setFieldError('valor', errors['valor'] !== undefined ? errors.valor[0] : '')
            } finally {
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    const excluiParecer = (id: ID) => {
        deleteAnotacao(atendimento?.id, id).then(() => {
            Swal.fire({
                text: 'Parecer excluído com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            }).then(() => {
                defineAtendimento()
            })
        }).catch((error) => {
            Swal.fire({
                text: 'Não foi possivel excluir! ' + error.response?.data.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            }).then(() => {
                defineAtendimento()
            })
        })
    }
    return (
        <>
            {atendimento?.anotacoes !== undefined ? (
                <>
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-1 text-dark">Anotações</span>
                        </h3>
                    </div>
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table align-middle gs-0 gy-5'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-200px'></th>
                                        <th className='p-0 min-w-100px'></th>
                                        <th className='p-0 min-w-40px'></th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {atendimento.anotacoes !== undefined ? atendimento.anotacoes.map((p) =>
                                        <tr>
                                            <th>
                                                <div className='symbol symbol-50px me-2'>
                                                    <span className='symbol-label'>
                                                        <img
                                                            src={toAbsoluteUrl('/media/general/document3.png')}
                                                            className='h-50 align-self-center'
                                                            alt=''
                                                        />
                                                    </span>
                                                </div>
                                            </th>
                                            <td>
                                                <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                    Profissional: {p.profissional?.nome}
                                                </a>
                                                <span className='text-muted fw-semibold d-block fs-7'>{p.dataFormatada}</span>
                                            </td>
                                            <td>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='text-bold me-2 fs-7 fw-semibold'>{p.tipo}</span>
                                                    </div>
                                                    <div className=' w-100'>
                                                        <span className='text-muted me-2 fs-7 fw-semibold'>{HTMLReactParser(p.anotacao as string)}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='text-end'>
                                                <button
                                                    className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
                                                    onClick={() => excluiParecer(p.id)}
                                                >
                                                    <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
                                                        <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-3' />
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>

                                    ) : <></>}


                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                </>
            ) : (<></>)}
            <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column  me-n7 pe-7'
                    id='kt_modal_add_paciente_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
                    data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}

                    <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Parecer</label>
                    </div>
                    <div className='fv-row mb-7'>
                        <input
                            placeholder='Parecer'
                            {...formik.getFieldProps('parecer')}
                            type='area'
                            name='parecer'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                { 'is-invalid': formik.touched.parecer && formik.errors.parecer },
                                {
                                    'is-valid': formik.touched.parecer && !formik.errors.parecer,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || loading}
                        />
                        {formik.touched.parecer && formik.errors.parecer && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.parecer}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-2 px-8'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>Enviar</span>
                        {(formik.isSubmitting || loading) && (
                            <span className='indicator-progress'>
                                Aguarde...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}

export { Parecer }
