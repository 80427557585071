/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect} from 'react'
import { ID, initialQueryState,  useDebounce } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { getEstados } from '../../../../atendimento/core/_requests'
import Select from 'react-select'
import { getEmpresas } from '../../../../empresa/empresas-list/core/_requests'
import { Form } from 'react-bootstrap'
import { EmpresasQueryResponse } from '../../core/_models'
import { useAuth } from '../../../../../auth'
import { getEspecialidadesSemQuery } from '../../../../especialidades/especialidades-list/core/_requests'
import { Especialidade } from '../../../../especialidades/especialidades-list/core/_models'

function getOptionsEstados() {
  const results: any = []
  getEstados().then((data) => {
    if (data !== undefined) {
      data.data?.forEach((value) => {
        results.push({
          label: value.descricao,
          value: value.id
        });
      });
    }
  })
  return results
}


const AtendimentoMedicoListSearchComponent = () => {
  const [idEmpresaConsulta, setIdEmpresaConsulta] = useState<Number | undefined>(undefined)
  const [especialialidadeOptions, setEspecialidadeOptions] = useState()
  const [selectEspecialidade, setSelectEspecialidade] = useState()
  const [tipoAtendimento, setTipoAtendimento] = useState<string>();
  const { updateState } = useQueryRequest()
  const {currentUser} = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [protocoloSel, setProtocoloSel] = useState<string>('')
  const [dataSel, setDataSel] = useState<string>('')
  const [dataConsulta, setDataConsulta] = useState<string>('')
  const [isChance, setIschange] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(getOptionsEstados());
  const [estadoSel, setEstadoSel] = useState<number>(1);
  const [especialialidade, setEspecialidade] = useState()
  const [optionsEmpresa, setOptionsEmpresa] = useState<any>();
  const [pacienteSel, setPacienteSel] = useState('')


  const [filters, setFilters] = useState<Array<String>>(idEmpresaConsulta ? new Array(String(idEmpresaConsulta)) : [])
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const updateFilter = () => {
    const search = [
      idEmpresaConsulta ? `paciente.empresa:${idEmpresaConsulta}` : '',
      tipoAtendimento ? `tipo:${tipoAtendimento}` : '',
      dataConsulta ? `inicio>${dataConsulta}T00:00:00` : '',
      dataSel ? `solicitacao>'${dataSel}T00:00:00` : '',
      estadoSel ? `estado:${estadoSel}` : '',
      selectEspecialidade ?  `especialidade:${selectEspecialidade}` : '',
      protocoloSel ?  `protocolo:${protocoloSel}` : '',
      pacienteSel ?  'paciente.pessoa.nome~*' + pacienteSel + '*' : '',

    ].filter(Boolean).join(',');
    setSearchTerm(search);
  };

  const handleEspecialidades = async () => {
    try {
      const data = await getEspecialidadesSemQuery()
      const options = data?.data?.map(especialidade => ({
        value: especialidade.id as unknown as string,
        label: especialidade.nome,
      }))
      //@ts-ignore
      setEspecialidadeOptions(options)
    } catch (error) {
      console.error('Erro ao obter os tipos de atendimentos:', error)
      return []
    }
  }

  
  useEffect(() => {
    setIschange(false);
  }, []);
  


  const getOptionsEmpresas = async () => {
    try {
      const results: { label: string; value: ID }[] = [];
      //@ts-ignore
      const data: EmpresasQueryResponse | undefined = await getEmpresas('page=0&size=30');
    
      const empresasTemp: { label: string; value: ID }[] = [];
    
      data?.data?.forEach((value) => {
        if (value.ativa) { 
          empresasTemp.push({ label: value.nomeFantasia as string, value: value.id });
        }
      });
    
      empresasTemp.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
    
      results.push({ label: 'Todas as empresas', value: undefined });
      results.push(...empresasTemp);
    
      setOptionsEmpresa(results);
    } catch (error) {
      console.error("Erro ao obter empresas:", error);
      setOptionsEmpresa([]);
    }
  };
  
  useEffect(() => {
    updateFilter();
  }, [idEmpresaConsulta, tipoAtendimento, dataConsulta, dataSel, estadoSel, selectEspecialidade, pacienteSel, protocoloSel]);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ filter: filters, search: searchTerm, sort: estadoSel !== 3 ? 'solicitacao' : 'termino', order: 'asc', ...initialQueryState })
    }
  }, [searchTerm, debouncedSearchTerm, filters]);

  useEffect(() => {
    if(idEmpresaConsulta){
      setFilters(idEmpresaConsulta ? [String(idEmpresaConsulta)] : []);
    }
  }, [idEmpresaConsulta])

  useEffect(() => {
    handleEspecialidades()
  }, [])


  useEffect(() => {
    if(pacienteSel === ''){
      let search = '';
      if (dataSel) search += 'solicitacao>' + dataSel + "T00:00:00";
      if (estadoSel) search += (search ? ',' : '') + 'estado.id:' + estadoSel;
      if (protocoloSel) search += (search ? ',' : '') + 'protocolo~*' + protocoloSel + '*';
    
      setSearchTerm(search);
    }
  }, [pacienteSel])

  const getTiposAtendimento = () => {
    const tiposSet = new Set();
    currentUser?.vinculos?.forEach((vinculo) => {
      if (vinculo.empresa && vinculo.empresa.tiposAtendimento) {
        vinculo.empresa.tiposAtendimento.forEach(tipo => tiposSet.add(tipo));
      }
    });
    return Array.from(tiposSet).map(tipo => ({ value: tipo, label: tipo }));
  };





  useEffect(() => {
    getOptionsEmpresas()
  },[])

  return (
    <div className='row p-6 col-lg-12 justify-content-center'>
      <div className='p-0 justify-content-center'>
        <div className='row mb-0'>
          <div className='col-lg-4 col-md-6 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Empresa</label>
            <Select
              placeholder='Todas as empresas'
              //@ts-ignore
              onChange={(e) => setIdEmpresaConsulta(e.value)}
              options={optionsEmpresa}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-6 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Estado</label>
            <Select
              placeholder='Selecione um estado'
              onChange={(e) => setEstadoSel(e?.value as unknown as number)}
              options={options}
              className='form-control form-control-solid fs-7'
              defaultValue={{ label: "Aguardando", value: "1" }}
            />
          </div>
          <div className='col-lg-4 col-md-6 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Protocolo</label>
            <input
              type='text'
              data-kt-user-table-filter='nome'
              className='form-control form-control-solid fs-7'
              placeholder='Digite o protocolo'
              value={protocoloSel}
              onChange={(e) => setProtocoloSel(e.target.value)}
            />
          </div>
        </div>
        <div className='row mb-0'>
          <div className='col-lg-4'>
            <div className='mb-3'>
              <label className='form-label fs-6 fw-bolder ps-4'>Paciente</label>
              <Form.Control
              type='text'
              data-kt-user-table-filter='nome'
              className='form-control form-control-solid w-100'
              placeholder='Buscar Pacientes'
              value={pacienteSel}
              onChange={(e) => setPacienteSel(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Tipo atendimento</label>
            <Select
              placeholder='Tipo de atendimento'
              //@ts-ignore
              onChange={(e) => setTipoAtendimento(e.value)}
              options={getTiposAtendimento()}
              className='form-control form-control-solid fs-7'
            />
          </div>
          <div className='col-lg-4 col-md-12 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Especialidade</label>
            <Select
            placeholder='Selecione uma especialidade'
            onChange={(data: any) => {
              setEspecialidade(data)
              setSelectEspecialidade(data.value)
            }}
            //@ts-ignore
            options={especialialidadeOptions}
            className='form-control form-control-solid'
            // defaultValue={{ label: "Selecione um valor", value: "0" }}
            value={especialialidade}

          />
          </div>
        </div>
        <div className='row mb-0'>
        <div className='col-lg-4 col-md-6 mb-2'>
              <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Agendamentos a partir de:</label>
              <input
                type='date'
                className='form-control form-control-solid'
                placeholder='Digite data inicial'
                value={dataSel}
                onChange={(e) => setDataSel(e.target.value)}
                />
            </div>
          <div className='col-lg-4 col-md-6 mb-2'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-3'>Consultas iniciadas a partir de:</label>
            <input
              type='date'
              className='form-control form-control-solid'
              placeholder='Digite data inicial'
              value={dataConsulta}
              onChange={(e) => setDataConsulta(e.target.value)}
              />
          </div>
        </div>
      </div>
      {/* <NotificaSocket idEmpresa='35' /> */}
      {/* end::Search */}
    </div>

  )
}

export { AtendimentoMedicoListSearchComponent }
