import { FC, useEffect, useState } from "react";
import { AtendimentoIniciado, Procedimento } from "../atendimento/core/_models";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { ID, KTSVG, getArquivoAnexo, toAbsoluteUrl } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import clsx from "clsx";
import { criarProcedimentoAnexo, deleteProcedimento, downloadAnexo, editarProcedimentoAnexo, getAtendimentoById } from "../atendimento/core/_requests";
import { Spinner } from "react-bootstrap";
import { arEG } from "date-fns/locale";

type Props = {
    atendimento: AtendimentoIniciado | undefined;
    defineAtendimento: Function;
    onClose?: () => void;
};

const ProcedimentoAso: FC<Props> = ({ atendimento, defineAtendimento, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [procedimento, setProcedimento] = useState<Procedimento | undefined>(undefined);
    const [atendimentoProcedimentos, setAtendimentoProcedimentos] = useState<AtendimentoIniciado>()
    const [procedimentoValue, setProcedimentoValue] = useState<File | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [edit, setEdit] = useState(false)
    const [arquivo, setArquivo] = useState(edit);

    const procedimentoMedicoSchema = Yup.object().shape({
        data: Yup.string().required('Data é obrigatória'),
        nome: Yup.string().required('Nome é obrigatório')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nome: procedimento?.nome || '',
            data: procedimento?.data || '',
            descricao: procedimento?.descricao || null,
            anexo: procedimento?.anexo || undefined,
        },
        validationSchema: procedimentoMedicoSchema,
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true);
            setLoading(true);
            try {
                const procedimentoId = procedimento?.id || null;
                if(procedimentoId){
                   await editarProcedimentoAnexo(
                    atendimento?.id, 
                    procedimentoId, 
                    values.data as string, 
                    values.nome as string,
                     values.descricao as string, 
                     defineAtendimento, 
                     procedimentoValue
                    );

                } else {
                    await criarProcedimentoAnexo(
                        atendimento?.id, 
                        values.data as string, 
                        values.nome as string,
                         values.descricao as string, 
                         defineAtendimento, 
                         procedimentoValue
                        );
                }
                setProcedimentoValue(undefined);
                formik.resetForm();
                defineAtendimento(atendimento?.id);
            } catch (ex: any) {
                const errors = ex.response?.data?.data?.errors || {};
                setFieldError('data', errors['data']?.[0] || '');
                setFieldError('descricao', errors['descricao']?.[0] || '');
                setFieldError('nome', errors['nome']?.[0] || '');
            } finally {
                setSubmitting(false);
                setLoading(false);
                defineAtendimento(atendimento?.id);
            }
        },
    });

    const editProcedimento = (id: ID) => {
        setEdit(true)
        const procedimentoEdit = atendimentoProcedimentos?.procedimentos?.find(p => p.id === id);

        setProcedimento(procedimentoEdit)
        if (procedimentoEdit) {
            setProcedimento(procedimentoEdit);
            formik.setValues({
                nome: procedimentoEdit.nome as string,
                data: procedimentoEdit.data as string,
                descricao: procedimentoEdit.descricao as string,
                anexo: undefined,
            });
            defineAtendimento();
        }
    };

    const excluiProcedimento = (id: ID) => {
        deleteProcedimento(atendimento?.id, id)
            .then(() => {
                Swal.fire({
                    text: 'Procedimento excluído com sucesso!',
                    icon: 'success',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                }).then(() => {
                    defineAtendimento();
                });
            })
            .catch((error) => {
                Swal.fire({
                    text: 'Não foi possível excluir! ' + error.response?.data.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                }).then(() => {
                    defineAtendimento();
                });
            });
    };

    const getAtendimento = () => {
        getAtendimentoById(atendimento?.id)
            .then((data) => {
                setAtendimentoProcedimentos(data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getAtendimento()
    }, [atendimento])

    return (
        <>
            {isLoading ? (
                <Spinner animation="border" role="status" >
                    <span className="visually-hidden">Carregando...</span>
                </Spinner>
            ) : (
                <>
                    {atendimentoProcedimentos?.procedimentos && (
                        <>
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold fs-1 text-dark">Exames Realizados</span>
                                </h3>
                            </div>
                            <div className='card-body py-3'>
                                <div className='table-responsive'>
                                    <table className='table align-middle gs-0 gy-5'>
                                        <thead>
                                            <tr>
                                                <th className='p-0 w-50px'></th>
                                                <th className='p-0 min-w-100px'></th>
                                                <th className='p-0 min-w-100px'></th>
                                                <th className='p-0 min-w-100px'></th>
                                                <th className='p-0 min-w-40px'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {atendimentoProcedimentos.procedimentos.map((p) => (
                                                <tr key={p.id}>
                                                    <th>
                                                        <div className='symbol symbol-50px me-2'>
                                                            <span className='symbol-label'>
                                                                <img
                                                                    src={toAbsoluteUrl('/media/general/document.png')}
                                                                    className='h-50 align-self-center'
                                                                    alt=''
                                                                />
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <td>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                            {p.nome}
                                                        </a>
                                                        <span className='text-muted fw-semibold d-block fs-7'>{p.data}</span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex flex-column w-100 me-2'>
                                                            <div className='d-flex flex-stack mb-2'>
                                                                <span className='text-bold me-2 fs-7 fw-semibold'>Descrição</span>
                                                            </div>
                                                            <div className=' w-100'>
                                                                <span className='text-muted me-2 fs-7 fw-semibold'>{p.descricao || '<Não informado>'}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex flex-column w-100 me-2'>
                                                            <div className='d-flex flex-stack mb-2'>
                                                                <span className='text-bold me-2 fs-7 fw-semibold'>Arquivo</span>
                                                            </div>
                                                            <div className=' w-100'>
                                                                {atendimentoProcedimentos.anexos?.filter(a => a.id === p.anexo).map((j) => (
                                                                    <button
                                                                        key={j.id}
                                                                        className='btn btn-light btn-active-light-primary btn-sm'
                                                                        onClick={() => getArquivoAnexo(atendimentoProcedimentos.id, j.id)}
                                                                    >
                                                                        <span className='indicator-label'>{j.url}</span>
                                                                        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-5 m-0' />
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-end'>
                                                        <button
                                                            className='btn btn-sm btn-light btn-color-muted btn-active-light-warning'
                                                            onClick={() => editProcedimento(p.id)}
                                                        >
                                                            <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
                                                                <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3' />
                                                            </span>
                                                        </button>
                                                        <button
                                                            className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
                                                            onClick={() => excluiProcedimento(p.id)}
                                                        >
                                                            <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
                                                                <KTSVG path='/media/icons/duotune/general/gen040.svg' className='svg-icon-3' />
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                    <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                        <div className='d-flex flex-column  me-n7 pe-7' id='kt_modal_add_paciente_scroll'>
                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Dados do Exame</label>
                            </div>
                            <div className='fv-row mb-7'>
                                <input
                                    placeholder='Nome'
                                    {...formik.getFieldProps('nome')}
                                    type='text'
                                    name='nome'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        { 'is-invalid': formik.touched.nome && formik.errors.nome },
                                        { 'is-valid': formik.touched.nome && !formik.errors.nome }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || loading}
                                />
                                {formik.touched.nome && formik.errors.nome && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.nome}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='fv-row mb-7'>
                                <input
                                    placeholder='Descrição'
                                    {...formik.getFieldProps('descricao')}
                                    type='text'
                                    name='descricao'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        { 'is-invalid': formik.touched.descricao && formik.errors.descricao },
                                        { 'is-valid': formik.touched.descricao && !formik.errors.descricao }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || loading}
                                />
                                {formik.touched.descricao && formik.errors.descricao && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.descricao}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='fv-row mb-7'>
                                <input
                                    type='date'
                                    max={new Date().toISOString().split("T")[0]}
                                    className='form-control form-control-lg textbox-n form-control-solid'
                                    placeholder='Data'
                                    {...formik.getFieldProps('data')}
                                />
                                {formik.touched.data && formik.errors.data && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.data}</div>
                                    </div>
                                )}
                            </div>
                            {(arquivo || !edit) && (
                                <div className='fv-row mb-7'>
                                    <input
                                        name="file"
                                        type="file"
                                        onChange={(event) => {
                                            setProcedimentoValue(event.currentTarget.files?.[0]);
                                        }}
                                        className="form-control"
                                    />
                                    {formik.touched.anexo && formik.errors.anexo && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.anexo}</div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {(edit && !arquivo) && (
                                <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={() => setArquivo(true)}
                                >
                                    Alterar arquivo
                                </button>
                            )}
                        </div>
                        <div className='card-footer d-flex justify-content-end py-2 px-8'>
                            <button
                                type='button'
                                className='btn btn-secondary me-2'
                                onClick={onClose}
                            >
                                Fechar
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={loading || formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Enviar</span>
                                {(formik.isSubmitting || loading) && (
                                    <span className='indicator-progress'>
                                        Aguarde...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </>
            )}
        </>
    );
};

export { ProcedimentoAso };
