import { FC, useEffect, useState } from "react"
import * as Yup from 'yup'
import { useFormik } from "formik"
import Swal from "sweetalert2"
import clsx from "clsx"
import Select from 'react-select';
import { ID, KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers"
import { vinculaCoordenadorPcmso } from "../atendimento/core/_requests"
import { AtendimentoIniciado } from "../atendimento/core/_models"
import { alteraCoordenadorPcmso, createColaboradorPcmso, createEmpresaClienteAso, getColaboradorPcmso, getEmpresaClienteAso } from "../empresa/empresas-list/core/_requests"
import { IMaskInput } from "react-imask"
import { AxiosError } from "axios"
import { ColaboradorPcmso } from "../empresa/empresas-list/core/_models"

type Props = {
    atendimento: AtendimentoIniciado | undefined
    defineAtendimento: Function
}

const Coordenador: FC<Props> = ({ atendimento, defineAtendimento }) => {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<any>([]);
    const [idCoordenador, setIdCoordenador] = useState<ID | undefined>(undefined);
    const [coordenadorEdit, setCoordenadorEdit] = useState<ColaboradorPcmso>()

    const [showCadastroCliente, setshowCadastroCliente] = useState(false);

    const handleShowCadastroCliente = () => {
        setCoordenadorEdit(undefined)
        setIdCoordenador(undefined)
        setshowCadastroCliente(!showCadastroCliente)
    };
    const clienteASoSchema = Yup.object().shape({
        nome: Yup.string()
            .required('Nome é obrigatório'),
        crm: Yup.string()
            .required('Número do Conselho de Classe é obrigatório'),
        rqe: Yup.string()
            .max(50, 'Máximo de 6 caracteres')
            .required('RQE é obrigatório'),
        uf: Yup.string()
            .required('UF é obrigatório')
    })
    const clienteAsoForEdit = {
        nome: '',
        crm: '',
        rqe: '',
        uf: ''
    }
    const defineCoordenador = (id: ID) => {
        setIdCoordenador(id)
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: clienteAsoForEdit,
        validationSchema: clienteASoSchema,
        onSubmit: async (values, { setSubmitting, setFieldError, validateForm }) => {
            setSubmitting(true)
            setLoading(true)
            try {
                if (coordenadorEdit !== undefined) {
                    if (atendimento?.cliente === undefined) {
                        Swal.fire({
                            text: 'Cadastre um Empregador ASO antes de definir um coordenador',
                            icon: 'warning',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    } else {
                        alteraCoordenadorPcmso(atendimento?.cliente?.id, coordenadorEdit.id, values.nome, values.crm, values.rqe, values.uf)
                            .then((coordenador) => {
                                setCoordenadorEdit(undefined)
                                setIdCoordenador(coordenador?.id)
                                // vincularEmpresaCliente()
                                defineAtendimento()
                                setshowCadastroCliente(false)
                                Swal.fire({
                                    text: 'Coordenador alterado com sucesso!',
                                    icon: 'success',
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok",
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    }
                                }).then(() => {
                                    formik.resetForm()
                                    getOptionsCoordenador()
                                })
                            }).catch((error) => {
                                const errors = error.response.data.data.errors
                                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                                setFieldError('crm', errors['crm'] !== undefined ? errors.crm[0] : '')
                                setFieldError('rqe', errors['rqe'] !== undefined ? errors.rqe[0] : '')
                                setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                                const err = error as AxiosError;
                                Swal.fire({
                                    text: 'Erro ao alterar! ',
                                    icon: 'error',
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok",
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    }
                                })
                            }).finally(() => {
                                setSubmitting(false)
                                setLoading(false)
                            })
                    }
                }
                //cadastra nova Empregador ASO
                createColaboradorPcmso(atendimento?.cliente?.id, values.nome, values.crm, values.rqe, values.uf)
                    .then((coordenador) => {
                        setSubmitting(false)
                        setLoading(false)
                        Swal.fire({
                            text: 'Coordenador adicionado com sucesso!',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                            .then(() => {
                                formik.resetForm()
                                setIdCoordenador(coordenador?.data.id)
                                getOptionsCoordenador()
                                handleShowCadastroCliente()
                                defineAtendimento()
                            })
                    }).catch((error) => {
                        const errors = error.response.data.data.errors
                        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                        setFieldError('crm', errors['crm'] !== undefined ? errors.crm[0] : '')
                        setFieldError('rqe', errors['rqe'] !== undefined ? errors.rqe[0] : '')
                        setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                        const err = error as AxiosError;
                        Swal.fire({
                            text: 'Erro ao cadastrar! ' + err.response?.data.data.message,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    })
            } finally {
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    //Carrega coordenadors PCMSO clientes registradas
    function getOptionsCoordenador() {
            getColaboradorPcmso(atendimento?.cliente?.id).then((response) => {
                const results: any = []
                    response?.forEach((value: any) => {
                        results.push({
                            label: value.nome,
                            value: value.id
                        });
                    });
                    setOptions([
                        ...results
                    ])
            }).catch((error) => {
                console.error(error)
            })
    }
    const handleEditCadastroCoordenador = () => {
        if (idCoordenador === undefined) {
            Swal.fire({
                text: 'Selecione um coordenador para edição',
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            })
        } else {
            getColaboradorPcmso(atendimento?.cliente?.id).then((response) => {
                response?.map(coordenador => {
                    if (coordenador.id === idCoordenador) {
                        setCoordenadorEdit(coordenador);
                        formik.setFieldValue('nome', String(coordenador?.nome));
                        formik.setFieldValue('crm', String(coordenador?.crm));
                        formik.setFieldValue('rqe', String(coordenador?.rqe));
                        formik.setFieldValue('uf', String(coordenador?.uf));
                        setshowCadastroCliente(true);
                    }
                });
            });

        }
    }
    const vinculaCoordenador = () => {
        if (idCoordenador !== undefined) {
            //Altera ou adiciona uma Empregador ASO já cadastrada ao atendimento
            vinculaCoordenadorPcmso(atendimento?.id, idCoordenador).then((coordenador) => {
                Swal.fire({
                    text: 'Coordenador adicionado com sucesso ao atendimento!',
                    icon: 'success',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                }).then(() => {
                    formik.resetForm()
                    getOptionsCoordenador()
                    defineCoordenador(coordenador?.id)
                    defineAtendimento()
                })
            }).catch((error) => {
                const err = error as AxiosError;
                Swal.fire({
                    text: 'Erro ao ao víncular! ' + err.response?.data.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            })
        } else {
            Swal.fire({
                text: 'Erro ao ao víncular! Selecione um coordenador. ',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            })
        }
    }
    const handleChangeCliente = (e: any) => {
        //Definir o campo de todos os inputs para filtro

        setIdCoordenador(e.value)

    }
    useEffect(() => {
        if (atendimento?.cliente !== null) {
            getOptionsCoordenador()
        }
    },
        [atendimento] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )
    return (
        <>
            {atendimento?.cliente !== null ? (
                <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                    {/* begin::Scroll */}
                    <div
                        className='d-flex flex-column me-n7 pe-7'
                        id='kt_modal_add_paciente_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
                        data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
                        data-kt-scroll-offset='300px'
                    >
                        {/* begin::Input group */}

                        <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Coordenador</label>
                        </div>
                        {atendimento?.pcmsoCliente !== null ? (
                            <div className='card-body'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table align-middle gs-0'>
                                        {/* begin::Table head */}
                                        <thead>
                                            <tr>
                                                <th className='p-0 w-50px'></th>
                                                <th className='p-0 min-w-200px'></th>
                                                <th className='p-0 min-w-50px'></th>
                                            </tr>
                                        </thead>
                                        {/* end::Table head */}
                                        {/* begin::Table body */}
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <div className='symbol symbol-50px'>
                                                        <span className='symbol-label'>
                                                            <img
                                                                src={toAbsoluteUrl('/media/avatars/blank.png')}
                                                                className='h-50 align-self-center'
                                                                alt=''
                                                            />
                                                        </span>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-bold me-2 fs-7 fw-semibold'>Nome</span>
                                                        </div>
                                                        <div className=' w-100'>
                                                            <span className='text-muted me-2 fs-7 fw-semibold'>{atendimento?.pcmsoCliente?.nome}</span>
                                                        </div>
                                                    </div>
                                                    {/* <span className='text-muted fw-semibold d-block fs-7'>{'RQE: ' + atendimento?.pcmsoCliente?.rqe}</span> */}
                                                </td>
                                                <td>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-bold me-2 fs-7 fw-semibold'>Número do Conselho de Classe </span>
                                                        </div>
                                                        <div className=' w-100'>
                                                            <span className='text-muted me-2 fs-7 fw-semibold'>{atendimento?.pcmsoCliente?.crm}</span>
                                                        </div>
                                                        {/* <span className='text-muted fw-semibold d-block fs-7'>{'UF: ' + atendimento?.pcmsoCliente?.uf}</span> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-bold me-2 fs-7 fw-semibold'>RQE</span>
                                                        </div>
                                                        <div className=' w-100'>
                                                            <span className='text-muted me-2 fs-7 fw-semibold'>{atendimento?.pcmsoCliente?.rqe}</span>
                                                        </div>
                                                        {/* <span className='text-muted fw-semibold d-block fs-7'>{'UF: ' + atendimento?.pcmsoCliente?.uf}</span> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex flex-stack mb-2'>
                                                            <span className='text-bold me-2 fs-7 fw-semibold'>UF</span>
                                                        </div>
                                                        <div className=' w-100'>
                                                            <span className='text-muted me-2 fs-7 fw-semibold'>{atendimento?.pcmsoCliente?.uf}</span>
                                                        </div>
                                                        {/* <span className='text-muted fw-semibold d-block fs-7'>{'UF: ' + atendimento?.pcmsoCliente?.uf}</span> */}
                                                    </div>
                                                </td>
                                            </tr>




                                        </tbody>
                                        {/* end::Table body */}
                                    </table>
                                    {/* end::Table */}
                                </div>
                                {/* end::Table container */}
                            </div>
                        ) : (<></>)}
                        {showCadastroCliente ? (
                            <>
                                <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Nome</label>
                                    <input
                                        placeholder='Nome'
                                        {...formik.getFieldProps('nome')}
                                        type='text'
                                        name='nome'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.nome && formik.errors.nome && coordenadorEdit === undefined },
                                            {
                                                'is-valid': formik.touched.nome && !formik.errors.nome,
                                            }
                                        )}
                                        autoComplete='off'
                                        disabled={formik.isSubmitting || loading}
                                    />
                                    {formik.touched.nome && formik.errors.nome && coordenadorEdit === undefined && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.nome}</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* end::Input */}
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Número do Conselho de Classe</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    <IMaskInput
                                        placeholder='Número do Conselho de Classe'
                                        {...formik.getFieldProps('crm')}
                                        mask="00000000000"
                                        type='text'
                                        name='crm'
                                        // hidden={hideFind}
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.crm && formik.errors.crm && coordenadorEdit === undefined },
                                            {
                                                'is-valid': formik.touched.crm && !formik.errors.crm,
                                            }
                                        )}
                                        disabled={formik.isSubmitting || loading}
                                    />
                                    {formik.touched.crm && formik.errors.crm && coordenadorEdit === undefined && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.crm}</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* end::Input */}
                                </div>
                                <div className='fv-row mb-7'>
                                    <label className='required fw-bold fs-6 mb-2'>Registro de Qualificação de Especialista (RQE)</label>
                                    <IMaskInput
                                        placeholder='RQE'
                                        {...formik.getFieldProps('rqe')}
                                        mask="000000"
                                        type='text'
                                        name='rqe'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.rqe && formik.errors.rqe && coordenadorEdit === undefined },
                                            {
                                                'is-valid': formik.touched.rqe && !formik.errors.rqe,
                                            }
                                        )}
                                        autoComplete='off'
                                        disabled={formik.isSubmitting || loading}
                                    />
                                    {formik.touched.rqe && formik.errors.rqe && coordenadorEdit === undefined && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.rqe}</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* end::Input */}
                                </div>
                                <div className='fv-row mb-7'>
                                    {/* begin::Label */}
                                    <label className='required fw-bold fs-6 mb-2'>Estado</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    <select
                                        className='form-select form-select-solid form-select-lg fw-bold'
                                        {...formik.getFieldProps('uf')}
                                    >
                                        <option value=''>Selecione um estado...</option>
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                    </select>
                                    {formik.touched.uf && formik.errors.uf && coordenadorEdit === undefined && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.uf}</div>
                                        </div>
                                    )}
                                    {/* end::Input */}
                                </div>
                                <div className='card-footer d-flex justify-content-end py-2 px-8'>
                                    <button
                                        type='reset'
                                        //className='btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-2 rounded-bottom-2 me-2 '
                                        className='btn btn-light me-3'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        onClick={handleShowCadastroCliente}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        disabled={loading || formik.isSubmitting || !formik.isValid}
                                    >
                                        <span className='indicator-label'>Cadastrar</span>
                                        {(formik.isSubmitting || loading) && (
                                            <span className='indicator-progress'>
                                                Aguarde...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </>
                        ) : (<>
                            <div className='card-title'></div>

                            <div className='fv-row mb-7'>
                                <Select
                                    placeholder='Selecione um Valor'
                                    onChange={handleChangeCliente}
                                    options={options}
                                    className='form-control form-control-solid'
                                    defaultValue={{ label: "Selecione um valor", value: "0" }}
                                />
                            </div>
                            <div className='card-footer d-flex justify-content-end px-8'>
                                <button
                                    className='btn btn-info active m-1'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    onClick={handleShowCadastroCliente}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                    Cadastrar Coordenador
                                </button>
                                <button
                                    className='btn btn-secondary m-1'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    onClick={handleEditCadastroCoordenador}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                                    Editar Coordenador
                                </button>
                                <button
                                    className='btn btn-primary m-1'
                                    data-kt-users-modal-action='submit'
                                    disabled={loading || formik.isSubmitting}
                                    onClick={vinculaCoordenador}
                                >
                                    <span className='indicator-label'>Vincular</span>
                                    {(formik.isSubmitting || loading) && (
                                    <span className='indicator-progress'>
                                        Aguarde...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    )}
                                </button>
                            </div>
                            {/* end::Input */}
                        </>)}
                    </div>

                </form>
            ) : (
                <div id="msgUser" className='mb-10 symbol-label bg-danger text-inverse-danger fw-bold rounded '>
                    Defina o Empregador ASO previamente! Não é possível definir coordenador.
                </div >
            )}

        </>
    )
}

export { Coordenador }
