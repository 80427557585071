import {Column} from 'react-table'
import {FormularioActionsCell} from './FormulariosActionsCell'
import {FormulariosCustomHeader} from './FormulariosCustomHeader'
import {Formulario} from '../../../formularios-create/core/_models'

const especialidadesColumns: ReadonlyArray<Column<Formulario>> = [
  {
    Header: (props) => <FormulariosCustomHeader tableProps={props} title='Nome' />,
    id: 'nome',
    accessor: 'nome',
  },
  {
    Header: (props) => <FormulariosCustomHeader tableProps={props} title='Descrição' />,
    id: 'descricao',
    accessor: 'descricao',
  },
  {
    Header: (props) => <FormulariosCustomHeader tableProps={props} title='Status' />,
    accessor: 'ativo',
    Cell: ({value}) => (
      <span
        className={`badge rounded-pill p-3 ${
          value ? 'bg-success text-white' : 'bg-secondary'
        } badge-lg fs-5`}
      >
        {value ? 'Ativo' : 'Inativo'}
      </span>
    ),
  },
  {
    Header: (props) => <FormulariosCustomHeader tableProps={props} title='Ações' />,
    id: 'actions',
    Cell: ({...props}) => <FormularioActionsCell row={props.row} />,
  },
]

export {especialidadesColumns}
