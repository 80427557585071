// @ts-nocheck
import { Column } from 'react-table'
import { RelatorioCustomHeader } from './RelatorioCustomHeader'

const relatorioColumns: ReadonlyArray<Column<Empresa>> = [
  {
    Header: (props) => <RelatorioCustomHeader tableProps={props} title='Protocolo' className='min-w-125px' />,
    id: 'protocolo',
    accessor: 'protocolo'
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Paciente' className='min-w-125px' />
    ),
    id: 'paciente',
    accessor: 'paciente.nome',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Tipo' className='min-w-125px' />
    ),
    id: 'tipo',
    accessor: 'tipoAtendimento',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Especialidade' className='min-w-125px' />
    ),
    id: 'especialidade',
    accessor: 'especialidade',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    accessor: 'estadoAtendimento',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Profissional de Saúde' className='min-w-125px' />
    ),
    id: 'medico',
    accessor: 'responsavel.nome',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Empresa' className='min-w-125px' />
    ),
    id: 'empresa',
    accessor: 'empresa.nomeFantasia',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='CNPJ' className='min-w-125px' />
    ),
    id: 'cnpj',
    accessor: 'empresa.cnpj',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Solicitante' className='min-w-125px' />
    ),
    id: 'criador',
    accessor: 'criador.nome',
  },
  {
    Header: (props) => (
      <RelatorioCustomHeader tableProps={props} title='Data' className='min-w-125px' />
    ),
    id: 'data',
    accessor: 'solicitacaoFormatada',
  },

]

export { relatorioColumns }
