import {EmpresasListToolbar} from './EmpresasListToolbar'
import {EmpresasListGrouping} from './EmpresasListGrouping'
import {EmpresasListSearchComponent} from './EmpresasListSearchComponent'
import { useListView } from '../../core/ListViewProvider'

const EmpresasListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <EmpresasListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <EmpresasListGrouping /> : <EmpresasListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {EmpresasListHeader}
