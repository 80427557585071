import {Column} from 'react-table'
import {EspecialidadeCustomHeader} from './EspecialidadeCustomHeader'
import { Especialidade } from '../../core/_models'
import { EspecialidadeActionsCell } from './EspecialidadeActionsCell';

const especialidadesColumns: ReadonlyArray<Column<Especialidade>> = [
  {
    Header: (props) => <EspecialidadeCustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  },
  {
    Header: (props) => <EspecialidadeCustomHeader tableProps={props} title='Tipo Atendimento' className='min-w-125px' />,
    id: 'tipo',
    accessor: 'tiposAtendimento',
    Cell: ({ value  }) => {
      if (Array.isArray(value)) {
        return <span>{value.join(', ')}</span>;
      }
      return <span></span>;
    },
  },
  {
    Header: (props) => (
      <EspecialidadeCustomHeader tableProps={props} title='Ativa' className='min-w-125px' />
    ),
    accessor: 'ativa',
    Cell: ({ value }) => (
      <span className={`badge rounded-pill p-2 ${value ? 'bg-success text-white' : 'bg-secondary'} badge-lg fs-5`}>
        {value ? 'Ativa' : 'Inativa'}
      </span>
    ),
  },
 {
  Header: (props) => (
   <EspecialidadeCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
   ),
  id: 'actions',
  Cell: ({...props}) => <EspecialidadeActionsCell id={props.data[props.row.index].id} row={props.row} />,
 },
]

export {especialidadesColumns}
