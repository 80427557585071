/* eslint-disable react/jsx-no-target-blank */

import { useEffect, useState } from 'react'

import { AsideMenuItem } from './AsideMenuItem'
import { getLoggedUser } from '../../../../app/modules/auth/core/_requests'
import { getPacienteLogado } from '../../../../app/modules/apps/paciente-management/pacientes-list/core/_requests'
import { UserModel, useAuth } from '../../../../app/modules/auth'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { useMediaQuery } from 'react-responsive';

export function AsideMenuMain() {
  const [usuario, setUsuario] = useState<UserModel>();
  const [isMedico, setIsMedico] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isColaborador, setIsColaborador] = useState(false);
  const [isResponsavel, setIsResponsavel] = useState(false);
  const [isPacienteLogado, setIsPacienteLogado] = useState(false)
  const [isMedicoAdmin, setIsMedicoAdmin] = useState(false)
  const [isGerente, setIsGerente] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)
  const [hasASO, setHasAso] = useState(false)

  const mostrarPaginaEmpresa = isSuperAdmin || isGerente
  const mostrarPaginaApi = isSuperAdmin || isResponsavel
  const mostrarPaginaEsp = isSuperAdmin || isMedicoAdmin 
  const mostrarPaginaEmpresaCliente = (isSuperAdmin || isResponsavel || isMedico || isColaborador || isMedicoAdmin || isEmployee) && hasASO
  const mostrarPaginaFuncionarios = isSuperAdmin || isResponsavel || isMedicoAdmin || isGerente
  const mostrarPaginaPacientes = isSuperAdmin || isResponsavel || isColaborador || isMedico || isMedicoAdmin
  const mostrarPaginaAtendimentos = isSuperAdmin || isMedico || isColaborador || isResponsavel || isMedicoAdmin || isEmployee
  const mostrarPaginaAso = (isSuperAdmin || isMedico || isMedicoAdmin) && hasASO
  const mostrarPaginaDownloadAso = (isSuperAdmin || isResponsavel || isColaborador || isMedicoAdmin || isEmployee) && hasASO
  const mostrarPaginaRelatorios = isSuperAdmin || isMedicoAdmin || isGerente || isResponsavel

  const { logout } = useAuth();




  const isMobile = useMediaQuery({ maxWidth: 767 });

  function loadRoles() {
    getLoggedUser()
      .then((responseData) => {
        setUsuario(responseData.data);
      }).catch((error) => {
        Swal.fire({
          text: 'Erro ao verificar usuário logado!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        })
      });
  }

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (usuario) {
      setIsMedico(usuario?.vinculos?.some(vinculo => vinculo.cargo === 'MEDICO') || false);
      setIsSuperAdmin(usuario?.grupo?.nome === 'SUPER_ADMIN' || false);
      setIsEmployee(usuario?.grupo?.nome === 'EMPĹOYEE' || false);
      setIsColaborador(usuario?.vinculos?.some(vinculo => vinculo.cargo === 'COLABORADOR') || false);
      setIsResponsavel(usuario?.vinculos?.some(vinculo => vinculo.cargo === 'RESPONSAVEL') || false);
      setIsGerente(usuario.grupo?.nome === 'SUPER_GERENTE' || false)
      setIsPacienteLogado(usuario?.grupo?.nome === 'USER')
      setIsMedicoAdmin(usuario?.grupo?.nome === 'SUPER_MEDICO')
      setHasAso(usuario?.vinculos?.some(vinculo =>
        vinculo?.empresa?.tiposAtendimento?.includes('ASO')
      ) ?? false)

    }
  }, [usuario])

  return (
    <div className="d-flex flex-column">
      {!!usuario ? (
        isPacienteLogado ? (
          <div>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Atendimentos</span>
              </div>
            </div>
            <AsideMenuItem
              to='/apps/atendimentos/paciente'
              icon='/media/icons/duotune/communication/com005.svg'
              title='Meus atendimentos'
            />
          </div>
        ) : (

          <>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-0'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Prestadores</span>
              </div>
            </div>


            {mostrarPaginaEmpresa && (
              <AsideMenuItem
                to='/apps/empresas/lista'
                icon='/media/icons/duotune/ecommerce/ecm007.svg'
                title='Empresas'
              />
            )}

            {mostrarPaginaEmpresaCliente && (
              <AsideMenuItem
                to='/apps/empresasCliente/lista'
                icon='/media/icons/duotune/communication/com005.svg'
                title='Empregador ASO'
              />
            )}

            {mostrarPaginaFuncionarios && (
              <AsideMenuItem
                to='/apps/funcionarios-management/funcionarios'
                icon='/media/icons/duotune/communication/com014.svg'
                title='Funcionários'
              />
            )}

            {mostrarPaginaApi && (
              <AsideMenuItem
                to='/apps/api'
                icon='/media/icons/duotune/coding/cod009.svg'
                title='API'
              />
            )}

            {mostrarPaginaEsp && (
              <AsideMenuItem
                to='/apps/especialidades'
                icon='/media/icons/duotune/general/gen003.svg'
                title='Especialidades'
              />
            )}

            {mostrarPaginaEsp && (
              <AsideMenuItem
                to='/apps/formularios'
                icon='/media/icons/duotune/coding/cod007.svg'
                title='Formularios'
              />
            )}

            <div className='menu-item'>
              <div className='menu-content pt-8 pb-0'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
              </div>
            </div>


            {mostrarPaginaAtendimentos && (
              <AsideMenuItem
                to='/apps/atendimentosMedicos/lista'
                icon='/media/icons/duotune/communication/com005.svg'
                title='Atendimentos'
              />
            )}

            {mostrarPaginaAso && (
              <AsideMenuItem
                to='/apps/atendimentosAso/lista'
                icon='/media/icons/duotune/communication/com005.svg'
                title='ASO'
              />
            )}

            {mostrarPaginaDownloadAso && (
              <AsideMenuItem
                to='/apps/downloadAso/download'
                icon='/media/icons/duotune/arrows/arr044.svg'
                title='Baixar ASOs'
              />
            )}

            {mostrarPaginaRelatorios && (
              <AsideMenuItem
                to='/apps/relatorios/atendimentos'
                icon='/media/icons/duotune/files/fil001.svg'
                title='Relatórios'
              />
            )}

            {mostrarPaginaPacientes && (
              <AsideMenuItem
                to='/apps/pacientes-management/pacientes'
                icon='/media/icons/duotune/communication/com006.svg'
                title='Pacientes'
              />
            )}
          </>)
      ) : (
        <></>
      )}

      {isMobile && (
        <div className='m-2 px-2'>
          <button
            className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'
            onClick={logout}
          >
            <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
              <i className='bi bi-box-arrow-right m-8 mx-5'></i>
              <span >Sair</span>
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

