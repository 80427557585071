import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select, { MultiValue, ActionMeta } from 'react-select';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../auth';
import { useQueryResponse } from '../especialidades-list/core/QueryResponseProvider';
import Swal from 'sweetalert2';
import { createEspecialidade, editEspecialidade, getTiposAtendimentos } from '../especialidades-list/core/_requests';
import { Especialidade } from '../especialidades-list/core/_models';
import { ID } from '../../../../../_metronic/helpers';

interface SelectOption {
  value: string;
  label: string;
}

export interface EspecialidadeValues {
  nome: string;
  tipoAtendimento: SelectOption[];
}

type tiposAtendimentoOptions = {
  value: string,
  label: string
}

interface EspecialidadeEditModalFormProps {
  show: boolean;
  onHide: () => void;
  valuesEdit?: Especialidade;
}

const EspecialidadeEditModalForm: React.FC<EspecialidadeEditModalFormProps> = ({
  show,
  onHide,
  valuesEdit,
}) => {
  const { currentUser } = useAuth();
  const { refetch } = useQueryResponse();
  const [tipoAtendimentoOptions, setTipoAtendimentoOptions] = useState<SelectOption[]>([]);
  const [options, setOptions] = useState<tiposAtendimentoOptions[]>([]) // Estado para armazenar as opções


  const handleTiposAtendimentos = async (): Promise<tiposAtendimentoOptions[]> => {
    try {
      const tiposAtendimentos = await getTiposAtendimentos()
  
      const tiposAtendimentoOptions: tiposAtendimentoOptions[] = tiposAtendimentos.map((tipo) => ({
        value: tipo.name,
        label: tipo.descricao,
      }))
  
      return tiposAtendimentoOptions
    } catch (error) {
      console.error('Erro ao obter os tipos de atendimentos:', error)
      return []
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      const fetchedOptions = await handleTiposAtendimentos()
      setOptions(fetchedOptions)
    }
    
    fetchOptions()
  }, [])

  const mapValuesToOptions = (values: string[], options: SelectOption[]): SelectOption[] => {
    return options.filter(option => values.includes(option.value));
  };

  const formatErrors = (errors: Record<string, string[]>) => {
    let errorMessage = 'Por favor, corrija os seguintes erros:\n\n';
    for (const [field, messages] of Object.entries(errors)) {
      errorMessage += `${field}:\n`;
      messages.forEach((message) => {
        errorMessage += ` ${message}\n`;
      });
      errorMessage += '\n';
    }
    return errorMessage;
  }

  const onSubmit = async (values: EspecialidadeValues, setFieldError: FormikHelpers<{ nome: string; tipoAtendimento: SelectOption[]; }>) => {
    const requestData = {
      nome: values.nome,
      tiposAtendimento: values.tipoAtendimento.map(option => option.value),
    };

    try {
      if (valuesEdit) {
        await editEspecialidade(valuesEdit.id, requestData.nome, requestData.tiposAtendimento);
        Swal.fire({
          text: 'Especialidade editada com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });
      } else {
        await createEspecialidade(requestData);
        Swal.fire({
          text: 'Especialidade cadastrada com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });
      }
      refetch();
      onHide();
    } catch (error) {
      //@ts-ignore
      const errors = error.response.data.data.errors
      const formattedErrors = formatErrors(errors);
      Swal.fire({
        text: formattedErrors,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      });
    }
  };


  const formik = useFormik({
    initialValues: {
      nome: valuesEdit?.nome || '',
      tipoAtendimento: mapValuesToOptions(valuesEdit?.tiposAtendimento || [], tipoAtendimentoOptions),
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('O nome é obrigatório'),
      tipoAtendimento: Yup.array().of(
        Yup.object({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
      ).min(1, 'Selecione ao menos um tipo de atendimento'),
    }),
    onSubmit: (data, setFieldError) => {
      onSubmit(data as EspecialidadeValues, setFieldError);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (show) {
      setTipoAtendimentoOptions(options);

      if (valuesEdit) {
        formik.setValues({
          nome: valuesEdit.nome,
          tipoAtendimento: mapValuesToOptions(valuesEdit.tiposAtendimento || [], options),
        });
      }
    } else {
      formik.resetForm();
    }
  }, [show, valuesEdit]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{valuesEdit ? 'Editar Especialidade' : 'Nova Especialidade'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="formNome">
            <Form.Label>Nome da Especialidade</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome da especialidade"
              {...formik.getFieldProps('nome')}
              isInvalid={!!formik.errors.nome && formik.touched.nome}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.nome}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formTipoAtendimento" className='mt-4'>
            <Form.Label>Tipo de Atendimento</Form.Label>
            <Select
              isMulti
              placeholder='Selecione um ou mais tipos de atendimento'
              onChange={(newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
                formik.setFieldValue('tipoAtendimento', newValue);
              }}
              options={tipoAtendimentoOptions}
              value={formik.values.tipoAtendimento}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
            {formik.errors.tipoAtendimento && formik.touched.tipoAtendimento && (
              <div className="invalid-feedback d-block">
                {formik.errors.tipoAtendimento as string}
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EspecialidadeEditModalForm;
