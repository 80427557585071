// @ts-nocheck
import { Column } from 'react-table'
import { CoordenadorCliente } from '../../../empresas-list/core/_models'
import { CoordenadorCustomHeader } from './CoordenadorCustomHeader'
import { CoordenadorActionsCell } from './CoordenadorActionsCell'


const CoordenadorColumns: ReadonlyArray<Column<CoordenadorCliente>> = [
  // {
  //   Header: (props) => <CoordenadorSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => <CoordenadorSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <CoordenadorCustomHeader tableProps={props} title='Coordenador' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  },
  {
    Header: (props) => <CoordenadorCustomHeader tableProps={props} title='Número do Conselho de Classe ' className='min-w-125px' />,
    id: 'crm',
    accessor: 'crm'
  },
  {
    Header: (props) => <CoordenadorCustomHeader tableProps={props} title='RQE' className='min-w-125px' />,
    id: 'rqe',
    accessor: 'rqe'
  },
  {
    Header: (props) => (
      <CoordenadorCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <CoordenadorActionsCell colaborador={props.row.original} />,
  },
]

export { CoordenadorColumns }
