/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteEmpresa } from '../../core/_requests'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import { CancelarAtendimentoModal } from '../../component/CancelarAtendimentoModal/CancelaratendimentoModal'
import { Atendimento, AtendimentoIniciado } from '../../../../atendimento/core/_models'
import { Button, Modal } from 'react-bootstrap'
import { useAuth } from '../../../../../auth'
import { MarcarFaltaModal } from '../../component/MarcarFaltaModal/MarcarFaltaModal'
import { ReabrirAtendimentoModal } from '../../component/ReabrirAtendimentoModal/ReabrirAtendimentoModal'
import { ProcedimentoAso } from '../../../../atendimento-ASO/Procedimento'
import { downloadProntuario, getAtendimentoById } from '../../../../atendimento/core/_requests'
import { MarcarPresencaModal } from '../../component/MarcarPresencaModal/MarcarPresencaModal'
type Props = {
  atendimento: AtendimentoIniciado
}

const AtendimentoMedicoActionsCell: FC<Props> = ({ atendimento }) => {

  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const { currentUser } = useAuth()
  const [IdEmpresa, setIdEmpresa] = useState<string>("");
  const [showFaltaAtendimento, setShowFaltaAtendimento] = useState(false)
  const [showCancelarAtendimento, setShowCancelarAtendimento] = useState(false)
  const [showReabrirAtendimento, setShowReabrirAtendimento] = useState(false)
  const [showMarcarPresenca, setShowMarcarPresenca] = useState(false)
  const isCancelado = atendimento.estado?.nome === 'Cancelado'
  const isAso = atendimento.tipo === 'ASO'
  const isADM = currentUser?.grupo?.nome === 'SUPER_MEDICO' || currentUser?.grupo?.nome === 'SUPER_ADMIN'
  const isMedico = !(currentUser?.grupo?.nome === 'SUPER_MEDICO') && currentUser?.vinculos?.some((v) => v.cargo === 'MEDICO');

  const [atendimentoExame, setAtendimentoExame] = useState<AtendimentoIniciado | undefined>(atendimento)
  const [showProcedimento, setShowProcedimento] = useState(false);
  const handleCloseProcedimento = () => setShowProcedimento(false);
  const handleShowProcedimento = () => setShowProcedimento(true);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const handleCancelarAtendimento = () => {
    setShowCancelarAtendimento(prev => !prev)
  }

  const handleFaltouAtendimento = () => {
    setShowFaltaAtendimento(prev => !prev)
  }


  const handleDownloadProntuario = () => {
    downloadProntuario(atendimento.id).then((anexo) => {
      if (anexo !== undefined) {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application/pdf;base64," + (anexo !== undefined ? anexo.contentBase64 : ''); //Image Base64 Goes here
        a.download = String(anexo.name); //File name Here
        a.click();
      }
    }).catch((error) => {
      const errors = error.response.data.data.message

      Swal.fire({
        text: `Não foi possível realizar o download: ${errors}.`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
    })
  }

  const handleReabrirAtendimento = () => {
    setShowReabrirAtendimento(prev => !prev)
  }

  const handleMarcarPresenca = () => {
    setShowMarcarPresenca(prev => !prev)
  }


  const openEditModal = () => {
    setItemIdForUpdate(atendimento.id)
  }
  const defineAtendimento = () => {
    getAtendimentoById(atendimento?.id).then((a) => {
      setAtendimentoExame(a)
      // verificaEstadoAso(a?.id)
    })
  }
  const inciarAtendimentoVideo = () => {
    window.location.href = "/apps/pacientes-management/pacientes?empresa=" + atendimento.id
  }
  const deleteItem = useMutation(() => deleteEmpresa(atendimento.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError;
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
    },
  })

  return (
    <>
      <Button variant="btn btn-primary btn-sm fs-7" onClick={openEditModal}>
        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
        Visualizar
      </Button>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}

        <>
        {atendimento?.presencaPacienteFormatada === null && (
        <div className='menu-item px-3'>
        <a
          className='menu-link px-3'
          onClick={() => handleMarcarPresenca()}>
          Marcar presença
        </a>
      </div>
       )}


          {(!isCancelado) && (
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                onClick={() => handleCancelarAtendimento()}>
                Cancelar atendimento
              </a>
            </div>
          )}

          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => handleFaltouAtendimento()}>
              Marcar falta paciente
            </a>
          </div>



          {atendimento?.estado?.nome === 'Finalizado' &&(
            <>
              <div className='menu-item px-3'>
                <a
                  className='menu-link px-3'
                  onClick={() => handleDownloadProntuario()}>
                  Baixar atendimento
                </a>
              </div>
            </>
          )}

          {(atendimento?.estado?.nome !== 'Iniciado' && atendimento?.estado?.nome !== 'Aguardando') && (
            <div className='menu-item px-3'>
              <a
                className='menu-link px-3'
                onClick={() => handleReabrirAtendimento()}>
                Reabrir atendimento
              </a>
            </div>
          )}


        </>

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            onClick={() => handleShowProcedimento()}>
            Inserir Exames
          </a>
        </div>

      </div>


      {/* end::Menu */}
      {showCancelarAtendimento && (
        <CancelarAtendimentoModal atendimento={atendimento} showCancelarAtendimento={showCancelarAtendimento} onClose={handleCancelarAtendimento} />
      )}

      {showFaltaAtendimento && (
        <MarcarFaltaModal atendimento={atendimento} showMarcarFalta={showFaltaAtendimento} onClose={handleFaltouAtendimento} />
      )}

      {showMarcarPresenca && (
        <MarcarPresencaModal atendimento={atendimento} showMarcarPresenca={showMarcarPresenca} onClose={handleMarcarPresenca} isMedico={isMedico} />
      )}

      {showReabrirAtendimento && (
        <ReabrirAtendimentoModal atendimento={atendimento} showReabrirAtendimento={showReabrirAtendimento} onClose={handleReabrirAtendimento} />
      )}
      <Modal className="modal-xl" show={showProcedimento} onHide={handleCloseProcedimento}>
        <Modal.Header closeButton>
          <Modal.Title>Exames</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProcedimentoAso atendimento={atendimentoExame} defineAtendimento={defineAtendimento} onClose={handleCloseProcedimento}  />
        </Modal.Body>
      </Modal>

    </>
  )
}

export { AtendimentoMedicoActionsCell }
