import {FC, useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {getFormularioById} from '../../../formularios-create/core/_requests'
import {AxiosError} from 'axios'
import Swal from 'sweetalert2'
import {deleteFormularioById, toggleStatus} from '../../core/_requests'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useMutation, useQueryClient} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'

type Props = {
  row: any
}

const FormularioActionsCell: FC<Props> = ({row}) => {
  const navigate = useNavigate()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => MenuComponent.reinitialization(), [])

  const editForm = () => {
    const {id} = row.original
    getFormularioById(id)
      .then((result) => {
        navigate('/apps/formularios/criar' + '?id=' + id)
      })
      .catch((error) => {
        const err = error as AxiosError
        Swal.fire({
          text: 'Erro ao buscar empresa! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
  }

  const toggleActive = useMutation(
    () => toggleStatus({id: row.original.id, ativo: !row.original.ativo}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.FORMULARIOS_LIST}-${query}`])
        Swal.fire({
          text: `Formulário ${row.original.ativo ? 'inativado' : 'ativado'}`,
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }) /* .then(() => window.location.reload()) */
      },
      onError(error, variables, context) {
        const err = error as AxiosError
        Swal.fire({
          text: err.response?.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      },
    }
  )

  const deleteForm = useMutation(() => deleteFormularioById(row.original.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
      Swal.fire({
        text: `Formulário deletado`,
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => window.location.reload())
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={editForm}>
            Editar
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter={row.original.ativa ? 'delete_row' : 'active_row'}
            onClick={async () => await toggleActive.mutateAsync()}
          >
            {row.original.ativo ? 'Inativar' : 'Ativar'}
          </a>
        </div>
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => alert('comando para vincular')}>
            Vincular
          </a>
        </div>
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={async () => await deleteForm.mutateAsync()}>
            Excluir
          </a>
        </div>
      </div>
    </>
  )
}

export {FormularioActionsCell}

/* <div className='d-flex justify-content-end'>
      <Dropdown>
        <Dropdown.Toggle variant='primary' className='btn-sm'>
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={editForm}>Editar</Dropdown.Item>
          <Dropdown.Item onClick={toggleStatus}>{row.original.ativa ? 'Inativar' : 'Ativar'}</Dropdown.Item>
          <Dropdown.Item onClick={deleteForm}>Excluir</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div> */
