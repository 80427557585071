/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { AtendimentoIniciado } from '../../../core/_models'

type Props = {
  atendimento: AtendimentoIniciado
}

const PresencaColumn: FC<Props> = ({atendimento}) => {
  const cor = atendimento?.presencaPacienteFormatada !== null  ? 'bg-success' : 'bg-secondary';
  
  return (
    <div className="d-inline-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px', borderRadius: '50%' }}>
      <div className={`rounded-circle ${cor}`} style={{ width: '30%', height: '30%' }}></div>
    </div>
  );
}

export {PresencaColumn}
