import React, { FC, useContext, useState, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  createResponseContext,
  getParameterByName,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers';
import { useQueryRequest } from './QueryRequestProvider';
import { AtendimentoIniciado } from '../../../atendimento/core/_models';
import { getAtendimentosEmpresaAguardando, getAtendimentosMedico } from '../../../atendimento/core/_requests';
import { useAuth } from '../../../../auth';
import { Socket, io } from 'socket.io-client';
import { Stomp } from '@stomp/stompjs';

const QueryResponseContext = createResponseContext<AtendimentoIniciado>(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [idEmpresaMedico, setIdEmpresaMedico] = useState('');
  const [refresh, setRefresh] = useState('');
  const auth = useAuth();
  const { state } = useQueryRequest();
  const queryClient = useQueryClient()
  const { idEmpresa } = useQueryResponse();
  const { idPaciente } = useQueryResponse();
  const [query, setQuery] = useState<string>(decodeURIComponent(stringifyRequestQuery(state)));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const API_URL = (window.location.hostname.includes("homolog") || window.location.hostname.includes("localhost")) ? 'wss://api-homologacao.lauduz.com/socket/atendimento' 
  : 'wss://api.lauduz.com/socket/atendimento';

  const idEmpresaParamRaw = getParameterByName('filter_0', query);
  const idEmpresaParam = idEmpresaParamRaw ? idEmpresaParamRaw.match(/\d+/)?.[0] : '';
  console.log(idEmpresaParamRaw);

  useEffect(() => {
    if (query !== updatedQuery) {
      getAtendimentosMedico(query).catch((error) => {
        console.log(error);
      });
      setQuery(decodeURIComponent(updatedQuery));
    }
  }, [query, updatedQuery]);


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`,
    () => (idEmpresaParam ? getAtendimentosEmpresaAguardando(String(idEmpresaParam), query) : getAtendimentosMedico(query)),
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  
  useEffect(() => {
    const stompClient = Stomp.client(API_URL);    
    var token = auth.auth?.accessToken;    
    stompClient.connect({Authorization:'Bearer ' + token}, function (frame: string) {
      console.log('Connected: ' + frame);
      stompClient.subscribe('/topic/atendimento', function (mensagem: any) {  
        queryClient.invalidateQueries([`${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`])    
      });
    });
  }, [auth.auth?.accessToken, query, queryClient]);

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query, idEmpresa, idPaciente }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }
  return response?.data || [];
};

const useQueryResponsePagination = (): PaginationState => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response) {
    return defaultPaginationState;
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  };

  return responsePaginationState;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
