import { useEffect, useState } from "react";
import { acceptTerms, getAtendimentoByProtocolo, login } from "../core/_requests";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik"; 
import Swal from "sweetalert2";

export function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const { protocolo } = useParams();
  const navigate = useNavigate();
  const pacienteToken = localStorage.getItem('pacienteToken'); 
  const protocoloStorage = localStorage.getItem('protocolo'); 

  const saveTokenToLocalStorage = (token: string) => {
    localStorage.setItem('pacienteToken', token);
    localStorage.setItem('protocolo', protocolo as string);
  };

  const defineAtendimento = async () => {
    try {
      await getAtendimentoByProtocolo(protocolo as string);
      navigate(`/tele-consulta/${protocolo}/atendimento`);
    } catch (error) {
      Swal.fire({
        text: "Acesso não autorizado",
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      });
    }
  };
  

  const handleAcceptTerms = async () => {
    try {
      await acceptTerms(protocolo as string);
     
    } catch (error) {
      Swal.fire({
        text: "Acesso não autorizado",
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      });
    }
  }

  useEffect(() => {
    if (pacienteToken && protocolo === protocoloStorage) {
      navigate(`/tele-consulta/${protocolo}/atendimento`); 
    } else {
      localStorage.removeItem('pacienteToken');
      localStorage.removeItem('protocolo');
    }
  }, [pacienteToken, protocolo, protocoloStorage, navigate])

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8"> 
          <div className="card shadow mt-15 p-5">
            <div className="card-body">
              <Formik
                initialValues={{ cpf: '', aceitoTermos: false }} 
                validate={values => {
                  const errors: any = {};
                  if (!values.cpf) {
                    errors.cpf = 'CPF obrigatório';
                  }
                  if (!values.aceitoTermos) {
                    errors.aceitoTermos = 'Você deve aceitar os termos';
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setIsLoading(true);
                  try {
                    const data = await login(values.cpf, protocolo as string);
                    if (data && data.accessToken) { 
                      saveTokenToLocalStorage(data.accessToken); 
                    } else {
                      console.error("Erro: Token inválido.");
                    }
                    await defineAtendimento()
                    await handleAcceptTerms()
                  } catch (error) {
                    Swal.fire({
                      text: "Acesso não autorizado",
                      icon: 'error',
                      buttonsStyling: false,
                      confirmButtonText: "Ok",
                      customClass: {
                        confirmButton: "btn btn-primary"
                      }
                    })}
                  setIsLoading(false);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-4 col-md-8 mx-auto"> 
                      <label htmlFor="cpf" className="form-label">CPF</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="cpf"
                        name="cpf"
                        placeholder="Digite seu CPF"
                      />
                      <ErrorMessage name="cpf" component="div" className="text-danger" />
                    </div>
                    <div className="mt-10 mb-10 d-flex align-items-center">
                      <Field
                        type="checkbox"
                        className="form-check-input mx-3"
                        id="aceitoTermos"
                        name="aceitoTermos"
                      />
                      <label className="text-primary fs-5" htmlFor="aceitoTermos">
                        Aceito a <strong>Política de Privacidade</strong> e o <strong>Termo de Consentimento Livre e Esclarecido</strong>
                      </label>
                      
                    </div>
                    
                    <div className="text-center">
                    <ErrorMessage name="aceitoTermos" component="div" className="text-danger" />
                      <button type="submit" className={`btn btn-primary ${isLoading || isSubmitting ? 'disabled' : ''} mt-2`} disabled={isLoading || isSubmitting}>
                        {isLoading ? 'Carregando...' : 'Iniciar Atendimento'}
                      </button>

                    </div>
                  </Form>
                )}
              </Formik>
              {/* Fim do Formik */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
