import axios, { AxiosResponse } from 'axios'
import { ID, Response, isNotEmpty } from '../../../../../../_metronic/helpers'
import { Endereco, Paciente, PacientesQueryResponse, Pessoa } from './_models'
import { Picture } from '../../../../auth'

const API_URL = process.env.REACT_APP_API_URL
const PACIENTE_URL = `${API_URL}/pacientes`
const ATENDIMENTOS_URL = `${API_URL}/atendimentos`
const PACIENTES_EMPRESAS_URL = `${API_URL}/pacientes/empresa/`
const GET_PACIENTES_URL = `${API_URL}/pacientes`

const getPacientes = (query: string): Promise<PacientesQueryResponse> => {
  return axios
    .get(`${GET_PACIENTES_URL}?${decodeURIComponent(query)}`)
    .then((d: AxiosResponse<PacientesQueryResponse>) => d.data)
}
const getPacientesEmpresa = (id: string, query: string): Promise<PacientesQueryResponse> => {
  return axios
    .get(`${PACIENTES_EMPRESAS_URL}${id}?${query}`)
    .then((d: AxiosResponse<PacientesQueryResponse>) => d.data)
}

const getPacienteById = (id: ID): Promise<Paciente | undefined> => {
  return axios
    .get(`${PACIENTE_URL}/${id}`)
    .then((response: AxiosResponse<Paciente>) => response.data)
}

export const downloadProntuario = (id: string): Promise<any> => {
  return axios
    .get(`${ATENDIMENTOS_URL}/prontuario/${id}`)
    .then((response: AxiosResponse<any>) => response.data)
}
export function getPacienteLogado() {
  return axios
    .get(`${PACIENTE_URL}/me`)
    .then((response) => response.data)
}

const createPaciente = (
  primeiroNome: string,
  sobreNome: string,
  cpf: string,
  cns: string | null,
  email: string,
  telefone: string,
  sexo: string,
  nascimento: string,
  senha: string,
  confirmaSenha: string,
  prontuario: string,
  idEmpresa: String): Promise<Paciente | undefined> => {
  return axios
    .post(PACIENTE_URL + '/empresa/' + idEmpresa, {
      primeiroNome,
      sobreNome,
      cpf,
      cns,
      email: email !== '' ? email : null,
      telefone,
      sexo,
      nascimento,
      senha,
      confirmaSenha,
      prontuario
    })
    .then((response: AxiosResponse<Paciente>) => response.data)
}

export const updateAsoPaciente = (
  id: ID,
  prontuario: string,
  numeroRg: string,
  orgaoRg: string,
  dataRg: string | null,
  numeroCtps: string,
  serieCtps: string,
  ufCtps: string,
  matriculaAso: string,
  cargo: string,
  setor: string,
  possuiDadosCtps: boolean
): Promise<Paciente | undefined> => {
  if (possuiDadosCtps) {
    return axios
      .put(PACIENTE_URL + '/aso/' + id, {
        ativo: true,
        prontuario: prontuario,
        pessoa: {
          rg: {
            numero: numeroRg,
            orgao: orgaoRg,
            data: dataRg
          },
          ctps: {
            numero: numeroCtps,
            serie: serieCtps,
            uf: ufCtps
          }
        },
        dadosAso: {
          matricula: matriculaAso,
          cargo: cargo,
          setor: setor
        }
      })
      .then((response: AxiosResponse<Paciente>) => response.data)
  } else {
    return axios
      .put(PACIENTE_URL + '/aso/' + id, {
        ativo: true,
        prontuario: prontuario,
        pessoa: {
          rg: {
            numero: numeroRg,
            orgao: orgaoRg,
            data: dataRg
          }
        },
        dadosAso: {
          matricula: matriculaAso,
          cargo: cargo,
          setor: setor
        }
      })
      .then((response: AxiosResponse<Paciente>) => response.data)
  }

}
export const createEnderecoPaciente = (idPaciente: ID,
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  uf: string
): Promise<Endereco | undefined> => {
  return axios
    .post(`${PACIENTE_URL}/endereco/${idPaciente}`, {
      tipo: 1,
      cep: cep,
      ativo: true,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      uf: uf
    })

}
export const updateEnderecoPaciente = (idPaciente: ID,
  idEndereco: Number,
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string,
  uf: string
): Promise<Endereco | undefined> => {
  return axios
    .put(`${PACIENTE_URL}/endereco/${idPaciente}`, {
      id: idEndereco,
      ativo: true,
      cep: cep,
      logradouro: logradouro,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      uf: uf
    })

}
export const getEnderecoByIdPaciente = (id: ID) => {
  return axios
    .get<Endereco[]>(`${PACIENTE_URL}/endereco/${id}`)
}
export function vincularPacienteEmpresa(
  cpf: string,
  prontuario: string,
  idEmpresa: String
) {
  return axios.put<{ result: Paciente }>(PACIENTE_URL + '/empresa/' + idEmpresa, {
    cpf,
    prontuario,
  })
}

const updatePaciente = (
  ativo: boolean,
  prontuario: string,
  primeiroNome: string,
  sobreNome: string,
  cpf: string,
  cns: string | null,
  telefone: string,
  sexo: string,
  nascimento: string,
  idPessoa: String,
  idPaciente: String): Promise<Paciente | undefined> => {
  return axios
    .put(`${PACIENTE_URL}/${idPaciente}`, {
      ativo,
      prontuario,
      pessoa: {
        id: idPessoa,
        primeiroNome,
        sobreNome,
        cpf,
        cns: cns !== '' && cns !== 'null' ? cns : null,
        telefone,
        sexo,
        nascimento
      }

    })
    .then((response: AxiosResponse<Paciente>) => response.data)
}
export const atualizaEmailPaciente = (idPaciente: ID, email: string): Promise<Paciente | undefined> => {
  return axios.put(`${PACIENTE_URL}/${idPaciente}`, {
    email: email
  })
    .then((response: AxiosResponse<Paciente>) => response.data)
}

const adicionaAlergia = (idPaciente: ID, descricao: String): Promise<Paciente | undefined> => {
  return axios
    .put(`${PACIENTE_URL}/alergia/${idPaciente}`, {
      descricao

    })
    .then((response: AxiosResponse<Response<Paciente>>) => response.data)
    .then((response: Response<Paciente>) => response.data)
}

const deletePaciente = (pacienteId: ID): Promise<void> => {
  return axios.delete(`${PACIENTE_URL}/${pacienteId}`);
}
const inativarPaciente = (pacienteId: ID): Promise<void> => {
  return axios.put(`${PACIENTE_URL}/${pacienteId}`, {
    ativo: false
  });
}
const deleteSelectedPaciente = (pacienteIds: Array<ID>): Promise<void> => {
  const requests = pacienteIds.map((id) => axios.delete(`${PACIENTE_URL}/${id}`))
  return axios.all(requests).then(() => { })
}
export const getAvatarPaciente = (idPaciente: ID): Promise<Picture | undefined> => {
  return axios
    .get(`${PACIENTE_URL}/${idPaciente}/avatar`)
    .then((response: AxiosResponse<Picture>) => response.data)
}

export { getPacientes, inativarPaciente, getPacientesEmpresa, adicionaAlergia, deletePaciente, deleteSelectedPaciente, getPacienteById, createPaciente, updatePaciente }
