// @ts-nocheck
import { Column } from 'react-table'
import { ExameCliente } from '../../../empresas-list/core/_models'
import { ExameCustomHeader } from './ExameCustomHeader'
import { ExameSelectionCell } from './ExameSelectionCell'
import { ExameActionsCell } from './ExameActionsCell'


const ExameColumns: ReadonlyArray<Column<ExameCliente>> = [
  {
    Header: (props) => <ExameSelectionCell tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <ExameSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ExameCustomHeader tableProps={props} title='Exame' className='min-w-125px' />,
    id: 'exame',
    accessor: 'exame'
  }, 
  {
    Header: (props) => <ExameCustomHeader tableProps={props} title='Setor' className='min-w-125px' />,
    id: 'setor.nome',
    accessor: 'setor.nome'
  }, 
  {
    Header: (props) => <ExameCustomHeader tableProps={props} title='Cargo' className='min-w-125px' />,
    id: 'cargo.nome',
    accessor: 'cargo.nome'
  }, 
  {
    Header: (props) => (
      <ExameCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ExameActionsCell id={props.data[props.row.index].id} />,
  },
]

export { ExameColumns }
