import * as Yup from 'yup'

import { FC, useEffect, useState } from 'react'
import { atualizaEmailPaciente, createEnderecoPaciente, createPaciente, getEnderecoByIdPaciente, updateAsoPaciente, updateEnderecoPaciente, updatePaciente } from '../core/_requests'
import { ID, isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'

import AsyncSelect from 'react-select/async'
import { Empresa } from '../../../empresa/empresas-list/core/_models'
import { IMaskInput } from 'react-imask'
import { Endereco, Paciente } from '../core/_models'
import { PacienteListLoading } from '../components/loading/PacienteListLoading'
import { PasswordMeterComponent } from '../../../../../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { getEmpresaById, getEmpresas } from '../../../empresa/empresas-list/core/_requests'
import { useFormik } from 'formik'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getUserByPessoa, updateEmailUser } from '../../../../auth/core/_requests'
import { isValid } from 'date-fns'

type Props = {
  isPacienteLoading: boolean
  paciente: Paciente

}
function refreshPage() {
  window.location.reload();
}
type SelectValue = {
  value: string,
  label: string
}
function getTiposAtendimento(empresa: Empresa) {
  var valuesTipoAt: any = []
  if (empresa.id !== undefined) {
    empresa.tiposAtendimento?.map(a => {
      valuesTipoAt.push({ value: a, label: a })
    })
  } else {
    valuesTipoAt.push({ value: 'PA', label: 'PA' })
  }
  return valuesTipoAt
}
function defineTiposInput(tipos: SelectValue[]) {
  var valuesTipoAt: any = []
  tipos.map((t) => {
    valuesTipoAt.push(t.value)
  })
  return valuesTipoAt
}
function possuiASO(tipos: SelectValue[]) {
  let possui = false
  if (tipos.length > 0) {
    tipos.map(t => {
      if (t.value === 'ASO') {
        possui = true
      }
    })
  }
  return possui
}
function formatCep(cep: string) {
  if (cep !== undefined) {
    cep.replace(/([.-])/g, "")
    cep.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1-$2')
      .replace(/(.\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  } else {
    return ''
  }
  return cep;
}
const PacienteEditModalForm: FC<Props> = ({ paciente, isPacienteLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [searchParams] = useSearchParams();
  const [empresa, setEmpresa] = useState('')
  const [email, setEmail] = useState<string>('')
  const [endereco, setEndereco] = useState<Endereco>()
  const [empresaObj, setEmpresaObj] = useState<Empresa>()
  const [isAso, setIsAso] = useState<boolean>(false)
  const idEmpresa = String(searchParams.get("empresa"))
  const isEdi = paciente.id !== undefined
  isPacienteLoading = false;
  const editPacienteSchema = Yup.object().shape({
    id: Yup.number()
      .notRequired(),
    primeiroNome: Yup.string()
      .min(3, 'Minimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo nome é obrigatório'),
    sobreNome: Yup.string()
      .min(3, 'Minimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo nome é obrigatório'),
    telefone: Yup.string()
      .min(16, 'Número Inválido')
      // .max(15, 'Máximo de 50 caracteres')
      .required('O campo telefone é obrigatório')
      .nullable(),
    email: Yup.string()
      .email('Formato inválido')
      .notRequired()
      .nullable(),
    cpf: Yup.string().required('O campo CPF é obrigatório'),
    sexo: Yup.string().required('O campo sexo é obrigatório'),
    nascimento: Yup.string().required('O campo data de nascimento é obrigatório'),
    tipoEndereco: Yup.string().notRequired()
    // .when([], {
    //   is: ()=>isAso,
    //     then: Yup.string().required('O campo tipo endereço é obrigatório'),
    //     otherwise: Yup.string().notRequired()
    //   })
    ,
    cep: Yup.string()
      .min(8, 'Minimo de 8 caracteres')
      .max(20, 'Máximo de 20 caracteres')
      .notRequired()
    ,
    logradouro: Yup.string()
      .min(5, 'Minimo de 5 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .notRequired()
    ,
    numero: Yup.string()
      .min(1, 'Minimo de 1 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .notRequired()
    ,
    complemento: Yup.string().notRequired(),
    bairro: Yup.string()
      .min(3, 'Minimo de 5 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .notRequired()
    ,
    cidade: Yup.string()
      .min(3, 'Minimo de 5 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .notRequired()
    ,
    uf: Yup.string()
      .min(2, 'Minimo de 2 caracteres')
      .max(2, 'Máximo de 2 caracteres')
      .notRequired()
  })
  const pacienteForEdit = {
    ...paciente,
    id: paciente.pessoa?.id,
    empresa: paciente.empresa?.id ? paciente.empresa?.id : '',
    primeiroNome: paciente.pessoa?.primeiroNome,
    sobreNome: paciente.pessoa?.sobreNome,
    cpf: paciente.pessoa?.cpf,
    cns: paciente.pessoa?.cns,
    telefone: paciente.pessoa?.telefone,
    email: email !== undefined ? email : '',
    sexo: paciente.pessoa?.sexo ? paciente.pessoa?.sexo : 'MASCULINO',
    nascimento: paciente.pessoa?.dataNascimento ? paciente.pessoa.dataNascimento : "",
    showEmail: paciente.id === undefined,
    //isAso
    isAso: isAso,
    numeroCi: paciente.pessoa?.rg?.numero,
    orgaoCi: paciente.pessoa?.rg?.orgao,
    dataCi: paciente.pessoa?.rg?.data,
    numeroCtps: paciente.pessoa?.ctps?.numero,
    serieCtps: paciente.pessoa?.ctps?.serie,
    ufCtps: paciente.pessoa?.ctps?.uf,
    matriculaAso: paciente.dadosAso?.matricula,
    cargoAso: paciente.dadosAso?.cargo,
    setorAso: paciente.dadosAso?.setor,
    // endereço
    cep: formatCep(String(endereco?.cep)),
    logradouro: endereco?.logradouro,
    numero: endereco?.numero,
    complemento: endereco?.complemento !== undefined ? endereco?.complemento : '',
    bairro: endereco?.bairro,
    cidade: endereco?.cidade,
    uf: endereco?.uf,

    idEndereco: paciente.id !== undefined && isAso ? endereco?.id : 0,
  }
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refreshPage()
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const userAvatarImg = toAbsoluteUrl(`/media/avatars/300-14.jpg`)
  //consulta pelo nome da empresa
  const INITIAL_DATA = {
    value: 0,
    label: 'Digite o nome da empresa',
  };

  const [selectData, setselectData] = useState(INITIAL_DATA);
  const mapResponseToValuesAndLabels = (data: Empresa) => ({
    value: data.id,
    label: data.nomeFantasia,
  });

  async function callApi(value: string) {
    const data = await getEmpresas('page=0&size=30&search=' + value)
      .then((response) => response.data?.map(mapResponseToValuesAndLabels))
      .then((final: any) =>
        final.filter((i: any) => String(i.label).toLowerCase().includes(value.toLowerCase()))
      );
    return data;
  }
  function setValueEmpresa(value: any) {
    setEmpresa(value)
    if (idEmpresa === 'null') {
      getEmpresa(value)
      formik.values.empresa = value
    }
  }
  function validadeEndereco() {
    var values = formik.values
    if (!isNotEmpty(values.cep)) {
      return false
    } else if (!isNotEmpty(values.logradouro)) {
      return false
    } else if (!isNotEmpty(values.numero)) {
      return false
    } else if (!isNotEmpty(values.bairro)) {
      return false
    } else if (!isNotEmpty(values.cidade)) {
      return false
    } else if (!isNotEmpty(values.uf)) {
      return false
    }
    return true
  }
  function validadeDadosAso() {
    var values = formik.values
    if (!isNotEmpty(values.numeroCi)) {
      return false
    } else if (!isNotEmpty(values.orgaoCi)) {
      return false
    } else if (!isNotEmpty(values.matriculaAso)) {
      return false
    } else if (!isNotEmpty(values.cargoAso)) {
      return false
    } else if (!isNotEmpty(values.setorAso)) {
      return false
    }
    return true
  }
  function trataErros(error: any, setFieldError: any) {
    let msg = error.response?.data.data.message
    Swal.fire({
      text: msg !== undefined ? msg : 'Erro na requisição',
      icon: 'error',
      buttonsStyling: false,
      confirmButtonText: "Ok",
      customClass: {
        confirmButton: "btn btn-primary"
      }
    })
    const errors = error.response.data.data.errors
    setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')
    setFieldError('cns', errors['cns'] !== undefined ? errors.cns[0] : '')
    setFieldError('telefone', errors['telefone'] !== undefined ? errors.telefone[0] : '')
    setFieldError('nascimento', errors['nascimento'] !== undefined ? errors.nascimento[0] : '')
    setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
    setFieldError('email', errors['email'] !== undefined ? errors.email[0] : '')
    setFieldError('prontuario', errors['prontuario'] !== undefined ? errors.prontuario[0] : '')
    setFieldError('cep', errors['cep'] !== undefined ? errors.cep[0] : '')
    setFieldError('logradouro', errors['logradouro'] !== undefined ? errors.logradouro[0] : '')
    setFieldError('numero', errors['numero'] !== undefined ? errors.numero[0] : '')
    setFieldError('complemento', errors['complemento'] !== undefined ? errors.complemento[0] : '')
    setFieldError('bairro', errors['bairro'] !== undefined ? errors.bairro[0] : '')
    setFieldError('cidade', errors['cidade'] !== undefined ? errors.cidade[0] : '')
    setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: pacienteForEdit,
    validationSchema: editPacienteSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (!validateCtps()) {
          Swal.fire({
            text: 'Informe todos os campos relaciodado ao CTPS ou deixe em branco!',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        }
        //edição do paciente
        else if (isNotEmpty(values.id)) {
          await updatePaciente(
            true,
            String(values.prontuario),
            String(values.primeiroNome),
            String(values.sobreNome),
            String(values.cpf),
            values.cns !== undefined || values.cns !== null ? String(values.cns) : null,
            String(values.telefone),
            String(values.sexo),
            String(values.nascimento),
            String(paciente.pessoa?.id),
            String(paciente.id)
          ).then((p) => {
            atualizaEmailPaciente(p?.id, values.email)
              .catch((error) => {
                trataErros(error, setFieldError)
              })
            if (isAso && validadeDadosAso()) {
              updateAsoPaciente(
                p?.id,
                String(p?.prontuario),
                String(values.numeroCi),
                String(values.orgaoCi),
                isNotEmpty(values.dataCi) ? String(values.dataCi) : null,
                String(values.numeroCtps),
                String(values.serieCtps),
                String(values.ufCtps),
                String(values.matriculaAso),
                String(values.cargoAso),
                String(values.setorAso),
                possuiDadosCtps()
              ).catch((error) => {
                trataErros(error, setFieldError)
                // cancel(true)
              })
            }
            //verifica se o paciente já possui endereço cadastrado
            if (values.idEndereco !== undefined) {
              if (validadeEndereco()) {
                updateEnderecoPaciente(Number(p?.id),
                  Number(values.idEndereco),
                  String(values.cep),
                  String(values.logradouro),
                  String(values.numero),
                  String(values.complemento),
                  String(values.bairro),
                  String(values.cidade),
                  String(values.uf)
                ).catch((error) => {
                  trataErros(error, setFieldError)
                })
              }
            } else {
              if (validadeEndereco()) {
                createEnderecoPaciente(Number(p?.id),
                  String(values.cep),
                  String(values.logradouro),
                  String(values.numero),
                  String(values.complemento),
                  String(values.bairro),
                  String(values.cidade),
                  String(values.uf)
                ).catch((error) => {
                  trataErros(error, setFieldError)
                })
              }
            }
          })
        } else {
          createPaciente(String(values.primeiroNome),
            String(values.sobreNome),
            String(values.cpf),
            values.cns !== undefined ? String(values.cns) : null,
            String(values.email),
            String(values.telefone),
            String(values.sexo),
            String(values.nascimento),
            '', '', '', (isNotEmpty(empresa) ? empresa : idEmpresa)).then((p) => {
              if (validadeEndereco()) {
                createEnderecoPaciente(Number(p?.id),
                  String(values.cep),
                  String(values.logradouro),
                  String(values.numero),
                  String(values.complemento),
                  String(values.bairro),
                  String(values.cidade),
                  String(values.uf)
                ).catch((error) => {
                  trataErros(error, setFieldError)
                })
              }
              if (isAso && validadeDadosAso()) {
                updateAsoPaciente(
                  p?.id,
                  String(p?.prontuario),
                  String(values.numeroCi),
                  String(values.orgaoCi),
                  values.dataCi !== undefined ? String(values.dataCi) : null,
                  String(values.numeroCtps),
                  String(values.serieCtps),
                  String(values.ufCtps),
                  String(values.matriculaAso),
                  String(values.cargoAso),
                  String(values.setorAso),
                  possuiDadosCtps()
                ).catch((error) => {
                  trataErros(error, setFieldError)
                })
              }
            }).catch((error) => {
              trataErros(error, setFieldError)
            })
        }
      } catch (error: any) {
        trataErros(error, setFieldError)
      } finally {
        setSubmitting(false)
        if (formik.isValid) {
          Swal.fire({
            text: 'Operação realizada com sucesso!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          }).then(() => {
            window.location.reload()
          })
        }
      }
    }
  })
  function getEmpresa(id: any) {
    let idEmp = idEmpresa !== '' && idEmpresa !== 'null' ? idEmpresa : id !== undefined ? id : paciente.empresa !== undefined ? paciente.empresa.id : undefined
    if (idEmp !== undefined) {
      getEmpresaById(Number(idEmp)).then((emp) => {
        setEmpresaObj(emp)
        emp?.tiposAtendimento?.map((t) => {
          if (t === 'ASO') {
            setIsAso(true)
          }
        })
      }).catch((error) => {
        Swal.fire({
          text: 'Empresa não localizada!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        })
      })
    }

  }
  function getEmail() {
    if (paciente !== undefined && paciente.pessoa !== undefined)
      getUserByPessoa(paciente.pessoa.id).then((user) => {
        setEmail(user.data.email !== undefined ? user.data.email : '')
      })
  }
  function possuiDadosCtps() {
    return isNotEmpty(formik.values.numeroCtps) && isNotEmpty(formik.values.serieCtps) && isNotEmpty(formik.values.ufCtps)
  }
  //Só permitir submeter CTPS se todos os campos forem preenchidos
  function validateCtps() {
    let isValid = false
    if (possuiDadosCtps()) {
      isValid = true
    } else if (!isNotEmpty(formik.values.numeroCtps) && !isNotEmpty(formik.values.serieCtps) && !isNotEmpty(formik.values.ufCtps)) {
      isValid = true
    }
    return isValid
  }
  async function getEndereco(paciente: Paciente) {
    var end: Endereco
    if (paciente !== undefined && paciente.id !== undefined) {
      var resultend = await getEnderecoByIdPaciente(paciente.id)

      if (resultend.data !== undefined) {
        end = { bairro: resultend.data[0].bairro }
        setEndereco(resultend.data[0])
      }

    }
    return undefined
  }
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    getEmpresa(undefined)
    getEndereco(paciente)
    getEmail()
    //  getEnderecoPaciente(paciente)
  }, [idEmpresa, paciente])


  return (
    <>
      <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_paciente_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
          data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Empresa</label>
            {!isEdi ? (
              <>
                <AsyncSelect
                  className='form-select-transparent'
                  cacheOptions
                  loadOptions={callApi}
                  name="empresa"
                  onChange={(data: any) => {
                    formik.setFieldValue('empresa', data.label);
                    setValueEmpresa(data.value)
                    setselectData(data);
                  }}
                  value={selectData}
                  defaultOptions
                //onInputChange={}
                />
                {!formik.errors.empresa && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.empresa}</span>
                    </div>
                  </div>
                )}
              </>
            ) :
              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{paciente?.empresa?.nome}</span>
              </div>
            }
          </div>
          {/* end::Image input */}

          {/* begin::Hint */}
          {/* <div className='form-text'>Allowed file types: png, jpg, jpeg.</div> */}
          {/* end::Hint */}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Primeiro Nome</label>
          <input
            placeholder='Primeiro Nome'
            {...formik.getFieldProps('primeiroNome')}
            type='text'
            name='primeiroNome'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.primeiroNome && formik.errors.primeiroNome },
              {
                'is-valid': formik.touched.primeiroNome && !formik.errors.primeiroNome,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.primeiroNome && formik.errors.primeiroNome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.primeiroNome}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Sobrenome</label>
          <input
            placeholder='Sobrenome'
            {...formik.getFieldProps('sobreNome')}
            type='text'
            name='sobreNome'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.sobreNome && formik.errors.sobreNome },
              {
                'is-valid': formik.touched.sobreNome && !formik.errors.sobreNome,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.sobreNome && formik.errors.sobreNome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.sobreNome}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          <label className='fw-bold fs-6 mb-2'>E-mail</label>
          <>
            <input
              placeholder='E-mail'
              {...formik.getFieldProps('email')}
              type='tel'
              name='email'
              // hidden={!formik.initialValues.showEmail}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              defaultValue={email !== undefined ? email : ''}
              disabled={formik.isSubmitting || isPacienteLoading}

            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </>

          {/* end::Input */}
        </div>
        {/* begin::Input */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Telefone</label>
          <IMaskInput
            placeholder='Telefone'
            {...formik.getFieldProps('telefone')}
            mask="(00) 0 0000-0000"
            type='text'
            name='telefone'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.telefone && formik.errors.telefone },
              {
                'is-valid': formik.touched.telefone && !formik.errors.telefone,
              }
            )}
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.telefone && formik.errors.telefone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.telefone}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Input */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>CPF</label>
          {/* end::Label */}

          {/* begin::Input */}
          <IMaskInput
            placeholder='CPF'
            {...formik.getFieldProps('cpf')}
            mask="000.000.000-00"
            type='text'
            name='cpf'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
              {
                'is-valid': formik.touched.cpf && !formik.errors.cpf,
              }
            )}
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.cpf && formik.errors.cpf && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cpf}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2'>CNS</label>
          {/* end::Label */}

          {/* begin::Input */}
          <IMaskInput
            placeholder='CNS'
            {...formik.getFieldProps('cns')}
            mask="000000000000000"
            type='text'
            name='cns'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.cns && formik.errors.cns },
              {
                'is-valid': formik.touched.cns && !formik.errors.cns,
              }
            )}
            disabled={formik.isSubmitting || isPacienteLoading}
          />
          {formik.touched.cns && formik.errors.cns && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cns}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Sexo</label>
          {/* end::Label */}

          {/* begin::Input */}
          <select
            className='form-select form-select-solid form-select-lg'
            {...formik.getFieldProps('sexo')}
          >
            <option value='MASCULINO' >Masculino</option>
            <option value='FEMININO'>Feminino</option>
          </select>
          {formik.touched.sexo && formik.errors.sexo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.sexo}</div>
            </div>
          )}
          {/* end::Input */}
          {formik.touched.sexo && formik.errors.sexo && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.sexo}</span>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Data de Nascimento</label>
          <input
            type='date'
            max={new Date().toISOString().split("T")[0]}
            className='form-control form-control-lg textbox-n form-control-solid'
            placeholder='Data de Nascimento'
            {...formik.getFieldProps('nascimento')}
          />
          {formik.touched.nascimento && formik.errors.nascimento && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.nascimento}</div>
            </div>
          )}
        </div>
        {/* </div> */}


        {/* Atendimento ASO */}
        {isAso ? (
          <>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Carteira de Identidade</label>
            </div>
            <div className='fv-row mb-7'>
              {/* begin::Input */}
              <label className='fw-bold fs-6 mb-2'>Número</label>
              <IMaskInput
                placeholder='Número'
                {...formik.getFieldProps('numeroCi')}
                mask="000000000000000"
                type='text'
                name='numeroCi'
                defaultValue={formik.values.numeroCi}
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.numeroCi && formik.errors.numeroCi },
                  {
                    'is-valid': formik.touched.numeroCi && !formik.errors.numeroCi,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.numeroCi && formik.errors.numeroCi && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.numeroCi}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Órgão</label>
                <input
                  placeholder='Órgão'
                  {...formik.getFieldProps('orgaoCi')}
                  type='text'
                  name='orgaoCi'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.orgaoCi && formik.errors.orgaoCi },
                    {
                      'is-valid': formik.touched.orgaoCi && !formik.errors.orgaoCi,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.orgaoCi && formik.errors.orgaoCi && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.orgaoCi}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Input */}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Data expedição</label>
              {/* begin::Label */}
              <input
                placeholder='dataCi'
                {...formik.getFieldProps('dataCi')}
                type='date'
                name='dataCi'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.dataCi && formik.errors.dataCi },
                  {
                    'is-valid': formik.touched.dataCi && !formik.errors.dataCi,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.dataCi && formik.errors.dataCi && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.dataCi}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Carteira de Trabalho</label>
            </div>
            <div className='fv-row mb-7'>
              <div className='fv-row mb-7'>
                {/* begin::Input */}
                <label className='fw-bold fs-6 mb-2'>Número</label>
                <IMaskInput
                  placeholder='Número'
                  {...formik.getFieldProps('numeroCtps')}
                  mask="000000000000000"
                  type='text'
                  name='numeroCtps'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.numeroCtps && formik.errors.numeroCtps },
                    {
                      'is-valid': formik.touched.numeroCtps && !formik.errors.numeroCtps,
                    }
                  )}
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.numeroCtps && formik.errors.numeroCtps && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numeroCtps}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-7'>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Série</label>
                {/* begin::Input */}
                <IMaskInput
                  placeholder='Série'
                  {...formik.getFieldProps('serieCtps')}
                  mask="000.00000.00-0"
                  type='text'
                  name='serieCtps'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.serieCtps && formik.errors.serieCtps },
                    {
                      'is-valid': formik.touched.serieCtps && !formik.errors.serieCtps,
                    }
                  )}
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.serieCtps && formik.errors.serieCtps && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.serieCtps}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='fw-bold fs-6 mb-2'>Estado</label>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                {...formik.getFieldProps('ufCtps')}
              >
                <option value=''>Selecione estado emissão...</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </select>
              {formik.touched.ufCtps && formik.errors.ufCtps && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.ufCtps}</div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Dados empresariais</label>
            </div>
            <div className='fv-row mb-7'>
              <label className=' fw-bold fs-6 mb-2'>Matrícula</label>
              {/* begin::Input */}
              <IMaskInput
                placeholder='Matrícula'
                {...formik.getFieldProps('matriculaAso')}
                mask="000000000000000"
                type='text'
                name='matriculaAso'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.matriculaAso && formik.errors.matriculaAso },
                  {
                    'is-valid': formik.touched.matriculaAso && !formik.errors.matriculaAso,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.matriculaAso && formik.errors.matriculaAso && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.matriculaAso}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Cargo</label>
              {/* begin::Input */}
              <input
                placeholder='Cargo'
                {...formik.getFieldProps('cargoAso')}
                type='text'
                name='cargoAso'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.cargoAso && formik.errors.cargoAso },
                  {
                    'is-valid': formik.touched.cargoAso && !formik.errors.cargoAso,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.cargoAso && formik.errors.cargoAso && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cargoAso}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-7'>
              <label className='fw-bold fs-6 mb-2'>Setor</label>
              {/* begin::Input */}
              <input
                placeholder='Setor'
                {...formik.getFieldProps('setorAso')}
                type='text'
                name='setorAso'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.setorAso && formik.errors.setorAso },
                  {
                    'is-valid': formik.touched.setorAso && !formik.errors.setorAso,
                  }
                )}
                disabled={formik.isSubmitting || isPacienteLoading}
              />
              {formik.touched.setorAso && formik.errors.setorAso && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.setorAso}</span>
                  </div>
                </div>
              )}
            </div>
            {/* Endereço */}
            <>
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Endereço</label>
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Logradouro</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Logradouro'
                  {...formik.getFieldProps('logradouro')}
                  type='text'
                  name='logradouro'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.logradouro && formik.errors.logradouro },
                    {
                      'is-valid': formik.touched.logradouro && !formik.errors.logradouro,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.logradouro && formik.errors.logradouro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.logradouro}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* begin::Input group 
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Tipo Endereço</label>
                  <Select
                    placeholder='Selecione um tipo de Endereço'
                    onChange={handleChangeTipoEndereco}
                    // options={optionsEmpresa}
                    options={options}
                    className={clsx(
                      'basic-single',
                      { 'is-invalid': formik.touched.tipoEndereco && formik.errors.tipoEndereco },
                      {
                        'is-valid': formik.touched.tipoEndereco && !formik.errors.tipoEndereco,
                      }
                    )}
                  />
                  {formik.touched.tipoEndereco && formik.errors.tipoEndereco && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.tipoEndereco}</span>
                      </div>
                    </div>
                  )}
                  {/* end::Input 
                </div>
                {/* end::Input group */}
              {/* end::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Número</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Número'
                  {...formik.getFieldProps('numero')}
                  type='text'
                  name='numero'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.numero && formik.errors.numero },
                    {
                      'is-valid': formik.touched.numero && !formik.errors.numero,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.numero && formik.errors.numero && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numero}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>CEP</label>
                {/* end::Label */}

                {/* begin::Input */}
                <IMaskInput
                  placeholder='CEP'
                  {...formik.getFieldProps('cep')}
                  mask="00.000-000"
                  type='text'
                  name='cep'
                  // hidden={hideFind}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.cep && formik.errors.cep },
                    {
                      'is-valid': formik.touched.cep && !formik.errors.cep,
                    }
                  )}
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.cep && formik.errors.cep && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.cep}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Complemento</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Complemento'
                  {...formik.getFieldProps('complemento')}
                  type='text'
                  name='complemento'
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.complemento && formik.errors.complemento },
                    {
                      'is-valid': formik.touched.complemento && !formik.errors.complemento,
                    }
                  )}
                />
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Bairro</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Bairro'
                  {...formik.getFieldProps('bairro')}
                  type='text'
                  name='bairro'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.bairro && formik.errors.bairro },
                    {
                      'is-valid': formik.touched.bairro && !formik.errors.bairro,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.bairro && formik.errors.bairro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.bairro}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Cidade</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Cidade'
                  {...formik.getFieldProps('cidade')}
                  type='text'
                  name='cidade'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.cidade && formik.errors.cidade },
                    {
                      'is-valid': formik.touched.cidade && !formik.errors.cidade,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isPacienteLoading}
                />
                {formik.touched.cidade && formik.errors.cidade && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.cidade}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 mb-2'>Estado</label>
                {/* end::Label */}

                {/* begin::Input */}
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('uf')}
                >
                  <option value=''>Selecione um estado...</option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
                {formik.touched.uf && formik.errors.uf && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.uf}</div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </>
            {/*   end::Input */}
          </>
        ) : (<></>)
        }

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt_modal_add_paciente='close'
            disabled={formik.isSubmitting || isPacienteLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt_modal_add_paciente='submit'
            disabled={isPacienteLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || isPacienteLoading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isPacienteLoading) && <PacienteListLoading />}
    </>
  )
}

export { PacienteEditModalForm }
