import { FC, useEffect, useState } from "react"
import { ID, KTSVG, QUERIES, } from "../../../../../../../_metronic/helpers"
import { useListView } from "../../core/ListViewProvider"
import { useMutation, useQueryClient } from "react-query"
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components"
import { deleteEmpresa } from "../../../../empresa/empresas-list/core/_requests"
import { useQueryResponse } from "../../core/QueryResponseProvider"
import { AxiosError } from "axios"
import Swal from "sweetalert2"
import { downloadAso, generateAso, getAtendimentoById } from "../../../../atendimento/core/_requests"

type Props = {
  id: ID
}

const AtendimentoAsoDownloadCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const [showAso, setShowAso] = useState(false);
  const handleCloseAso = () => setShowAso(false);
  const handleShowAso = () => setShowAso(true);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const inciarAtendimentoVideo = () => {
    window.location.href = "/apps/pacientes-management/pacientes?empresa=" + id
  }
  const deleteItem = useMutation(() => deleteEmpresa(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.ATENDIMENTO_MEDICO_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError;
      Swal.fire({
        text: err.response?.data.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }

      })
    },
  })
  const handleEmitirAso = () => {
    getAtendimentoById(id).then((atendimento) => {
      if (atendimento?.situacaoAso !== 'GERADO' && atendimento?.situacaoAso !== 'FINALIZADO') {
        generateAso(id).then((aso) => {
          if (aso !== undefined) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:application/pdf;base64," + (aso !== undefined ? aso.contentBase64 : ''); //Image Base64 Goes here
            a.download = String(aso.name); //File name Here
            a.click();
          }
        }).catch((error) => {
          const err = error as AxiosError;
          Swal.fire({
            text: err.response?.data.data.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        })
      } else {
        downloadAso(id).then((aso) => {
          if (aso !== undefined) {
            var a = document.createElement("a"); //Create <a>
            a.href = "data:application/pdf;base64," + (aso !== undefined ? aso.contentBase64 : ''); //Image Base64 Goes here
            a.download = String(aso.name); //File name Here
            a.click();
          }
        }).catch((error) => {
          const err = error as AxiosError;
          Swal.fire({
            text: err.response?.data.data.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        })
      }
    })
  }

  return (
    <>
      <button
        className='btn btn-primary btn-sm'
        onClick={() => handleEmitirAso()}
      >
        Baixar
        <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-5 m-0' />
      </button>
    </>

  )
}
export { AtendimentoAsoDownloadCell }
