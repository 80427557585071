import React from 'react';
import { Anotacoes } from '../../../atendimento/core/_models';
import ReactHtmlParser from 'react-html-parser';

interface AnnotationProps {
  nota: Anotacoes;
}

const Anotacao: React.FC<AnnotationProps> = ({ nota }) => {

  return (
    <ul>
      <li>
        {nota.tipo}
        {' - '}
        {ReactHtmlParser(nota.anotacao as string)}
      </li>
    </ul>
  );
}

export default Anotacao;
