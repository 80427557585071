import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { ListLoading } from '../../../commons/components/ListLoading';
import clsx from 'clsx';
import { ID, QUERIES, isNotEmpty } from '../../../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';
import { useQueryResponse } from '../core/QueryResponseProvider';
import Swal from 'sweetalert2';
import { CargoCliente, Empresa, RiscoCliente, SetorCliente } from '../../empresas-list/core/_models';
import { createRisco, editRiscoAso, getCargosCliente, getEmpresaClienteById, getSetoresCliente } from '../../empresas-list/core/_requests';
import { useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import { getTiposFatorASO } from '../../../atendimento/core/_requests';

type Props = {
  isEmpresaLoading: boolean,
  risco: RiscoCliente
};

const RiscoClienteEditModalForm: FC<Props> = ({ isEmpresaLoading, risco }) => {
  const queryClient = useQueryClient();
  const { query } = useQueryResponse();
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [searchParams] = useSearchParams();
  const idEmpresaCliente = Number(searchParams.get("cliente"));
  const [idEmpresa, setIdEmpresa] = useState<ID>();
  const [idSetor, setIdSetor] = useState<ID>();
  const [idCargo, setIdCargo] = useState<ID>();
  const [optionsSetor, setOptionsSetor] = useState<any>([]);
  const [optionsCargo, setOptionsCargo] = useState<any>([]);
  const [optionsFatorRisco, setOptionsFatorRisco] = useState<any>([]);

  const INITIAL_DATA_SETOR = {
    value: risco?.setor?.id || 0,
    label: risco?.setor?.nome || 'Selecione um setor',
  };
  const [selectDataSetor, setSelectDataSetor] = useState(INITIAL_DATA_SETOR);

  const INITIAL_DATA_CARGO = {
    value: risco?.cargo?.id || 0,
    label: risco?.cargo?.nome || 'Selecione um cargo',
  };
  const [selectDataCargo, setSelectDataCargo] = useState(INITIAL_DATA_CARGO);

  const INITIAL_DATA_FATOR_RISCO = {
    value: risco?.fatorRisco || 0,
    label: risco?.fatorRisco || 'Selecione um fator de risco',
  };
  const [selectDataFatorRisco, setSelectDataFatorRisco] = useState(INITIAL_DATA_FATOR_RISCO);

  function getOptionsSetores(id: ID) {
    getSetoresCliente(id, 'page=0&size=100&search=cliente.id:' + idEmpresaCliente).then((data) => {
      const results = data?.data?.map((value) => ({
        label: value.nome,
        value: value.id,
      })) || [];
      setOptionsSetor(results);
    }).catch((error) => {
      console.error(error);
    });
  }

  function getOptionsCargos(id: ID) {
    getCargosCliente(id, 'page=0&size=100&search=cliente.id:' + idEmpresaCliente).then((data) => {
      const results = data?.data?.map((value) => ({
        label: value.nome,
        value: value.id,
      })) || [];
      setOptionsCargo(results);
    }).catch((error) => {
      console.error(error);
    });
  }

  function getOptionsTipos() {
    getTiposFatorASO().then((data) => {
      const results = data?.map((value) => ({
        label: value.descricao,
        value: value.name,
      })) || [];
      setOptionsFatorRisco(results);
    }).catch((error) => {
      console.error(error);
    });
  }

  const editRiscoSchema = Yup.object().shape({
    setor: Yup.string().required('Setor é obrigatório'),
    cargo: Yup.string().required('Cargo é obrigatório'),
    fatorRisco: Yup.string().required('Fator de risco é obrigatório'),
    descricao: Yup.string().required('Descrição é obrigatório'),
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const riscoClienteForEdit = {
    id: risco.id,
    nome: risco.nome || '',
    setor: risco.setor?.id || undefined,
    cargo: risco.cargo?.id || undefined,
    fatorRisco: risco.fatorRisco || '',
    descricao: risco.descricao || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: riscoClienteForEdit,
    validationSchema: editRiscoSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      try {
        if (isNotEmpty(values.id)) {
          await editRiscoAso(values.id, values.cargo, values.setor, values.fatorRisco, values.descricao);
          Swal.fire({
            text: 'Risco alterado com sucesso!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`]);
            cancel();
          });
        } else {
          await createRisco(idEmpresaCliente, values.cargo, values.setor, values.fatorRisco, values.descricao);
          Swal.fire({
            text: 'Risco criado com sucesso!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error: any) {
        const errors = error.response.data.data.errors;
        if (errors) {
          setFieldError('nome', errors['nome'] ? errors.nome[0] : '');
          setFieldError('cargo', errors['cargo'] ? errors.cargo[0] : '');
          setFieldError('setor', errors['setor'] ? errors.setor[0] : '');
        } else {
          Swal.fire({
            text: "Não foi possível realizar a operação!",
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (idEmpresaCliente) {
      getEmpresaClienteById(idEmpresaCliente).then((result) => {
        setIdEmpresa(result?.empresa?.id);
        getOptionsSetores(result?.empresa?.id);
        getOptionsCargos(result?.empresa?.id);
        getOptionsTipos();
      }).catch((error) => {
        const err = error as AxiosError;
        Swal.fire({
          text: 'Erro ao buscar empresa! ' + err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      });
    } else {
      Swal.fire({
        text: 'Informe a Empregador ASO',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    }
  }, [risco]);

  useEffect(() => {
    if (optionsFatorRisco.length > 0) {
      const selectedOption = optionsFatorRisco.find((option: { value: string, label: string }) => option.value === risco.fatorRisco);
      setSelectDataFatorRisco(selectedOption || INITIAL_DATA_FATOR_RISCO);
    }
  }, [optionsFatorRisco, risco.fatorRisco]);

  return (
    <>
      <form id='kt_modal_add_empresa_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column me-n7 '
          id='kt_modal_add_empresa_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_empresa_header'
          data-kt-scroll-wrappers='#kt_modal_add_empresa_scroll'>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Setor</label>
            <Select
              placeholder='Selecione um setor'
              onChange={(data: any) => {
                setIdSetor(data.value);
                setSelectDataSetor(data);
                formik.setFieldValue('setor', data.value);
              }}
              options={optionsSetor}
              className='form-control form-control-solid'
              value={selectDataSetor}
            />
            {formik.touched.setor && formik.errors.setor && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.setor}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Cargo</label>
            <Select
              placeholder='Selecione um cargo'
              onChange={(data: any) => {
                setIdCargo(data.value);
                setSelectDataCargo(data);
                formik.setFieldValue('cargo', data.value);
              }}
              options={optionsCargo}
              className='form-control form-control-solid'
              value={selectDataCargo}
            />
            {formik.touched.cargo && formik.errors.cargo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cargo}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Fator de Risco</label>
            <Select
              placeholder='Selecione um fator de risco'
              onChange={(data: any) => {
                setSelectDataFatorRisco(data);
                formik.setFieldValue('fatorRisco', data.value);
              }}
              options={optionsFatorRisco}
              className='form-control form-control-solid'
              value={selectDataFatorRisco}
            />
            {formik.touched.fatorRisco && formik.errors.fatorRisco && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.fatorRisco}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Descrição</label>
            <input
              placeholder='Valor'
              {...formik.getFieldProps('descricao')}
              type='text'
              name='descricao'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.descricao && formik.errors.descricao },
                {
                  'is-valid': formik.touched.descricao && !formik.errors.descricao,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isEmpresaLoading}
            />
            {formik.touched.descricao && formik.errors.descricao && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.descricao}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isEmpresaLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isEmpresaLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || isEmpresaLoading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isEmpresaLoading) && <ListLoading />}
    </>
  );
};

export { RiscoClienteEditModalForm };
