/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup'

import React, { FC, useEffect, useRef, useState } from 'react'

import Avatar from 'react-avatar-edit'
import { IMaskInput } from 'react-imask'
import { PacienteEditModalFormWrapper } from './PacienteEditModalFormWrapper'
import { PacienteProntuarioForm } from './PacienteProntuarioForm'
import clsx from 'clsx'
import { getUserByCPF } from '../../../../auth/core/_requests'
import { useFormik } from 'formik'
import { useSearchParams } from 'react-router-dom'

const initialValues = {
  cpf: '',
}
var msgErro = ''
var showDiv = false
const PacienteFindSchema = Yup.object().shape({
  cpf: Yup.string().required('O campo CPF é obrigatório'),
})
const PacienteFindModalForm: FC = () => {
  const [hideFind, sethideFind] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [possuiCadastro, setPossuiCadastro] = useState<boolean | undefined>(undefined)
  const [cpfForInput, setCpfForInput] = useState('')
  const [nomeForInput, setNomeForInput] = useState('')
  let btnAddRef = useRef(null)
  let btnAddRefVinc = useRef(null)
  const [searchParams] = useSearchParams();
  const idEmpresa = String(searchParams.get("empresa"))

  const handleClick = (btnAdd: any) => {
    btnAdd.current.click();
  }
  function refreshPage() {
    window.location.reload();
  }
  const openModalVinc = () => {
    let element: HTMLElement = document.getElementsByClassName('btnAddRefVincModal')[0] as HTMLElement;
    element.click();

  }

  const formik = useFormik({
    initialValues,
    validationSchema: PacienteFindSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      sethideFind(undefined)
      setCpfForInput(values.cpf)
      getUserByCPF(values.cpf)
        .then((result) => {
          //Aqui tem que definir o id
          setPossuiCadastro(true)
          setNomeForInput(result.data.pessoa!==undefined?result.data.pessoa.nome:"")
          msgErro = result.data.email!==undefined?result.data.email:""
          //handleClick(btnAddRefVinc)
           openModalVinc()
        })
        .catch((error) => {
          setPossuiCadastro(false)
          handleClick(btnAddRef)
          msgErro = error.response.data.data.errors.email
          showDiv = true
          setStatus('The login detail is incorrect.')
        }).finally(() => {
          sethideFind(true)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  const resetData = () => {
    initialValues.cpf = '';
    sethideFind(undefined);
    formik.resetForm();
    setPossuiCadastro(undefined)
    setCpfForInput('')
    setNomeForInput('')
    setLoading(false)
  }
  return (

    <div>
      {possuiCadastro === false && (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>
            Paciente não possui cadastro.
          </div>
        </div>
      )}
      {possuiCadastro === true && (
        <div id="msgUser" className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Paciente localizado na base de dados.</div>
        </div>
      )}
      <div
        ref={btnAddRefVinc}
        className='card-header border-0 cursor-pointer btnAddRefVincModal hidden'
        role='button'
        data-bs-target='#kt_vincular_paciente'
        aria-expanded='true'
        // onAnimationEnd={resetData}
        // onClick={() => resetData()}
      >
      </div>
      {possuiCadastro ? (
        <div id='kt_vincular_paciente' className='fv-row'>
        {<PacienteProntuarioForm idEmpresa={idEmpresa} cpf={cpfForInput} nome={nomeForInput} onCancel={resetData} />}
      </div>
      ):(
        <></>
      )}
      {/* begin::Form group */}
      <div hidden={hideFind}>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6' >CPF</label>
            <IMaskInput
              placeholder='CPF'
              {...formik.getFieldProps('cpf')}
              mask="000.000.000-00"
              type='text'
              name='cpf'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
                {
                  'is-valid': formik.touched.cpf && !formik.errors.cpf,
                }
              )}
              disabled={formik.isSubmitting || loading}
            />
            {formik.touched.cpf && formik.errors.cpf && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cpf}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          <div className='d-flex flex-center flex-row-fluid pt-12 ' >
            <button
              type='reset'
              className='btn btn-light me-3'
              data-bs-dismiss='modal'
              data-kt_modal_add_paciente='close'
              onClick={() => resetData()}
            >
              Cancelar
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading} >
              {!loading && 'Verificar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      <div
        ref={btnAddRef}
        className='card-header border-0 cursor-pointer hidden'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_cadastar_paciente'
        aria-expanded='true'
      >
      </div>
      <div id='kt_cadastar_paciente' className='fv-row collapse hidden'>
        <div className='modal-content'>
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>Adicionar Paciente</h2>
          </div>
          {/* begin::Modal body */}
          <div className='modal-body scroll-y mx-20 mx-xl-15 my-7'>
            <PacienteEditModalFormWrapper cpfFind={cpfForInput} />
          </div>
        </div>
        { }
      </div>
    </div>
  )
}

export { PacienteFindModalForm }
