import { ID, Response } from "../../../../../../_metronic/helpers"
import { UserModel } from "../../../../auth"

export type Funcionario = {
  id?: ID,
  pessoa?: Pessoa,
  empresa?: Empresa,
  cargo?: string,
  descricaoCargo?: string, //transient
  cargoDescricao?: string,
  ativo?: boolean,
  responsavel?: string,
  email?: string,
  senha?: string,
  confirmaSenha?: string,
  crm?: string
  uf?: string
  nome?: string
}

export type FuncionarioMemed = {

  id?: ID,
  idExterno?: string,
  nome?: string,
  sobrenome?: string,
  nomeCompleto?: string,
  telefone?: string,
  cpf?: string,
  crm?: string,
  uf?: string,
  email?: string,
  token?: string,
  status?: string,
  especialidade?: string,
  termoAceito?: true,
  novoCadastro?: true,
  permitePrescrever?: true

}

export type Pessoa = {
  id: ID,
  nome?: string,
  primeiroNome?: string,
  sobreNome?: string,
  cpf: string,
  cns?: string,
  telefone?: string,
  sexo?: string,
  nascimento?: string,
  dataNascimento?: string,
  dataNascimentoFormatada?: string,
  uf?: string,
  certificadoDigital?: boolean
  usuario?:UserModel;
}

export type Empresa = {
  id?: ID,
  nome?: string,
  nomeFantasia?: string,
  cnpj?: string,
  ativa?: boolean,
  responsavel?: Pessoa,
  tiposAtendimento?: string[]
}
export type Vinculo = {
  crm: any
  id?: ID,
  empresa?: Empresa,
  cargo?: string,
  paciente?: boolean,
  ativo?: boolean,
  cargoDescricao?: string
}
export type Cargo = {
  id?: ID,
  descricao?: string,
  name?: string
}
export type FuncionariosQueryResponse = Response<Array<Funcionario>>
export type CargosQueryResponse = Response<Array<Cargo>>

export const initialFuncionario: Funcionario = {

}
export const initialPessoa: Pessoa = {
  id: undefined,
  cpf: ''
}
