/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {useSearchParams} from 'react-router-dom'
import {Formulario} from './_models'
import {getFormularioById} from './_requests'

const QueryResponseContext = createResponseContext<Formulario>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [idEmpresa] = useState<string>(useQueryResponseIdEmpresa())
  const [idPaciente] = useState<string>(useQueryResponseIdEmpresa())
  const [searchParams] = useSearchParams()
  const formularioId = Number(searchParams.get('id'))

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: responseSemPaginacao,
  } = useQuery(`${QUERIES.EMPRESAS_LIST}-${query}`, () => getFormularioById(Number(formularioId)), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return (
    <QueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, responseSemPaginacao, query, idEmpresa, idPaciente}}
    >
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {responseSemPaginacao} = useQueryResponse()

  return responseSemPaginacao
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response) {
    return defaultPaginationState
  }

  const responsePaginationState: PaginationState = {
    page: response?.page,
    size: response.size,
    totalElements: response.totalElements,
  }

  return responsePaginationState
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

const useQueryResponseIdEmpresa = (): string => {
  const {idEmpresa} = useQueryResponse()
  return idEmpresa
}
export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseIdEmpresa,
}
