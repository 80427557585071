import { useListView } from "../../core/ListViewProvider";
import { DownloadAsoListGrouping } from "./DownloadAsoListGrouping";
import { DownloadAsoListSearchComponent } from "./DownloadAsoListSearchComponent";
import { DownloadAsoListToolbar } from "./DownloadAsoListToolbar";

interface DownloadAsoListHeaderProps {
}

const DownloadAsoListHeader: React.FC<DownloadAsoListHeaderProps> = () => {
  const { selected } = useListView();

  return (
    <div className='border-0 pt-6'>
      <DownloadAsoListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <DownloadAsoListGrouping /> : <DownloadAsoListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export { DownloadAsoListHeader };
