import { KTCard } from "../../../../../_metronic/helpers"
import { EmpresasListHeader } from "./component/header/EmpresasListHeader"
import { ListViewProvider} from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { EmpresasTable } from "./table/EmpresasTable"



const EmpresasList = () => {
    return (
      <>
        <KTCard>
          <EmpresasListHeader />
          <EmpresasTable />
        </KTCard>
      </>
    )
  }
  
  const EmpresasListWrapper = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <EmpresasList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
  
  export {EmpresasListWrapper}