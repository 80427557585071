import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask'
import * as Yup from 'yup'
import { ID, KTSVG, isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { FuncionarioListLoading } from '../components/loading/FuncionarioListLoading'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { PasswordMeterComponent } from '../../../../../../_metronic/assets/ts/components'
import { Funcionario } from '../core/_models'
import { createFuncionario, updateFuncionario, getCargos, updateEnderecoFuncionario, createEnderecoFuncionario, getEnderecoByIdFuncionario, getEnderecoPessoaId } from '../core/_requests'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { Empresa } from '../../../empresa/empresas-list/core/_models'
import { getEmpresas } from '../../../empresa/empresas-list/core/_requests'
import Swal from 'sweetalert2'
import { Endereco } from '../../../paciente-management/pacientes-list/core/_models'
import { getUserByCPF, getUserByPessoa, persistCertificado, persistCertificadoAdmin, reenvioTokenConfirm, requestPassword } from '../../../../auth/core/_requests'
import { EmpresasVinculo } from '../../../empresa/empresas-list/table/EmpresasVinculo'
import { UserModel, useAuth } from '../../../../auth'

type Props = {
  isFuncionarioLoading: boolean
  cpf: String
  funcionario: Funcionario
  onCancel: Function
}
function refreshPage() {
  window.location.reload();
}

type AddressPayload = {
  idFuncionario: number;
  cep: string | null;
  logradouro: string | null;
  numero: string | null;
  complemento: string | null;
  bairro: string | null;
  cidade: string | null;
  uf: string;
}


const FuncionarioEditModalForm: FC<Props> = ({ funcionario, cpf, isFuncionarioLoading, onCancel }) => {
  const { setItemIdForUpdate } = useListView()
  const { currentUser } = useAuth()
  const [empresa, setEmpresa] = useState(funcionario.empresa !== undefined ? funcionario.empresa.id : '')
  const { idEmpresa, setIdEmpresa } = useListView()
  const { refetch } = useQueryResponse()
  const [usuario, setUsuario] = useState<UserModel>()
  const [possuiVinculo, setPossuiVinculo] = useState<boolean | undefined>(undefined)
  const [statusVinc, setStatusVinc] = useState<boolean>(false)
  const [cargoInput, setCargoInput] = useState(funcionario.cargo !== undefined ? funcionario.cargo : '')
  const [isAso, setIsAso] = useState<boolean>(false)
  const [endereco, setEndereco] = useState<Endereco>()
  const isEdi = funcionario.id !== undefined
  const [email, setEmail] = useState<String | undefined>('')
  const [usuarioFunc, setUsuarioFunc] = useState<UserModel>()
  const isAdmin = currentUser?.grupo?.nome === 'SUPER_ADMIN'
  const MEDICO = 'MEDICO'
  const [isMedico, setIsMedico] = useState<boolean>()
  const [certificadoValue, setCertificadoValue] = useState<File | undefined>()
  const possuiCertificado = funcionario?.pessoa?.certificadoDigital


  //const [searchParams] = useSearchParams();
  // const idEmpresa = String(searchParams.get("empresa"))
  function formatCep(cep: string) {
    if (cep !== undefined) {
      cep.replace(/([.-])/g, "")
      cep.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1-$2')
        .replace(/(.\d{3})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1')
    } else {
      return ''
    }
    return cep;
  }
  const editFuncionarioSchema = Yup.object().shape({
    id: Yup.number(),
    primeiroNome: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo nome é obrigatório'),
    sobreNome: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('O campo nome é obrigatório'),
    email: Yup.string()
      .email('Formato inválido')
      .required('O campo email é obrigatório'),
    cpf: Yup.string().required('O campo CPF é obrigatório'),
    sexo: Yup.string().required('O campo sexo é obrigatório'),
    telefone: Yup.string()
      .nullable()
      .min(16, 'Número Inválido'),
    dataNascimento: Yup.string().required('O campo data de nascimento é obrigatório'),
    cargo: Yup.string().required('O campo cargo é obrigatório'),
    empresa: Yup.string().required('O campo empresa é obrigatória'),
  
    cep: Yup.string().nullable(),
    logradouro: Yup.string().nullable(),
    complemento: Yup.string().nullable(),
    bairro: Yup.string().nullable(),
    cidade: Yup.string().nullable(),
    ufEndereco: Yup.string().nullable(),

  
    uf: Yup.string()
      .nullable()
      .when("cargo", {
        is: (cargo: string) => cargo === 'MEDICO',
        then: Yup.string()
          .min(2, 'Mínimo de 2 caracteres')
          .max(2, 'Máximo de 2 caracteres')
          .required('O campo UF é obrigatório'),
      }),
  });
  

  isFuncionarioLoading = false;
  const funcionarioForEdit = {
    ...funcionario,
    id: funcionario.id,
    primeiroNome: funcionario.pessoa?.primeiroNome,
    sobreNome: funcionario.pessoa?.sobreNome,
    cpf: funcionario.pessoa?.cpf,
    cns: funcionario.pessoa?.cns,
    uf: funcionario.uf,
    telefone: funcionario.pessoa?.telefone,
    sexo: funcionario.pessoa?.sexo ? funcionario.pessoa?.sexo : 'MASCULINO',
    dataNascimento: funcionario.pessoa?.dataNascimento ? funcionario.pessoa.dataNascimento : "",
    crm: funcionario.crm,
    cargo: cargoInput,
    empresa: empresa,
    email: usuarioFunc?.email || null,

    // endereço
    cep: !!endereco?.cep ? formatCep(String(endereco?.cep)) : '',
    logradouro: endereco?.logradouro || '',
    numero: endereco?.numero || '',
    complemento: endereco?.complemento || '',
    bairro: endereco?.bairro || '',
    cidade: endereco?.cidade || '',
    ufEndereco: endereco?.uf || '',

    isAso: isAso,
    idEndereco: endereco?.id || 0,
  }
  function validadeEndereco(values: any) {
    if (!isNotEmpty(values.cep)) {
      return false
    } else if (!isNotEmpty(values.logradouro)) {
      return false
    } else if (!isNotEmpty(values.numero)) {
      return false
    } else if (!isNotEmpty(values.bairro)) {
      return false
    } else if (!isNotEmpty(values.cidade)) {
      return false
    } else if (!isNotEmpty(values.ufEndereco)) {
      return false
    }
    return true
  }
  const cancel = () => {
    setItemIdForUpdate(undefined)
  }
  function getEmail() {
    if (funcionario !== undefined && funcionario.pessoa !== undefined)
      getUserByPessoa(funcionario.pessoa.id).then((user) => {
        setUsuarioFunc(user.data)
        let nomeGrupo = user.data.grupo?.nome
        setEmail(user.data.email)
      })
  }
  function reenviarConfirmacao() {
    if (usuarioFunc !== undefined)
      if (usuarioFunc.confirmado === false) {
        reenvioTokenConfirm(usuarioFunc.id)
          .then((f) => {
            Swal.fire({
              text: 'Reenvio do Token enviado com sucesso!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            })
          }).catch((error) => {
            console.log({ ...error })
            Swal.fire({
              text: 'Não foi possível reenviar o token!',
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            })
          })
      } else {
        Swal.fire({
          title: 'Conta já confirmada!',
          text: 'Deseja recadastrar a senha? Um e-mail será enviado para:' + usuarioFunc.email,
          icon: 'warning',
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
          customClass: {
            cancelButton: "btn btn-light",
            confirmButton: "btn btn-primary"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            requestPassword(String(usuarioFunc.email)).then(() => {
              Swal.fire({
                text: 'Solicitação realizada com sucesso!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              })
            }).catch((error) => {
              Swal.fire({
                text: 'Não foi possível realizar a operação!',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              })
            })
          }
        })
      }
  }
  var options: any = []
  getCargos().then((cargos) => {
    for (let i = 0; i < cargos.length; i++) {
      options.push({ value: cargos[i].name, label: cargos[i].descricao })
      if (funcionario.cargo === cargos[i].name) {
        // setCargoInput(String(cargos[i].id))
        funcionario.descricaoCargo = cargos[i].descricao
      }
    }

  }).catch((error: any) => {
    console.error(error)
  })


  //consulta pelo nome da empresa
  const INITIAL_DATA = {
    value: funcionario.empresa?.id !== undefined ? funcionario.empresa?.id : 0,
    label: funcionario.empresa?.id !== undefined ? String(funcionario.empresa?.nome) : 'Digite o nome da empresa',
  };
  const INITIAL_DATA_CARGO = {
    value: funcionario.cargo !== undefined ? funcionario.cargo : 0,
    label: funcionario.cargoDescricao !== undefined ? String(funcionario.cargoDescricao) : 'Digite um cargo',
  };
  const [selectData, setselectData] = useState(INITIAL_DATA);
  const [selectCargo, setselectCargo] = useState(INITIAL_DATA_CARGO);
  const mapResponseToValuesAndLabels = (data: Empresa) => ({
    value: data.id,
    label: data.nomeFantasia,
  });

  async function callApi(value: string) {
    const data = await getEmpresas('page=0&size=30&search=' + value)
      .then((response) => response.data?.map(mapResponseToValuesAndLabels))
      .then((final: any) =>
        final.filter((i: any) => String(i.label).toLowerCase().includes(value.toLowerCase()))
      );
    return data;
  }
  const getvinculos = () => {
    if (funcionario.id !== undefined && funcionario.pessoa !== undefined) {
      getUserByCPF(funcionario.pessoa?.cpf)
        .then((result) => {
          //Aqui tem que definir o id
          setUsuario(result.data)
          isNotEmpty(result.data.vinculos) ? setPossuiVinculo(true) : setPossuiVinculo(false)
        })
        .catch((error) => {
          setPossuiVinculo(false)
        })

    }
  }

  const handleSuccess = (message: string) => {
    Swal.fire({
      text: message,
      icon: 'success',
      buttonsStyling: false,
      confirmButtonText: "Ok",
      customClass: {
        confirmButton: "btn btn-primary"
      }
    }).then(() => {
      window.location.reload();
    });
  };
  
  const handleError = (error: any, setFieldError: (field: string, error: string) => void): void => {
    const errors = error.response?.data?.data?.errors;
    setFieldError('cpf', errors?.cpf?.[0] || '');
    setFieldError('dataNascimento', errors?.dataNascimento?.[0] || '');
    setFieldError('nome', errors?.nome?.[0] || '');
    setFieldError('email', errors?.email?.[0] || '');
    setFieldError('cns', errors?.cns?.[0] || '');
    setFieldError('crm', errors?.crm?.[0] || '');
    setFieldError('uf', errors?.uf?.[0] || '');
    setFieldError('telefone', errors?.telefone?.[0] || '');

    const allErrors = Object.entries(errors || {})
    .map(([field, messages]) => 
      //@ts-ignore
      `${field}: ${messages.join(', ')}`)
    .join('\n');


  Swal.fire({
    text: allErrors || 'An error occurred',
    icon: 'error',
    buttonsStyling: false,
    confirmButtonText: "Ok",
    customClass: {
      confirmButton: "btn btn-primary"
    }
  });
  };
  
  const handleAddress = async (values: any, id: ID): Promise<any> => {
    const addressPayload: AddressPayload = {
      idFuncionario: Number(id),
      cep: values.cep ? String(values.cep) : null,
      logradouro: values.logradouro ? String(values.logradouro) : null,
      numero: values.numero ? String(values.numero) : null,
      complemento: values.complemento ? String(values.complemento) : null,
      bairro: values.bairro ? String(values.bairro) : null,
      cidade: values.cidade ? String(values.cidade) : null,
      uf: String(values.ufEndereco)
    };
    console.log("Address Payload:", addressPayload);
    if(!!values.cep || !!values.logradouro || !!values.numero || !!values.complemento || !!values.bairro || !!values.cidade || values.ufEndereco){
      if (values.idEndereco !== 0) {
        console.log("Updating address...");
        return updateEnderecoFuncionario(
          id,
          Number(values.idEndereco),
          addressPayload.cep,
          addressPayload.logradouro,
          addressPayload.numero,
          addressPayload.complemento,
          addressPayload.bairro,
          addressPayload.cidade,
          addressPayload.uf
        );
      } else {
        console.log("Creating address...");
        return createEnderecoFuncionario(
          id,
          addressPayload.cep,
          addressPayload.logradouro,
          addressPayload.numero,
          addressPayload.complemento,
          addressPayload.bairro,
          addressPayload.cidade,
          addressPayload.uf
        );
      }
    }
  }
  

  
const formik = useFormik({
  enableReinitialize: true,
  initialValues: funcionarioForEdit,
  validationSchema: editFuncionarioSchema,
  onSubmit: async (values, { setSubmitting, setFieldError,  }) => {
    setSubmitting(true);
    try {


      let result;

      if (isNotEmpty(values.id)) {
        result = await updateFuncionario(
          Boolean(values.ativo),
          String(values.primeiroNome),
          String(values.sobreNome),
          String(values.cpf),
          String(values.cns),
          values.telefone ? String(values.telefone) : null,
          String(values.sexo),
          String(values.dataNascimento),
          String(cargoInput),
          String(values.crm),
          !!values.uf ? String(values.uf) : null,
          String(values.id),
          String(values.pessoa?.id),
          String(values.email)
        );
      } else {
        result = await createFuncionario(String(values.primeiroNome),
          String(values.sobreNome),
          String(values.cpf),
          String(values.cns),
          String(values.email),
          String(values.sexo),
          String(values.dataNascimento),
          String(cargoInput),
          String(values.crm),
          !!values.uf ? String(values.uf) : null,
          String(empresa),
          values.telefone ? String(values.telefone) : null
        );
      }

      if (isMedico && certificadoValue) {
        await persistCertificadoAdmin(result?.id, certificadoValue);
      }

      if(validadeEndereco(values)){
        await handleAddress(values, result?.id);
      }


      handleSuccess(isNotEmpty(values.id) ? 'Funcionário atualizado com sucesso!' : 'Funcionário cadastrado com sucesso!');
    } catch (error) {
      handleError(error, setFieldError);
    } finally {
      setSubmitting(false);
    }
  
   }});

  
  async function getEndereco() {
    try {
      const resultend = await getEnderecoPessoaId(usuario?.pessoa?.id);
      if (resultend && resultend.length > 0) {
        const end: Endereco = { bairro: resultend[0].bairro };
        setEndereco(resultend[0]); 
        return end;
      }
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
    return undefined; 
  }
  useEffect(() => {
    async function fetchData() {
      if(funcionario.id) {
        try {
          getEmail();
          getvinculos();
          setIsMedico(funcionario.cargo === MEDICO);
        } catch (error) {
          console.error("Erro ao buscar dados do funcionário:", error);
        }
      }
    }
  
    fetchData();
  }, [funcionario]);
  useEffect(() => {
    if(usuario?.pessoa?.id){
      getEndereco()
    }
  }, [usuario])
  const handleChange = (e: any) => {
    setCargoInput(e.value)
    setIsMedico(e.value === MEDICO ? true : false)
    setselectCargo(e)
    formik.setFieldValue('cargo', e.value)
  }
  return (
    <>
      <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column  me-n7 pe-7'
          id='kt_modal_add_paciente_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
          data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='required fw-bold '>Empresa</label>
            <AsyncSelect
              className='form-select-transparent'
              cacheOptions
              loadOptions={callApi}
              onChange={(data: any) => {
                setEmpresa(data.value)
                setselectData(data);
                formik.setFieldValue('empresa', data.value)
              }}
              value={selectData}
              defaultOptions

              isDisabled={formik.isSubmitting || isFuncionarioLoading}
            />
            {empresa === '' && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.empresa}</div>
              </div>
            )}
          </div>

        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Cargo</label>
          <Select
            placeholder='Selecione um cargo'
            {...formik.getFieldProps('cargo')}
            onChange={handleChange}
            options={options}
            value={selectCargo}
            // value={{ value: funcionario.cargo, label: funcionario.descricaoCargo }}
            className={clsx(
              'basic-single',
              { 'is-invalid': formik.touched.cargo && formik.errors.cargo },
              {
                'is-valid': formik.touched.cargo && !formik.errors.cargo,
              }
            )}
            isDisabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.values.cargo === '' && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.cargo}</div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Primeiro Nome</label>
          <input
            placeholder='Primeiro Nome'
            {...formik.getFieldProps('primeiroNome')}
            type='text'
            name='primeiroNome'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.primeiroNome && formik.errors.primeiroNome },
              {
                'is-valid': formik.touched.primeiroNome && !formik.errors.primeiroNome,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.touched.primeiroNome && formik.errors.primeiroNome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.primeiroNome}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>Sobrenome</label>
          <input
            placeholder='Sobrenome'
            {...formik.getFieldProps('sobreNome')}
            type='text'
            name='sobreNome'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.sobreNome && formik.errors.sobreNome },
              {
                'is-valid': formik.touched.sobreNome && !formik.errors.sobreNome,
              }
            )}
            autoComplete='off'
            disabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.touched.sobreNome && formik.errors.sobreNome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.sobreNome}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          <label className='required fw-bold fs-6 mb-2'>E-mail</label>
              <input
                placeholder='E-mail'
                {...formik.getFieldProps('email')}
                type='tel'
                name='email'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                disabled={formik.isSubmitting || isFuncionarioLoading}

              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
        </div>
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>CPF</label>
          {/* end::Label */}

          {/* begin::Input */}
          <IMaskInput
            placeholder='CPF'
            {...formik.getFieldProps('cpf')}
            mask="000.000.000-00"
            type='text'
            name='cpf'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
              {
                'is-valid': formik.touched.cpf && !formik.errors.cpf,
              }
            )}
            disabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.touched.cpf && formik.errors.cpf && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cpf}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2'>Telefone</label>
          {/* end::Label */}

          <IMaskInput
            placeholder='Telefone'
            {...formik.getFieldProps('telefone')}
            mask="(00) 0 0000-0000"
            type='text'
            name='telefone'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.telefone && formik.errors.telefone },
              {
                'is-valid': formik.touched.telefone && !formik.errors.telefone,
              }
            )}
            disabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.touched.telefone && formik.errors.telefone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.telefone}</span>
              </div>
            </div>
          )}
        </div>
        {cargoInput === 'MEDICO' && (
          <>
           <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2 required'>Número do Conselho de Classe</label>
          {/* end::Label */}

          {/* begin::Input */}
          <IMaskInput
            placeholder='Número do Conselho de Classe'
            {...formik.getFieldProps('crm')}
            mask="0000000000"
            type='text'
            name='crm'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.crm && formik.errors.crm },
              {
                'is-valid': formik.touched.crm && !formik.errors.crm,
              }
            )}
            disabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.touched.crm && formik.errors.crm && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.crm}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2 required'>UF do Conselho de Classe</label>
          <select
            className='form-select form-select-solid form-select-lg fw-bold'
            {...formik.getFieldProps('uf')}
          >
            <option value=''>Selecione um estado...</option>
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
          </select>
          {formik.touched.uf && formik.errors.uf && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.uf}</div>
            </div>
          )}
        </div>
          </>
        )}
       
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2'>CNS</label>
          {/* end::Label */}

          {/* begin::Input */}
          <IMaskInput
            placeholder='CNS'
            {...formik.getFieldProps('cns')}
            mask="000000000000000"
            type='text'
            name='cns'
            className={clsx(
              'form-control form-control-solid mb-3 mb-lg-0',
              { 'is-invalid': formik.touched.cns && formik.errors.cns },
              {
                'is-valid': formik.touched.cns && !formik.errors.cns,
              }
            )}
            disabled={formik.isSubmitting || isFuncionarioLoading}
          />
          {formik.touched.cns && formik.errors.cns && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.cns}</span>
              </div>
            </div>
          )}
          {/* end::Input */}
        </div>
        {/* end::Input group */}
        {/* begin::Input group */}
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Sexo</label>
          {/* end::Label */}

          {/* begin::Input */}
          <select
            className='form-select form-select-solid form-select-lg'
            {...formik.getFieldProps('sexo')}
          >
            <option value='MASCULINO' >Masculino</option>
            <option value='FEMININO'>Feminino</option>
          </select>
          {formik.touched.sexo && formik.errors.sexo && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.sexo}</div>
            </div>
          )}
          {/* end::Input */}
          {formik.touched.sexo && formik.errors.sexo && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.sexo}</span>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='required fw-bold fs-6 mb-2'>Data de Nascimento</label>
          <input
            type='date'
            max={new Date().toISOString().split("T")[0]}

            className='form-control form-control-lg textbox-n form-control-solid'
            placeholder='Data de Nascimento'
            defaultValue={funcionario.pessoa?.dataNascimento}
            {...formik.getFieldProps('dataNascimento')}
          />
          {formik.touched.dataNascimento && formik.errors.dataNascimento && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.dataNascimento}</div>
            </div>
          )}
        </div>

        {/* Endereço */}
        <>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Endereco</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Logradouro'
              {...formik.getFieldProps('logradouro')}
              type='text'
              name='logradouro'
              onChange={(e) => formik.setFieldValue('logradouro', e.target.value)}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.logradouro && formik.errors.logradouro },
                {
                  'is-valid': formik.touched.logradouro && !formik.errors.logradouro,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isFuncionarioLoading}
            />
            {formik.touched.logradouro && formik.errors.logradouro && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.logradouro}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Número</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Número'
              {...formik.getFieldProps('numero')}
              type='text'
              name='numero'
              onChange={(e) => formik.setFieldValue('numero', e.target.value)}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.numero && formik.errors.numero },
                {
                  'is-valid': formik.touched.numero && !formik.errors.numero,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isFuncionarioLoading}
            />
            {formik.touched.numero && formik.errors.numero && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.numero}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>CEP</label>
            {/* end::Label */}

            {/* begin::Input */}
            <IMaskInput
              placeholder='CEP'
              {...formik.getFieldProps('cep')}
              mask="00.000-000"
              type='text'
              name='cep'
              // hidden={hideFind}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.cep && formik.errors.cep },
                {
                  'is-valid': formik.touched.cep && !formik.errors.cep,
                }
              )}
              disabled={formik.isSubmitting || isFuncionarioLoading}
            />
            {formik.touched.cep && formik.errors.cep && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cep}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
          {/* begin::Label */}
          <label className='fw-bold fs-6 mb-2'>UF</label>
          <select
            className='form-select form-select-solid form-select-lg fw-bold'
            {...formik.getFieldProps('ufEndereco')}
            onChange={(e) => formik.setFieldValue('ufEndereco', e.target.value)}
          >
            <option value=''>Selecione um estado...</option>
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
          </select>
          {formik.touched.ufEndereco && formik.errors.ufEndereco && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.ufEndereco}</div>
            </div>
          )}
        </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Complemento</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Complemento'
              {...formik.getFieldProps('complemento')}
              type='text'
              name='complemento'
              onChange={(e) => formik.setFieldValue('complemento', e.target.value)}
              autoComplete='off'
              disabled={formik.isSubmitting || isFuncionarioLoading}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.complemento && formik.errors.complemento },
                {
                  'is-valid': formik.touched.complemento && !formik.errors.complemento,
                }
              )}
            />
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Bairro</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Bairro'
              {...formik.getFieldProps('bairro')}
              type='text'
              name='bairro'
              onChange={(e) => formik.setFieldValue('bairro', e.target.value)}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.bairro && formik.errors.bairro },
                {
                  'is-valid': formik.touched.bairro && !formik.errors.bairro,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isFuncionarioLoading}
            />
            {formik.touched.bairro && formik.errors.bairro && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bairro}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className=' fw-bold fs-6 mb-2'>Cidade</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Cidade'
              {...formik.getFieldProps('cidade')}
              type='text'
              onChange={(e) => formik.setFieldValue('cidade', e.target.value)}
              name='cidade'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.cidade && formik.errors.cidade },
                {
                  'is-valid': formik.touched.cidade && !formik.errors.cidade,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isFuncionarioLoading}
            />
            {formik.touched.cidade && formik.errors.cidade && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cidade}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div
            // ref={btnAddRefVinc}
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-target='#kt_vincular_funcionario'
            aria-expanded='true'
            data-bs-toggle='collapse'
            title='Vínculos'
            // onAnimationEnd={resetData}
            onClick={() => { statusVinc ? setStatusVinc(false) : setStatusVinc(true) }}
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Vínculos Empresas
                {statusVinc ?
                  (
                    <KTSVG path='/media/icons/duotune/arrows/arr081.svg' />
                  ) : (
                    <KTSVG path='/media/icons/duotune/arrows/arr082.svg' />
                  )
                }
              </h3>
            </div>
          </div>
          {possuiVinculo ? (
            <div id='kt_vincular_funcionario' className='fv-row collapse'>
              <EmpresasVinculo vinculos={usuario?.vinculos} cpf={usuario?.pessoa?.cpf} key={1} showExc={false}></EmpresasVinculo>
            </div>
          ) : (
            <>
              <label className='fw-bold '>Empresa</label>
            </>
          )}
        </>
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            Cancelar
          </button>
            <button
              type='reset'
              onClick={() => reenviarConfirmacao()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || (usuarioFunc === undefined)}
            >
              Reenviar token
            </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isFuncionarioLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Enviar</span>
            {(formik.isSubmitting || isFuncionarioLoading) && (
              <span className='indicator-progress'>
                Aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isFuncionarioLoading) && <FuncionarioListLoading />}
    </>
  )
}

export { FuncionarioEditModalForm }
