import { Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { EspecialidadeListWrapper } from "./especialidades-list/EspecialidadeList"


const apiBreadcrumbs: Array<PageLink> = [
  {
    title: 'Especialidades',
    path: '/apps/especialidades',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EspecialidadePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={apiBreadcrumbs}>Lista</PageTitle>
              <EspecialidadeListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default EspecialidadePage
