import { Modal } from "react-bootstrap"
import { KTCard } from "../../../../../_metronic/helpers"
import { HistoricoListHeader } from "./component/header/HistoricoListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { HistoricoDetalhes } from "./historicoDetalhes"
import { HistoricoTable } from "./table/HistoricoTable"



const HistoricoList = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const handleClose = () => {
    setItemIdForUpdate(undefined)
  }
  return (
    <>
      <KTCard>
        <HistoricoListHeader />
        <HistoricoTable />
      </KTCard>

      <div>
        <Modal className="modal-xl" show={itemIdForUpdate !== undefined} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Visualização Atendimento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HistoricoDetalhes idAtendimento={Number(itemIdForUpdate)} />
          </Modal.Body>
        </Modal>
      </div>

    </>
  )
}

const HistoricoListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <HistoricoList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { HistoricoListWrapper }