import { FC, useState } from 'react';
import { ID, KTSVG } from '../../../../../../../_metronic/helpers';
import { Row } from 'react-table';
import { Especialidade } from '../../core/_models';
import EspecialidadeEditModalForm from '../../../especialidade-edit-modal-form/EspecialidadeEditModalForm';
import EspecialidadeStatusModal from '../../../dasativar-especialidade-modal/EspecialidadeStatusModal';

type Props = {
  id: ID;
  row: Row<Especialidade>;
};

const EspecialidadeActionsCell: FC<Props> = ({ id, row }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowModalStatus = () => setShowModalStatus(true);
  const handleCloseModalStatus = () => setShowModalStatus(false);

  return (
    <div className="d-flex justify-content-end">
      <div className="dropdown">
        <button
          className='btn btn-primary btn-active-primary btn-sm dropdown-toggle'
          type='button'
          id='dropdownMenuButton'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          Ações
        </button>
        <ul
          className='dropdown-menu dropdown-menu-end'
          aria-labelledby='dropdownMenuButton'
        >
          <li>
            <a
              className='dropdown-item'
              onClick={() => handleShowModal()}
            >
              Editar
            </a>
          </li>
          <li>
            <a
              className='dropdown-item'
              onClick={() => handleShowModalStatus()}
            >
              {row.original.ativa ? 'Desativar' : 'Ativar'}
            </a>
          </li>
        </ul>
      </div>

      <EspecialidadeEditModalForm
        show={showModal}
        onHide={handleCloseModal}
        valuesEdit={row.original}
      />
      <EspecialidadeStatusModal
        show={showModalStatus}
        onHide={handleCloseModalStatus}
        especialidade={row.original}
      />
    </div>
  );
};

export { EspecialidadeActionsCell };
