import { KTCard } from "../../../../../_metronic/helpers"
import { SetorClienteListHeader } from "./component/header/SetorClienteListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { SetorClienteEditModal } from "./setor-edit-modal/SetorClienteEditModal"
import { SetorClienteTable } from "./table/SetorClienteTable"


const SetorClienteList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <SetorClienteListHeader />
        <SetorClienteTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <SetorClienteEditModal />}
    </>
  )
}

const SetorClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <SetorClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { SetorClienteListWrapper }