/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { deleteColaboradorCliente, getEmpresaClienteById } from '../../../empresas-list/core/_requests'
import { ColaboradorPcmso, EmpresaCliente } from '../../../empresas-list/core/_models'
import { Coordenador } from '../../../empresasCliente-list/empresa-cliente-edit-modal/Coordenador'
import { useSearchParams } from 'react-router-dom'
import { refreshEmpresaCliente } from '../../CoordenadorClienteList'
type Props = {
  colaborador: ColaboradorPcmso

}

const CoordenadorActionsCell: FC<Props> = ({ colaborador }) => {
  const [searchParams] = useSearchParams();
  const cliente = Number(searchParams.get("cliente"))
  const [empresaCliente, setEmpresaCliente] = useState<EmpresaCliente>()
  const [showCadastroCoord, setShowCadastroCoord] = useState<boolean>(false);


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const updateStateCliente = async () => {
    getEmpresaClienteById(cliente).then((result) => {
      if (result) {
        setEmpresaCliente(result)
      } else {
        Swal.fire({
          text: "Não foi possível atualizar os dados do Empregador ASO!",
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        })
      }
    })
  }

  const excluiCoordenador = (id: ID) => {
    deleteColaboradorCliente(cliente, id)
      .then(() => {
        Swal.fire({
          text: 'Coordenador excluído com sucesso!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          refreshEmpresaCliente();
        });
      })
      .catch((error) => {
        Swal.fire({
          text: 'Não foi possível excluir este coordenador!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });
      });
  }


  const editCoordenador = () => {
    setShowCadastroCoord(true);
  }

  const handleShowCoordenador = async () => {
    updateStateCliente();
    setShowCadastroCoord(!showCadastroCoord);
  }
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => editCoordenador()}>
            Alterar
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => excluiCoordenador(colaborador.id)}>
            Excluir
          </a>
        </div>
      </div>
      {/* end::Menu */}
      {showCadastroCoord && (
        <div>
          <div className='modal-backdrop fade show' style={{ zIndex: 1050 }}></div>
          <div className='modal fade show' id='exampleModal' tabIndex={-1} aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: 'block' }}>
            <div className='modal-dialog modal-xl'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button type='button' className='btn-close' aria-label='Close' onClick={handleShowCoordenador}></button>
                </div>
                <div className='modal-body justify-content-start text-start'>
                  <Coordenador empresaCliente={empresaCliente} coordenador={colaborador} onCancel={handleShowCoordenador} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { CoordenadorActionsCell }
