/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getLoggedUser, register, resetPasswordUserConfirm} from '../core/_requests'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2'

const initialValues = {
  senha: '',
  confirmaSenha: '',
}
const resetPasswordSchema = Yup.object().shape({
  senha: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .matches(/[a-z]+/, "Inserir pelo menos um caractere especial")
    .matches(/[A-Z]+/, "Inserir pelo menos um caractere Maiúsculo")
    .matches(/[@$!%*#?&]+/, "Inserir pelo menos um caractere especial")
    .matches(/\d+/, "Inserir pelo menos um número")    
    .required('Campo senha obrigatório'),
  confirmaSenha: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "As senhas não são identicas"),
    }),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [searchParams, setSearchParams] = useSearchParams();
  const token = String(searchParams.get("token"))
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await resetPasswordUserConfirm(
          token,
          values.senha,
          values.confirmaSenha
        )
        navigate("/auth?alter=true");
      } catch (error:any) {
        const senhaError = error.response?.data?.data?.errors?.senha?.[0] || '';
        const tokenError = error.response?.data?.data?.errors?.token || '';
        const confirmaSenhaError = error.response?.data?.data?.errors?.confirmaSenha || '';

        const msgErro = `${senhaError} ${tokenError} ${confirmaSenhaError}`.trim();
        Swal.fire({
          text: msgErro,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        })
        saveAuth(undefined)
        setStatus(error.response.data.data.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div className="d-flex flex-column flex-column-fluid flex-center py-20">
      <div className="card-body">
            <div className="text-start mb-10">
              <h1 className="text-dark mb-3 fs-2x" data-kt-translate="sign-in-title">Alterar Senha</h1>
            </div>
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Senha'
              autoComplete='off'
              {...formik.getFieldProps('senha')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.senha && formik.errors.senha,
                },
                {
                  'is-valid': formik.touched.senha && !formik.errors.senha,
                }
              )}
            />
            {formik.touched.senha && formik.errors.senha && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.senha}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Utilize 8 ou mais caracteres com números, letras e caracteres especiais.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5 ' data-kt-password-meter='true'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmar senha</label>
        <input
          type='password'
          placeholder='Confirmar senha'
          autoComplete='off'
          {...formik.getFieldProps('confirmaSenha')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmaSenha && formik.errors.confirmaSenha,
            },
            {
              'is-valid': formik.touched.confirmaSenha && !formik.errors.confirmaSenha,
            }
          )}
        />
        {formik.touched.confirmaSenha && formik.errors.confirmaSenha && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmaSenha}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Enviar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
    </div>
    </div>
  )
}
