import { FC, useEffect, useState } from "react";
import { AtendimentoIniciado } from "../../atendimento/core/_models";
import { getAtendimentoById } from "../../atendimento/core/_requests";
import ReactHtmlParser from 'react-html-parser';

type Props = {
    atendimento: AtendimentoIniciado
}

const Anamnese: FC<Props> = ({ atendimento, }) => {
    const [atendimentoEx, setAtendimentoEx] = useState<AtendimentoIniciado>(atendimento)

    const defineAtendimento = () => {
        getAtendimentoById(atendimento.id).then((a) => {
            setAtendimentoEx(a as AtendimentoIniciado)
        })
    }

    useEffect(() => {
        defineAtendimento()
    }, [])
    
    return (
        <div
            className='card mb-5'
            data-kt-scroll='true'
            data-kt-scroll-offset='5px'
        >
           <div className='card-title py-2'>
            <h3 className='fw-bolder border border-gray-200 m-0'>Anamnese</h3>
          </div>
          {atendimentoEx !== undefined ? (
            <div className='flex-row'>
              <p className='text-muted'>
                {atendimentoEx?.anotacoes?.map((anotacao) => (
                  <div className='py-2' key={anotacao.dataFormatada}>
                    <p className='fw-bolder'>Nota {anotacao.dataFormatada}</p>
                    <p>{ReactHtmlParser(anotacao?.anotacao as string)}</p>
                  </div>
                ))}
              </p>
            </div>
          ) : (
            <>Sem anotações</>
          )}  
        </div>
    )
}
export { Anamnese }