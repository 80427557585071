import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { ListLoading } from '../../../commons/components/ListLoading'
import { AtendimentoIniciado, Dispositivo, TiposDispositivos } from '../../../atendimento/core/_models'
import { CustomHeaderColumn } from '../../../paciente-management/pacientes-list/table/columns/CustomHeaderColumn'
import { getFuncionarioLogado } from '../../../funcionarios-management/funcionarios-list/core/_requests'
import { Funcionario } from '../../../funcionarios-management/funcionarios-list/core/_models'
import Swal from 'sweetalert2'
import React from 'react'
import { HistoricoListPagination } from '../component/pagination/HistoricoListPagination'
import { HistoricoColumns } from './columns/_columns'

const HistoricoTable = () => {
  const atendimentosMedicos = useQueryResponseData()
  const atendimentosFormatados = atendimentosMedicos.sort((a, b) => {
    const dataA = a.inicio ? new Date(a.inicio) : new Date(0);
    const dataB = b.inicio ? new Date(b.inicio) : new Date(0);
    //@ts-ignore
    return dataB - dataA;
  });
  
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => atendimentosFormatados, [atendimentosFormatados])
  const columns = useMemo(() => HistoricoColumns, [])
  const [medico, setMedico] = useState<Funcionario | undefined>(undefined)
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data as AtendimentoIniciado[],
  })
  useEffect(() => {
    getFuncionarioLogado().then((result: Funcionario) => {
      setMedico(result)
    })
      .catch((err) => {
        Swal.fire({
          text: err.response?.data.data.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        })
      })
  }, [])

  const getIconsPath = (dispositivos: Dispositivo[], tipo: TiposDispositivos) => {
    const dispositivoEncontrado = dispositivos.find(d => d.tipo === tipo);

    if (dispositivoEncontrado) {
      return `/media/icons/duotune/sinais/${tipo.toLowerCase()}-on.png`;
    } else {
      return `/media/icons/duotune/sinais/${tipo.toLowerCase()}.png`;
    }

  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_atendimento_medico'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<AtendimentoIniciado>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<AtendimentoIniciado>, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={`row-${i}-${row.id}`}>
                    <tr>
                      {row.cells.map((cell, j) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.column.id === 'sinais' ? (
                              <div className="d-flex flex-column justify-content-center align-items-center">
                              <div className="d-flex justify-content-around align-items-center mb-2">
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'BALANCA')}
                                  width={33}
                                  alt="Ícone de balança"
                                />
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'PRESSAO')}
                                  width={35}
                                  alt="Ícone de aferidor de pressão arterial"
                                />
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'OXIMETRO')}
                                  width={25}
                                  alt="Ícone de oxímetro"
                                />
                              </div>
                              <div className="d-flex justify-content-around align-items-center">
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'TERMOMETRO')}
                                  width={25}
                                  alt="Ícone de termometro"
                                />
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'CAMERA')}
                                  width={25}
                                  alt="Ícone de inspeção"
                                />
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'OTOSCOPIO')}
                                  width={25}
                                  alt="Ícone de otoscópio"
                                />
                                <img
                                  src={getIconsPath(row?.original?.dispositivos as Dispositivo[], 'GLICOSIMETRO')}
                                  width={25}
                                  alt="Ícone de Glicosímetro"
                                />
                              </div>
                          </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>

                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Nenhum registro encontrado
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <HistoricoListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { HistoricoTable }
