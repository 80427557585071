import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { KTCard, KTCardBody, KTSVG } from "../../../../../_metronic/helpers";
import { ListLoading } from "../../commons/components/ListLoading";
import { CustomHeaderColumn } from "../../commons/table/CustomHeaderColumn";
import { CustomRow } from "../../commons/table/CustomRow";
import { ColaboradorPcmso, EmpresaCliente } from "../empresas-list/core/_models";
import { getEmpresaClienteById } from "../empresas-list/core/_requests";
import { CoordenadorClienteListHeader } from "./component/header/CoordenadorClienteListHeader";
import { CoordenadorClienteEditModal } from "./coordenador-edit-modal/CoordenadorClienteEditModal";
import { ListViewProvider, useListView } from "./CoordenadorQueryResponseContext.ts/ListViewProvider";
import { QueryRequestProvider } from "./CoordenadorQueryResponseContext.ts/QueryRequestProvider";
import { CoordenadorColumns } from "./table/columns/_columns";
import { CoordenadorClienteTable } from "./table/CoordenadorClienteTable";

let storedCliente: number | undefined;
let storedSetEmpresa: React.Dispatch<React.SetStateAction<EmpresaCliente | undefined>> | undefined;
let storedSetIsLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined;

const fetchData = async () => {
  try {
    if (storedCliente !== undefined && storedSetEmpresa && storedSetIsLoading) {
      const response = await getEmpresaClienteById(storedCliente);
      storedSetEmpresa(response);
      storedSetIsLoading(false);
    }
  } catch (error) {
    console.error('Error fetching Empregador ASO:', error);
    if (storedSetEmpresa && storedSetIsLoading) {
      storedSetEmpresa(undefined);
      storedSetIsLoading(false);
    }
  }
};

export const refreshEmpresaCliente = () => {
  fetchData();
};

const CoordenadorClienteList = () => {
  const { itemIdForUpdate } = useListView();
  const [searchParams] = useSearchParams();
  const cliente = Number(searchParams.get("cliente"));
  const [empresa, setEmpresa] = useState<EmpresaCliente>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setItemIdForUpdate } = useListView()
  const openAddCoordenador = () => {
    setItemIdForUpdate(null)
  }


  useEffect(() => {
    storedCliente = cliente;
    storedSetEmpresa = setEmpresa;
    storedSetIsLoading = setIsLoading;
    fetchData();
  }, [cliente]);

  return (
    <>
      <KTCard>
        {isLoading ? (
          <ListLoading />
        ) : (
          <>
            <CoordenadorClienteTable empresa={empresa} isLoading={isLoading} />
          </>

        )}
      </KTCard>
      {itemIdForUpdate !== undefined && <CoordenadorClienteEditModal />}
    </>
  );
};

const CoordenadorClienteListWrapper = () => (
  <QueryRequestProvider>
    <ListViewProvider>
      <CoordenadorClienteList />
    </ListViewProvider>
  </QueryRequestProvider>
);

export { CoordenadorClienteListWrapper };
