import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../../../../../_metronic/helpers"
import { useListView } from "../../core/ListViewProvider"


const AtendimentoMedicoListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const navigate = useNavigate();
  const openAddAtendimento = () => {
    navigate('/apps/pacientes-management/pacientes')
  }

  return (
    <div className='d-flex justify-content-end' style={{ marginRight: '20px' }} data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary w-100 w-md-auto mb-3 mb-md-0' onClick={openAddAtendimento}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Adicionar Paciente
      </button>
    </div>
  )


}

export { AtendimentoMedicoListToolbar }
