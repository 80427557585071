import { KTCard } from "../../../../../_metronic/helpers"
import { EmpresasClienteListHeader } from "./component/header/EmpresasClienteListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { EmpresaClienteEditModal } from "./empresa-cliente-edit-modal/EmpresaClienteEditModal"
import { EmpresasClienteTable } from "./table/EmpresasClienteTable"


const EmpresasClienteList = () => {
    const {itemIdForUpdate} = useListView()
    return (
      <>
        <KTCard>
          <EmpresasClienteListHeader />
          <EmpresasClienteTable />
        </KTCard>
        {itemIdForUpdate !== undefined && <EmpresaClienteEditModal />} 
      </>
    )
  }
  
  const EmpresasClienteListWrapper = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <EmpresasClienteList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
  
  export {EmpresasClienteListWrapper}