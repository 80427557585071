import { KTCard } from "../../../../../_metronic/helpers"
import { ExameClienteListHeader } from "./component/header/ExameClienteListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { ExameClienteEditModal } from "./exame-edit-modal/ExameClienteEditModal"
import { ExameClienteTable } from "./table/ExameClienteTable"


const ExameClienteList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <ExameClienteListHeader />
        <ExameClienteTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ExameClienteEditModal />}
    </>
  )
}

const ExameClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ExameClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ExameClienteListWrapper }