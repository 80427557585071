import React, {ReactNode, useEffect, useState} from 'react'
import {FormControl} from 'react-bootstrap'

interface InputProps {
  type?: string
  name?: string
  placeholder?: string
  value?: string | number
  required?: boolean
  className?: string
  disabled?: boolean
  onChange?: (value: string) => void
  onBlur?: () => void
}

export const Input: React.FC<InputProps> = ({
  type,
  name,
  placeholder,
  value: initialValue,
  required,
  className,
  disabled,
  onChange,
  onBlur,
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => setValue(initialValue), [initialValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (onChange) onChange(e.target.value)
  }

  return (
    <div className='d-flex flex-column w-100 mb-4'>
      <label className={`form-label fw-bold ${required ? 'required' : ''}`}>{name}</label>
      {/*   <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        required={required}
        className={className}
        disabled={disabled}
        onChange={handleChange}
        data-kt-user-table-filter={name}
      /> */}
      <FormControl
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        required={required}
        className={className}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  name: '',
  placeholder: 'Preencha o campo',
  value: '',
  required: false,
  className: '',
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
}
