import { KTCard } from "../../../../../_metronic/helpers";
import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { DownloadAsoTable } from "./table/DownloadAsoTable";
import { DownloadAsoListHeader } from "./component/header/DownloadAsoListHeader";

interface DownloadAsoListProps {

}

const DownloadAsoList: React.FC<DownloadAsoListProps> = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <KTCard>
        <DownloadAsoListHeader />
        <DownloadAsoTable />
      </KTCard>
    </>
  );
};

interface DownloadAsoListWrapperProps {
}

const DownloadAsoListWrapper: React.FC<DownloadAsoListWrapperProps> = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DownloadAsoList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { DownloadAsoListWrapper };
