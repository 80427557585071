import { useState, useEffect } from 'react'
import { initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'
import ReactSelect from 'react-select'
import { useAuth } from '../../../../../auth'
import EspecialidadeEditModalForm from '../../../especialidade-edit-modal-form/EspecialidadeEditModalForm'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { AtendimentoMedicoSelectionHeader } from '../../../../atendimento_medicos/atendimentos-medico-list/table/columns/AtendimentoMedicoSelectionHeader'
import { TipoAtendimento } from '../../../../atendimento/core/_models'
import { getTiposAtendimentos } from '../../core/_requests'

type tiposAtendimentoOptions = {
  value: string,
  label: string
}

const EspecialidadeListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const { currentUser } = useAuth()
  const [tipoAtendimento, setTipoAtendimento] = useState<string>()
  const [nome, setNome] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [options, setOptions] = useState<tiposAtendimentoOptions[]>([]) // Estado para armazenar as opções
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const handleChangeNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNome(e.target.value)
    let search = ''
    if (e.target.value) search += (search ? ',' : '') + 'nome~*' + e.target.value + '*'
  
    setSearchTerm(search)
  }

  const handleTiposAtendimentos = async (): Promise<tiposAtendimentoOptions[]> => {
    try {
      const tiposAtendimentos = await getTiposAtendimentos()
  
      const tiposAtendimentoOptions: tiposAtendimentoOptions[] = tiposAtendimentos.map((tipo) => ({
        value: tipo.name,
        label: tipo.descricao,
      }))
  
      return tiposAtendimentoOptions
    } catch (error) {
      console.error('Erro ao obter os tipos de atendimentos:', error)
      return []
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      const fetchedOptions = await handleTiposAtendimentos()
      setOptions(fetchedOptions)
    }
    
    fetchOptions()
  }, [])

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ filter: [], search: searchTerm, sort: 'nome', order: 'asc', ...initialQueryState, tipo: tipoAtendimento })
    }
  }, [searchTerm, debouncedSearchTerm, tipoAtendimento])

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  return (
    <div className="d-flex align-items-center justify-content-center w-100 col-md-12">
      <div className="d-flex align-items-center col-md-10">
        <div className="mb-5 me-2 col-5 col-md-4">
          <label className="form-label fw-bold ps-1">Nome</label>
          <input
            type="text"
            data-kt-user-table-filter="nome"
            className="form-control form-control-solid w-100"
            placeholder="Digite o nome"
            onChange={handleChangeNome}
          />
        </div>
        <div className="mb-5 me-2 col-md-4">
          <label className="form-label fs-6 fw-bolder mb-1 ps-3">Tipo atendimento</label>
          <ReactSelect
            placeholder="Tipo de atendimento"
            onChange={(selectedOption) => {
              setTipoAtendimento(selectedOption?.value as string)
            }}
            options={options}
            className="form-control form-control-solid fs-7 w-200px"
          />
        </div>
      </div>
      <div className="ms-auto">
        <button className="btn btn-primary" onClick={handleShowModal}>Nova Especialidade</button>
      </div>
      <EspecialidadeEditModalForm
        show={showModal}
        onHide={handleCloseModal}
      />
    </div>
  )
}

export { EspecialidadeListSearchComponent }
