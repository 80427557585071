import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getSetorClienteById } from '../../empresas-list/core/_requests'
import { SetorClienteEditModalForm } from './SetorClienteEditModalForm'


const SetorClienteEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: setor,
    error,
  } = useQuery(
    `${QUERIES.EMPRESAS_LIST}-empresa-${itemIdForUpdate}`,
    () => {
      return getSetorClienteById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <SetorClienteEditModalForm isEmpresaLoading={isLoading} setor={{ id: undefined }} />
  }

  if (!isLoading && !error && setor) {
    return <SetorClienteEditModalForm isEmpresaLoading={isLoading} setor={setor} />
  }

  return null
}

export { SetorClienteEditModalFormWrapper }
