import { KTCard } from "../../../../../_metronic/helpers"
import { RiscoClienteListHeader } from "./component/header/RiscoClienteListHeader"
import { ListViewProvider, useListView } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { RiscoClienteEditModal } from "./risco-edit-modal/RiscoClienteEditModal"
import { RiscoClienteTable } from "./table/RiscoClienteTable"


const RiscoClienteList = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard>
        <RiscoClienteListHeader />
        <RiscoClienteTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <RiscoClienteEditModal />}
    </>
  )
}

const RiscoClienteListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RiscoClienteList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { RiscoClienteListWrapper }