/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { confirmAccount, getAvatarUser, getLoggedUser, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { useThemeMode } from '../../../../_metronic/partials'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato inválido')
    .min(3, 'E-mail deve possuir no mínimo 3 caracteres')
    .max(50, 'E-mail deve possuir no máximo 50 caracteres')
    .required('E-mail é obrigatório'),
  password: Yup.string()
    .min(3, 'Senha deve possuir no mínimo 3 caracteres')
    .max(50, 'Senha deve possuir no máximo 50 caracteres')
    .required('Senha é obrigatória'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const { mode } = useThemeMode()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [msg, setMsg] = useState('')
  const { saveAuth, setCurrentUser } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams();
  const alterSenha = String(searchParams.get("alter"))
  const token = String(searchParams.get("token"))
  const [confirm, setConfirm] = useState(false)
  const navigate = useNavigate();
  console.log(mode);


  if (token !== "null") {
    confirmAccount(token).then((v) => {
      setConfirm(true)
    })
      .catch((error) => {
        setHasErrors(true)
        setMsg(error.response.data.data.message)
      }).finally(() => {
        navigate('/reset-password?token=' + token)
      })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      await login(values.email, values.password).then((auth) => {
        saveAuth(auth.data)
      }).catch((error) => {
        console.error(error)
        saveAuth(undefined)
        setStatus('Login/Senha inválidos')
        setSubmitting(false)
        setLoading(false)
      })
      await getLoggedUser().then((user) => {
        getAvatarUser()
          .then((v) => {
            if (v.data !== null) {
              user.data.imagemPerfil = v.data.data
            } else {
              user.data.imagemPerfil = '/media/avatars/AVATARES_05.png'
            }
            setCurrentUser(user.data)
          }).catch(() => {
            user.data.imagemPerfil = '/media/avatars/AVATARES_05.png'
            setCurrentUser(user.data)
          })
      }).catch((error) => {
        console.error(error)
        saveAuth(undefined)
        setStatus('Login/Senha inválidos')
        setSubmitting(false)
        setLoading(false)
      })
    },
  })

  return (
    <>
      {/* <div className="d-flex flex-stack py-2">
        <div className="me-2"></div>
          <div className="m-0">
            <span className="text-gray-400 fw-bold fs-5 me-2" data-kt-translate="sign-in-head-desc">Não possui cadastro?</span>
            <Link 
              to="/auth/registration"
              className="link-primary fw-bold fs-5" 
              data-kt-translate="sign-in-head-link"
              >
              Cadastre-se
            </Link>
          </div>
      </div>  */}
      <div className="d-flex flex-column flex-column-fluid flex-center py-20">
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >

          <div className="card-body">
            <div className="text-start mb-10">
              <h1 className="text-dark mb-3 fs-2x" data-kt-translate="sign-in-title">Bem vindo a Lauduz</h1>
              <div className="text-gray-600 fw-semibold fs-3" data-kt-translate="general-desc">Entrar</div>
            </div>
            {alterSenha === 'true' && (
              <div className='mb-lg-15 alert alert-success'>
                <div className='text-success font-weight-bold'>
                  Senha Alterada com sucesso
                </div>
              </div>
            )}
            {confirm && (
              <div className='mb-lg-15 alert alert-success'>
                <div className='text-success font-weight-bold'>
                  Conta confirmada
                </div>
              </div>
            )}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {msg}
                </div>
              </div>
            )}
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : ''}

            <div className="fv-row mb-8">
              <input
                type="text"
                placeholder="E-mail"
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-solid',
                  {
                    'is-invalid': formik.touched.email && formik.errors.email
                  },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                data-kt-translate="sign-in-input-email"
                name="email"
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="fv-row mb-7 position-relative">
        <input
          type={showPassword ? 'text' : 'password'}
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
          )}
          placeholder="Senha"
          name="password"
          data-kt-translate="sign-in-input-password"
        />
        <button
          type="button"
          className="btn btn-icon position-absolute end-0 top-50 translate-middle-y me-3"
          onClick={() => setShowPassword(prev => !prev)}
          aria-label={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
        >
          <i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
        </button>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
            <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
              <div></div>
              <Link
                to='/auth/forgot-password'
                className='link fs-6 fw-bolder'
                style={{
                  marginLeft: '5px',
                  color: mode === 'light' ? '#371E56' : 'white',
                }}
              >
                Esqueceu sua senha?
              </Link>
            </div>
            <div className="d-flex flex-stack mb-10">
              <button
                id="kt_sign_in_submit"
                className="btn btn-primary me-2 flex-shrink-0"
                disabled={formik.isSubmitting || !formik.isValid}
                type="submit"
              >
                {!loading && <span className='indicator-label'>Continuar</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Aguarde...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {/* Google + Apple de inicio não teremos 
              <div className="d-flex align-items-center">
                <div className="text-gray-400 fw-semibold fs-6 me-3 me-md-6" data-kt-translate="general-or">ou</div>
                <a href="#" className="symbol symbol-circle symbol-45px w-45px bg-light me-3">
                  <img alt="Logo" src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')} className="p-4" />
                </a>
                <a href="#" className="symbol symbol-circle symbol-45px w-45px bg-light">
                  <img alt="Logo" src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')} className="theme-light-show p-4" />
                  <img alt="Logo" src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')} className="theme-dark-show p-4" />
                </a>
              </div>
              */}
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
