import { AxiosError } from 'axios';
import { ReactNode, useState } from 'react'; // Importe ReactNode se você estiver usando React version >= 17
import { Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AtendimentoIniciado } from '../../../../atendimento/core/_models';
import { reabrirAtendimentoById } from '../../../../atendimento/core/_requests';
import { useQueryResponse } from '../../core/QueryResponseProvider';

interface ReabrirAtendimentoModalProps {
  atendimento: AtendimentoIniciado;
  showReabrirAtendimento: boolean;
  onClose: () => void;
}

const ReabrirAtendimentoModal: React.FC<ReabrirAtendimentoModalProps> = ({ atendimento, showReabrirAtendimento, onClose }) => {
  const [observacao, setObservacao] = useState('');
  const navigate = useNavigate();
  const { refetch } = useQueryResponse()

  const handleCancelAtendimento = async () => {
    if (!observacao) {
      Swal.fire({
        text: 'A observação é obrigatório.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });
      return;
    }

    reabrirAtendimentoById(atendimento.id, observacao)
      .then(() => {
        refetch()
        Swal.fire({
          text: `Atendimento reaberto`,
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
        onClose();
        navigate('/apps/atendimentosMedicos/lista');
      })
      .catch((error) => {
        const err = error as AxiosError;
        Swal.fire({
          text: `${err.response?.data.data.message}`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        });
      });
  };

  return (
    <Modal className="modal-md" show={showReabrirAtendimento} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reabrir o atendimento de {atendimento?.paciente?.nome}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="observacao">
          <Form.Label>Observação</Form.Label>
          <Form.Control
            type="text"
            placeholder="Digite uma observação"
            value={observacao}
            required={true}
            className={observacao ? '' : 'is-invalid'}
            onChange={(e) => setObservacao(e.target.value)}
          />
        </Form.Group>

        <div className="d-flex justify-content-end mt-6">
          <Button variant="secondary" onClick={onClose} className="me-3">
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleCancelAtendimento}>
            Confirmar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { ReabrirAtendimentoModal };
