import axios, { AxiosResponse } from 'axios'
import { AuthModel } from './_models';
import { AtendimentoIniciado } from '../../apps/atendimento/core/_models';


const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/auth/token`
const ATENDIMENTO_URL = `${API_URL}/public/paciente/atendimento`
const TERMS_URL = `${API_URL}/public/paciente/termos`


export function login(cpf: string, protocolo: string): Promise<AuthModel> {
  return axios.post<AuthModel>(LOGIN_URL, {
    cpf,
    protocolo,
  },).then((retorno: AxiosResponse<AuthModel>) => {
    return retorno.data;
  });
}

export function acceptTerms(protocolo: string): Promise<void> {
  const pacienteToken = localStorage.getItem('pacienteToken'); 

  if (!pacienteToken) {
    return Promise.reject("Token de paciente não encontrado");
  }

  const headers = {
    Authorization: `Bearer ${pacienteToken}`
  };

  return axios
    .patch(`${TERMS_URL}/${protocolo}`, {}, { headers })
    .then((response: AxiosResponse<AtendimentoIniciado>) => response.data)
    .catch((error) => {
     return error 
    });
}

export const getAtendimentoByProtocolo = (protocolo: string): Promise<AtendimentoIniciado | undefined> => {
  const pacienteToken = localStorage.getItem('pacienteToken'); 

  if (!pacienteToken) {
    return Promise.reject("Token de paciente não encontrado");
  }

  const headers = {
    Authorization: `Bearer ${pacienteToken}`
  };

  return axios
    .get(`${ATENDIMENTO_URL}/${protocolo}`, { headers })
    .then((response: AxiosResponse<AtendimentoIniciado>) => response.data)
    .catch((error) => {
      console.error('Erro ao buscar atendimento:', error);
      if (error.response && error.response.status === 403) {
        return Promise.reject(error);
      } else {
        return undefined;
      }
    });
}