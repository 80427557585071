import { useLayout } from '../../core'
import { DefaultTitle } from './page-title/DefaultTitle'
import { ThemeModeSwitcher } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'
import { useMediaQuery } from 'react-responsive'

const HeaderToolbar = () => {
  const { classes } = useLayout()
  const { logout } = useAuth()

  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className='toolbar d-flex align-items-center'>
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-center pt-3 pt-lg-0'>
          <div className='d-flex align-items-center'>
            <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
          </div>
          {!isMobile && (
            <div className='ms-3'>
              <button
                className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'
                onClick={logout}
              >
                <span className='menu-icon d-flex align-items-center' data-kt-element='icon'>
                  <span className='me-2'>Sair</span>
                  <i className='bi bi-box-arrow-right'></i>
                </span>
              </button>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}

export { HeaderToolbar }
