import { FC, useEffect, useState } from "react"
import * as Yup from 'yup'
import { useFormik } from "formik"
import Swal from "sweetalert2"
import clsx from "clsx"
import Select from 'react-select';
import { ID, KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers"
import { adicionaFatorRisco, alteraAtendimentoASO, alteraEmpresaClienteAtendimento, getTiposFatorASO } from "../atendimento/core/_requests"
import { AtendimentoIniciado } from "../atendimento/core/_models"
import { EditEmpresaClienteAso, createEmpresaClienteAso, getCargosCliente, getEmpresaClienteAso, getRiscosCliente, getSetoresCliente, vincularRiscosClienteAtendimento } from "../empresa/empresas-list/core/_requests"
import { IMaskInput } from "react-imask"
import { AxiosError } from "axios"
import { EmpresaCliente } from "../empresa/empresas-list/core/_models"

type Props = {
    atendimento: AtendimentoIniciado | undefined
    defineAtendimento: Function
}
const INITIAL_DATA = {
    value: undefined,
    label: 'Selecione um valor',
};
const ClienteAso: FC<Props> = ({ atendimento, defineAtendimento }) => {
    const [selectData, setselectData] = useState(INITIAL_DATA)
    const [selectDataCargo, setselectDataCargo] = useState(INITIAL_DATA)
    const [selectDataSetor, setselectDataSetor] = useState(INITIAL_DATA)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<any>([]);
    const [optionsCargos, setOptionsCargos] = useState<any>([])
    const [optionsSetores, setOptionsSetores] = useState<any>([])
    const [clienteEdit, setClienteEdit] = useState<EmpresaCliente>()
    const [tipoDocumento, setTipoDocumento] = useState<string>(clienteEdit?.tipoDocumento || 'CNPJ')
    const [idempresaCliente, setIdEmpresaCliente] = useState<ID | undefined>(undefined)
    const [showCadastroCliente, setshowCadastroCliente] = useState(false);

    const handleShowCadastroCliente = () => {
        setClienteEdit(undefined)
        setshowCadastroCliente(!showCadastroCliente)
    };
    console.log(atendimento)
    const handleEditCadastroCliente = () => {
        if (idempresaCliente === undefined) {
            Swal.fire({
                text: 'Selecione uma empresa para edição',
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            })
        } else {
            getEmpresaClienteAso(atendimento?.empresa?.id, "page=0&size=100").then((data) => {
                data.data?.map(cliente => {
                    if (cliente.id === idempresaCliente) {
                        setClienteEdit(cliente)
                        formik.setFieldValue('nome', String(cliente?.nome))
                        formik.setFieldValue('cnpj', String(cliente?.cnpj))
                        setshowCadastroCliente(true)
                    }
                })
            })
        }
    };
    const clienteASoSchema = Yup.object().shape({
        tipo: Yup.string().required('Tipo de documento é obrigatório'),
        cnpj: Yup
            .string()
            .nullable()
            .when([], {
                is: () => tipoDocumento === 'CNPJ',
                then: Yup.string().required('O campo CNPJ obrigatório'),
                otherwise: Yup.string().notRequired()
            }),
        cpf: Yup
            .string()
            .nullable()
            .when([], {
                is: () => tipoDocumento === 'CPF',
                then: Yup.string().required('O campo CPF obrigatório'),
                otherwise: Yup.string().notRequired()
            }),
        caepf: Yup
            .string()
            .nullable()
            .when([], {
                is: () => tipoDocumento === 'CAEPF',
                then: Yup.string().required('O campo CAEPF obrigatório'),
                otherwise: Yup.string().notRequired()
            }),
        nome: Yup.string().required('Nome é obrigatório'),
    })
    const clienteAsoForEdit = {
        nome: clienteEdit?.nome || '',
        cnpj: clienteEdit?.tipoDocumento === 'CNPJ' ? clienteEdit?.documento : '',
        cpf: clienteEdit?.tipoDocumento === 'CPF' ? clienteEdit?.documento : '',
        caepf: clienteEdit?.tipoDocumento === 'CAEPF' ? clienteEdit?.documento : '',
        tipo: tipoDocumento,
    }
    const defineEmpresaCliente = (idempresaCliente: ID) => {
        setIdEmpresaCliente(idempresaCliente)
    }
    const resetDadosAtendimento = () => {
        setselectData(INITIAL_DATA)
        setselectDataCargo(INITIAL_DATA)
        setselectDataSetor(INITIAL_DATA)

        Swal.fire({
            text: 'Empregador ASO adicionada com sucesso ao atendimento!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
                confirmButton: "btn btn-primary"
            }
        }).then(() => {
            formik.resetForm()
            getOptionsEmpresas()
            defineAtendimento()
            // setIdEmpresaCliente(empresaCliente?.id)
        })
    }
    const vincularEmpresaCliente = () => {
        if (idempresaCliente !== undefined) {
            if (selectDataCargo.value && selectDataSetor.value) {
                //Altera ou adiciona uma Empregador ASO já cadastrada ao atendimento
                alteraEmpresaClienteAtendimento(atendimento?.id, idempresaCliente).then((empresaCliente) => {
                    let matricula = atendimento?.dadosAso?.matricula !== undefined ? atendimento?.dadosAso?.matricula : ''
                    alteraAtendimentoASO(atendimento?.id, matricula, selectDataCargo.label, selectDataSetor.label, selectDataCargo.value, selectDataSetor.value).then((at) => {
                        resetDadosAtendimento()
                    }).catch((error) => {
                        const err = error as AxiosError;
                        Swal.fire({
                            text: 'Erro ao ao víncular! ' + err.response?.data.data.message,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    })

                }).catch((error) => {
                    const err = error as AxiosError;
                    Swal.fire({
                        text: 'Erro ao ao víncular! ' + err.response?.data.data.message,
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: "Ok",
                        customClass: {
                            confirmButton: "btn btn-primary"
                        }
                    })
                })
            } else {
                Swal.fire({
                    text: 'Erro ao ao víncular! Selecione um setor e um cargo. ',
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            }
        } else {
            Swal.fire({
                text: 'Erro ao ao víncular! Selecione uma empresa. ',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            })
        }
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: clienteAsoForEdit,
        // validationSchema: clienteASoSchema,
        onSubmit: async (values, { setSubmitting, setFieldError, validateForm }) => {
            setSubmitting(true)
            setLoading(true)
            if (clienteEdit !== undefined) {
                EditEmpresaClienteAso(clienteEdit.id, values.nome, String(values.cnpj), values.tipo, String(values.cpf), String(values.caepf))
                    .then((empresaCliente) => {
                        setClienteEdit(undefined)
                        setSubmitting(false)
                        setLoading(false)
                        setIdEmpresaCliente(empresaCliente?.id)
                        // vincularEmpresaCliente()
                        defineAtendimento()
                        setshowCadastroCliente(false)
                        Swal.fire({
                            text: 'Empregador ASO alterada com sucesso!',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        }).then(() => {
                            formik.resetForm()
                            getOptionsEmpresas()
                        })
                    }).catch((error) => {
                        setSubmitting(false)
                        setLoading(false)
                        const errors = error.response.data.data.errors
                        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                        setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                        const err = error as AxiosError;
                        Swal.fire({
                            text: 'Erro ao cadastrar! ',
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    }).finally(() => {
                        setSubmitting(false)
                        setLoading(false)
                    })
            } else {
                //cadastra nova Empregador ASO
                createEmpresaClienteAso(atendimento?.empresa?.id, values.nome, String(values.cnpj), values.tipo, String(values.cpf), String(values.caepf))
                    .then((empresaCliente) => {
                        setIdEmpresaCliente(empresaCliente?.id)
                        vincularEmpresaCliente()
                        defineAtendimento()
                        handleShowCadastroCliente()
                        Swal.fire({
                            text: 'Empregador ASO adicionada com sucesso!',
                            icon: 'success',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                            .then(() => {
                                formik.resetForm()
                                getOptionsEmpresas()
                            })
                    }).catch((error) => {
                        const errors = error.response.data.data.errors
                        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                        setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
                        const err = error as AxiosError;
                        Swal.fire({
                            text: 'Erro ao cadastrar! ',
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    }).finally(() => {
                        setselectData(INITIAL_DATA)
                        setSubmitting(false)
                        setLoading(false)
                    })
            }
        },
    })
    //Carrega impresas clientes registradas
    function getOptionsEmpresas() {
        getEmpresaClienteAso(atendimento?.empresa?.id, "page=0&size=100").then((data) => {
            const results: any = []
            if (data !== undefined) {
                data.data?.forEach((value) => {
                    results.push({
                        label: value.nome,
                        value: value.id
                    });
                });
                setOptions([
                    ...results
                ])
            }
        }).catch((error) => {
            console.error(error)
        })

    }
    function getOptionsCargos(idCliente: ID) {
        getCargosCliente(atendimento?.empresa?.id, "page=0&size=100&search=cliente:" + idCliente).then((data) => {
            const results: any = []
            if (data !== undefined) {
                data.data?.forEach((value) => {
                    results.push({
                        label: value.nome,
                        value: value.id
                    });
                });
                setOptionsCargos([
                    ...results
                ])
            }
        }).catch((error) => {
            console.error(error)
        })

    }
    function getOptionsSetores(idCliente: ID) {
        getSetoresCliente(atendimento?.empresa?.id, "page=0&size=100&search=cliente:" + idCliente).then((data) => {
            const results: any = []
            if (data !== undefined) {
                data.data?.forEach((value) => {
                    results.push({
                        label: value.nome,
                        value: value.id
                    });
                });
                setOptionsSetores([
                    ...results
                ])
            }
        }).catch((error) => {
            console.error(error)
        })

    }
    const handleChangeCliente = (e: any) => {
        //Definir o campo de todos os inputs para filtro
        setselectData(e)
        setIdEmpresaCliente(e.value)
        setselectDataCargo(INITIAL_DATA)
        setselectDataSetor(INITIAL_DATA)
        getOptionsCargos(e.value)
        getOptionsSetores(e.value)
    }
    const handleChangeSetor = (e: any) => {
        //Definir o campo de todos os inputs para filtro
        setselectDataSetor(e)
    }
    const handleChangeCargo = (e: any) => {
        //Definir o campo de todos os inputs para filtro
        setselectDataCargo(e)
    }
    useEffect(() => {
        getOptionsEmpresas()
    },
        [atendimento] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )
    return (
        <>
            {/* begin::Scroll */}
            <div
                className='d-flex flex-column me-n7 pe-7'
                id='kt_modal_add_paciente_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
                data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
            // data-kt-scroll-offset='300px'
            >
                {/* begin::Input group */}

                <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Empregador ASO</label>
                </div>
                {atendimento?.cliente !== null ? (
                    <div className='card-body'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table align-middle gs-0'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr>
                                        <th className='p-0 w-50px'></th>
                                        <th className='p-0 min-w-100px'></th>
                                        <th className='p-0 min-w-200px'></th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <th>
                                            <div className='symbol symbol-50px'>
                                                <span className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl('/media/empresa/predio.png')}
                                                        className='h-50 align-self-center'
                                                        alt=''
                                                    />
                                                </span>
                                            </div>
                                        </th>
                                        <td>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <span className='text-bold me-2 fs-7 fw-semibold'>{atendimento?.cliente?.tipoDocumento}</span>
                                                </div>
                                                <div className=' w-100'>
                                                    <span className='text-muted me-2 fs-7 fw-semibold'>{atendimento?.cliente?.documento}</span>
                                                </div>
                                            </div>
                                            {/* <span className='text-muted fw-semibold d-block fs-7'>{p.data}</span> */}
                                        </td>
                                        <td>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <span className='text-bold me-2 fs-7 fw-semibold'>Nome</span>
                                                </div>
                                                <div className=' w-100'>
                                                    <span className='text-muted me-2 fs-7 fw-semibold'>{atendimento?.cliente?.nome}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>




                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                ) : (<></>)}
                {showCadastroCliente ? (
                    <>
                        <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Nome</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <input
                                    placeholder='Nome'
                                    {...formik.getFieldProps('nome')}
                                    type='text'
                                    name='nome'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        { 'is-invalid': formik.touched.nome && formik.errors.nome },
                                        {
                                            'is-valid': formik.touched.nome && !formik.errors.nome,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || loading}
                                />
                                {formik.touched.nome && formik.errors.nome && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.nome}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            {/* end::Input group */}

                            {/* end::Input group */}

                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                <div className="mb-3">
                                    <label className="required fw-bold fs-6 mb-2 me-4">Tipo</label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input form-check-input-sm"
                                            type="radio"
                                            id="tipoCPF"
                                            value="CPF"
                                            checked={tipoDocumento === 'CPF'}
                                            onChange={(e) => {
                                                setTipoDocumento(e.target.value)
                                                formik.setFieldValue('tipo', e.target.value)
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="tipoCPF">CPF</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input form-check-input-sm"
                                            type="radio"
                                            id="tipoCNPJ"
                                            value="CNPJ"
                                            checked={tipoDocumento === 'CNPJ'}
                                            onChange={(e) => {
                                                setTipoDocumento(e.target.value)
                                                formik.setFieldValue('tipo', e.target.value)
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="tipoCNPJ">CNPJ</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input form-check-input-sm"
                                            type="radio"
                                            id="tipoCAEPF"
                                            value="CAEPF"
                                            checked={tipoDocumento === 'CAEPF'}
                                            onChange={(e) => {
                                                setTipoDocumento(e.target.value)
                                                formik.setFieldValue('tipo', e.target.value)
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="tipoCAEPF">CAEPF</label>
                                    </div>
                                </div>
                            </div>

                            <div className='fv-row mb-7'>
                                {tipoDocumento === 'CPF' && (
                                    <>
                                        <label className='required fw-bold fs-6 mb-2'>CPF</label>
                                        <IMaskInput
                                            placeholder='Digite o CPF'
                                            {...formik.getFieldProps('cpf')}
                                            mask="000.000.000-00"
                                            type='text'
                                            name='cpf'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
                                                { 'is-valid': formik.touched.cpf && !formik.errors.cpf }
                                            )}
                                            disabled={formik.isSubmitting || loading}
                                        />
                                        {formik.touched.cpf && formik.errors.cpf && tipoDocumento === 'CPF' && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.cpf}</span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                {tipoDocumento === 'CNPJ' && (
                                    <>
                                        <label className='required fw-bold fs-6 mb-2'>CNPJ</label>
                                        <IMaskInput
                                            placeholder='Digite o CNPJ'
                                            {...formik.getFieldProps('cnpj')}
                                            mask="00.000.000/0000-00"
                                            type='text'
                                            name='cnpj'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                                                { 'is-valid': formik.touched.cnpj && !formik.errors.cnpj }
                                            )}
                                            disabled={formik.isSubmitting || loading}
                                        />
                                        {formik.touched.cnpj && formik.errors.cnpj && tipoDocumento === 'CNPJ' && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.cnpj}</span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                                {tipoDocumento === 'CAEPF' && (
                                    <>
                                        <label className='required fw-bold fs-6 mb-2'>CAEPF</label>
                                        <IMaskInput
                                            placeholder='Digite o CAEPF'
                                            {...formik.getFieldProps('caepf')}
                                            mask="000.000.000/000-00"
                                            type='text'
                                            name='caepf'
                                            className={clsx(
                                                'form-control form-control-solid mb-3 mb-lg-0',
                                                { 'is-invalid': formik.touched.caepf && formik.errors.caepf },
                                                { 'is-valid': formik.touched.caepf && !formik.errors.caepf }
                                            )}
                                            disabled={formik.isSubmitting || loading}
                                        />
                                        {formik.touched.caepf && formik.errors.caepf && tipoDocumento === 'CAEPF' && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.caepf}</span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='card-footer d-flex justify-content-end px-8'>

                                <button
                                    type='reset'
                                    //className='btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-2 rounded-bottom-2 me-2 '
                                    className='btn btn-light me-3'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    onClick={handleShowCadastroCliente}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    <span className='indicator-label'>Enviar</span>
                                    {(formik.isSubmitting || loading) && (
                                        <span className='indicator-progress'>
                                            Aguarde...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </>
                ) : (<>
                    <div className='card-title'></div>

                    <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Empregador ASO</label>
                        <Select
                            placeholder='Selecione uma empresa'
                            onChange={handleChangeCliente}
                            options={options}
                            className='form-control form-control-solid'
                            // defaultValue={{ label: "Selecione um valor", value: "0" }}
                            value={selectData}

                        />
                    </div>
                    <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>Setor</label>
                        <Select
                            placeholder='Selecione um setor'
                            onChange={handleChangeSetor}
                            options={optionsSetores}
                            className='form-control form-control-solid'
                            // defaultValue={{ label: "Selecione um valor", value: "0" }}
                            value={selectDataSetor}

                        />
                    </div>
                    <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-'>Cargo</label>

                        <Select
                            placeholder='Selecione um cargo'
                            onChange={handleChangeCargo}
                            options={optionsCargos}
                            className='form-control form-control-solid'
                            // defaultValue={{ label: "Selecione um valor", value: "0" }}
                            value={selectDataCargo}

                        />
                    </div>

                    {/* end::Input */}


                    <div className='card-footer d-flex justify-content-end px-8'>
                        <button
                            className='btn btn-info active m-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            onClick={handleShowCadastroCliente}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                            Cadastrar Novo Empregador ASO
                        </button>
                        <button
                            className='btn btn-secondary m-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            onClick={handleEditCadastroCliente}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2' />
                            Editar Empregador ASO
                        </button>
                        <button
                            className='btn btn-primary m-1'
                            data-kt-users-modal-action='submit'
                            disabled={loading || formik.isSubmitting}
                            onClick={vincularEmpresaCliente}
                        >
                            <span className='indicator-label'>Vincular</span>
                            {(formik.isSubmitting || loading) && (
                            <span className='indicator-progress'>
                                Aguarde...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>

                </>)}
            </div>
        </>
    )
}

export { ClienteAso }
