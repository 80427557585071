import { Dispatch, SetStateAction } from 'react'
import { AtendimentoIniciado, AtendimentoQueryResponse } from '../../../app/modules/apps/atendimento/core/_models'
export type ID = undefined | null | number
export type PaginationState = {
  page: number
  size: 10 | 30 | 50 | 100
  totalElements: number
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
}
export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}
export type FilterState = {
  filter?: unknown
}
export type TipoAtendimentoState = {
  tipo?: string
}
export type SearchState = {
  search?: string
}
export type Response<T> = {
  data: T | undefined
  page: number
  size: 10 | 30 | 50 | 100
  totalElements: number
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}
export type QueryState = PaginationState & SortState & FilterState & SearchState
export type QueryStateEspecialidade = PaginationState & SortState & FilterState & SearchState & TipoAtendimentoState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export type QueryRequestContextPropsEspecilidade = {
  state: QueryStateEspecialidade
  updateState: (updates: Partial<QueryStateEspecialidade>) => void
}
export const initialQueryState: QueryState = {
  page: 0,
  size: 10,
  totalElements: 0
}
export const initialQueryStateEspecialidade: QueryStateEspecialidade = {
  page: 0,
  size: 10,
  totalElements: 0,
}
export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}
export const initialQueryRequestEspecialidade: QueryRequestContextPropsEspecilidade = {
  state: initialQueryState,
  updateState: () => { },
}
export type QueryResponseContextProps<T> = {
  responseSemPaginacao?: T
  response?: Response<Array<T>>
  refetch: () => void
  isLoading: boolean
  query: string
  idEmpresa: string
  idPaciente: string
  atendimentosFinalizados?: AtendimentoQueryResponse | AtendimentoIniciado[] | undefined
}
export type QueryResponseContextPropsSemPaginacao<T> = {
  response?: Array<T>
  refetch: () => void
  isLoading: boolean
  query: string
  idEmpresa: string
}
export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '', idEmpresa: '', idPaciente: '' }
export type ListViewContextProps = {

  isAllSelected?: boolean;
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  idEmpresa?: ID
  idPaciente?: ID
  setIdEmpresa: Dispatch<SetStateAction<ID>>
  setIdPaciente: Dispatch<SetStateAction<ID>>
  disabled?: boolean
  atendimentosFinalizados?: AtendimentoQueryResponse | AtendimentoIniciado[] | undefined
  onSelectAll?: () => void
}
export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setIdEmpresa: () => { },
  setIdPaciente: () => { },
  disabled: false,
  isAllSelected: false,
  onSelectAll: () => { }

}
