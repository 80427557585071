import { useNavigate, useParams } from "react-router-dom";
import { getAtendimentoByProtocolo } from "../core/_requests";
import { useEffect, useState } from "react";
import { AtendimentoIniciado } from "../../apps/atendimento/core/_models";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";

export function Atendimento() {
  const { protocolo } = useParams();
  const navigate = useNavigate();
  const [atendimento, setAtendimento] = useState<AtendimentoIniciado | null>(
    null
  );


  const defineAtendimento = async () => {
    await getAtendimentoByProtocolo(protocolo as string) 
    .then((a) => {
      setAtendimento(a as AtendimentoIniciado)
    })
    .catch((error) => {
      Swal.fire({
        text: "Acesso não autorizado",
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
      localStorage.removeItem('pacienteToken');
      localStorage.removeItem('protocolo');
      navigate(`/tele-consulta/${protocolo}`);
      
    });
  };

  useEffect(() => {
    defineAtendimento();

    const intervalId = setInterval(() => {
      defineAtendimento();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (atendimento?.terminoFormatado) {
      localStorage.removeItem('pacienteToken'); 
      localStorage.removeItem('protocolo'); 
    }
  }, [atendimento]);



  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-600px h-500px">
     {!!atendimento?.terminoFormatado ? (
      <div className="m-15">
      <h2 className="text-center">Este atendimento foi finalizado</h2>
    </div>
     ) : (
      (atendimento && atendimento.urlSalaVideo) ? (
        <>
          <iframe
            style={{ display: "block" }}
            className="w-100 h-100 m-4"
            src={`${toAbsoluteUrl(
              atendimento.urlSalaVideo
            )}&externalId=${atendimento.paciente?.id}`}
            allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
          ></iframe>
        </>
        
      ) : (
        <div className="m-15">
          <h2 className="text-center">Aguarde o início do atendimento</h2>
        </div>
      )
     )}
    </div>
  );
}
