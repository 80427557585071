
import { useEffect, useMemo, useState } from "react"
import { ColumnInstance, Row, useTable } from "react-table"
import { KTCardBody } from "../../../../../../_metronic/helpers"
import { ListLoading } from "../../../commons/components/ListLoading"
import { CustomHeaderColumn } from "../../../commons/table/CustomHeaderColumn"
import { CustomRow } from "../../../commons/table/CustomRow"
import { calcularInformacoesAtendimentos } from "../core/atendimentos_infos"
import { useQueryResponseData, useQueryResponseLoading } from "../core/QueryResponseProvider"
import { Atendimento } from "../core/_models"
import { relatorioColumns } from "./columns/_columns"


const RelatorioTable = () => {
  const atendimentos = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => atendimentos, [atendimentos])
  const columns = useMemo(() => relatorioColumns, [])
  const [infos, setInfos] = useState<any>(undefined)
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data || [],
  })

  useEffect(() => {
    const informações = calcularInformacoesAtendimentos(atendimentos as Atendimento[])
    setInfos(informações)
  }, [atendimentos])



  return (
    <KTCardBody className='py-4' >
      <div id='relatorio'>
        <div className="row card-header mt-6 pt-3">
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Total de Atendimentos:</p>
            <p className="form-label fs-6  mb-1">{infos?.total || 0}</p>
          </div>
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Assistenciais:</p>
            <p className="form-label fs-6  mb-1">{infos?.assistencial || 0}</p>
          </div>
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Screenings:</p>
            <p className="form-label fs-6  mb-1">{infos?.screening || 0}</p>
          </div>
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Ocupacionais:</p>
            <p className="form-label fs-6  mb-1">{infos?.ocupacional || 0}</p>
          </div>
        </div>
        <div className="row card-header mt-6">
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Aguardando Atendimento:</p>
            <p className="form-label fs-6  mb-1">{infos?.aguardando || 0}</p>
          </div>
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Em Atendimento:</p>
            <p className="form-label fs-6  mb-1">{infos?.emAtendimento || 0}</p>
          </div>
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Atendimentos Finalizados:</p>
            <p className="form-label fs-6  mb-1">{infos?.finalizado || 0}</p>
          </div>
          <div className="col">
            <p className="form-label fs-6  mb-1 fw-bolder">Atendimentos Cancelados:</p>
            <p className="form-label fs-6  mb-1">{infos?.cancelado || 0}</p>
          </div>
        </div>

        <div className='table-responsive' >
          <table
            id='kt_table_empresas'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<Atendimento>) => (
                  // @ts-ignore
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Atendimento>, i) => {
                  prepareRow(row)
                  // @ts-ignore
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Nenhum registro encontrado
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export { RelatorioTable }
