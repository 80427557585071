import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'


/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const { currentUser } = useAuth()
  const imageDefault = '/media/avatars/blank.png'
  const imagePerfil = toAbsoluteUrl(String(currentUser?.imagemPerfil))
  const hasImage = currentUser?.imagemPerfil !== null

  return (
    <>
      {/*begin::User*/}
      <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
        {/*begin::Symbol*/}
        <div className='symbol symbol-50px'>
          <img src={hasImage ? imagePerfil : imageDefault} alt='Imagem do Perfil' />
        </div>
        {/*end::Symbol*/}


        {/*begin::Wrapper*/}
        <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
          {/*begin::Section*/}
          <div className='d-flex'>
            {/*begin::Info*/}
            <div className='flex-grow-1 me-2'>
              {/*begin::Username*/}
              {/*begin::User menu*/}
              <div className='me-n2'>
                <Link to='/crafted/account/overview' className='d-flex align-items-center'>
                  <span style={{ color: 'white' }} className='me-3'>
                    {currentUser?.pessoa?.nome}
                  </span>
                  <KTSVG
                    path='/media/icons/duotune/art/art005.svg'
                    className='svg-icon-muted svg-icon-12'
                  />
                </Link>
              </div>
            </div>
            {/*end::Info*/}
            {/*end::Section*/}


          </div>
          {/*end::Section*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::User*/}

      {/*begin::Aside search*/}
      {/* <div className='aside-search py-5'> */}
      {/* <?php Theme::getView('partials/search/_inline', array(
        'class' => 'w-100',
        'menu-placement' => 'bottom-start',
        'responsive' => 'false'
    ))?> */}
      {/* <Search /> */}
      {/* </div> */}
      {/*end::Aside search*/}
    </>
  )
}

export { AsideToolbar }
