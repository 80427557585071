import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useState } from 'react';
import { getAsoAutenticado } from '../../empresa/empresas-list/core/_requests';
import { AsoEmitido } from '../../atendimento/core/_models';
import Swal from 'sweetalert2';
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import clsx from "clsx";
import { Url } from "url";




const AutenticacaoASO = () => {
    const [asoAutentidado, setAsoAutentidado] = useState<AsoEmitido>()
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState('')
    const navigate = useNavigate();




    const toLoginPage = () => {
        navigate('/auth')
    }
    const resetPage = () => {
        setAsoAutentidado(undefined)
    }
    const initialValues = {
        hash: ''
    }
    const relatorioSchema = Yup.object().shape({
        hash: Yup.string()
            .min(10, 'O campo hash deve possuir no mínimo 10 caracteres')
            .max(50, 'O campo hash deve possuir no máximo 50 caracteres')
            .required('hash é obrigatório'),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: relatorioSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            getAsoAutenticado(values.hash).then((relatorio) => {
                if (relatorio !== undefined) {
                    var a = document.createElement("a"); //Create <a>
                    a.href = toAbsoluteUrl("data:application/pdf;base64," + (relatorio !== undefined ? relatorio.contentBase64 : '')); //Image Base64 Goes here
                    setUrl(a.href)
                    a.download = String(relatorio?.name); //File name Here
                    // a.click();
                }
                setAsoAutentidado(relatorio)
                // downloadPDF(String(relatorio?.contentBase64))
            }).catch((error) => {
                Swal.fire({
                    text: "Relatório não localizado",
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            }).finally(() => {
                setLoading(false)
            })
        },
    })
    return (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <a href="../../demo1/dist/index.html" className="d-block d-lg-none mx-auto py-20">
                <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-white.png')} className="theme-light-show h-50px" />
                <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-dark.png')} className="theme-dark-show h-50px" />
            </a>
            <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
                <div className=" justify-content-between flex-column-fluid flex-column w-100">
                    {/* <div className="d-flex  flex-row-auto" > */}
                    <h2 className='fw-bolder mb-5'>
                        Relatório Autentidado
                    </h2>
                    {asoAutentidado !== undefined ? (
                        <>
                            <object width="100%" height="500px" data={url + "#navpanes=0&scrollbar=0"} />
                            <div className='flex-center flex-row-fluid'>
                                <button type='reset' className='btn btn-light me-3' onClick={resetPage}>
                                    Cancelar
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <form className='form' onSubmit={formik.handleSubmit}>
                                <div className="fv-row">
                                    <input
                                        type="text"
                                        placeholder="Hash"
                                        {...formik.getFieldProps('hash')}
                                        className={clsx(
                                            'form-control form-control-solid',
                                            {
                                                'is-invalid': formik.touched.hash && formik.errors.hash
                                            },
                                            {
                                                'is-valid': formik.touched.hash && !formik.errors.hash,
                                            }
                                        )}
                                        name="hash"
                                        autoComplete="off"
                                    />
                                    {formik.touched.hash && formik.errors.hash && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.hash}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='flex-center flex-row-fluid'>
                                    <button type='reset' className='btn btn-light me-3' onClick={toLoginPage}>
                                        Cancelar
                                    </button>
                                    <button type='submit' className='btn btn-primary' disabled={loading} >
                                        {!loading && 'Enviar'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Aguarde...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </>

                    )}
                    {/* </div> */}
                </div>
            </div>
            {/* begin::Aside */}
            <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ backgroundImage: 'url("media/misc/auth-bg.png")' }}></div>
            {/* end::Aside */}
        </div>
    )

}
export { AutenticacaoASO }