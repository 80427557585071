import { FC, useEffect, useState } from "react";
import { alteraAtendimento, getAtendimentoById, getAtendimentoPacienteById } from "../../atendimento/core/_requests";

import { AtendimentoIniciado, initialAtendimento } from "../../atendimento/core/_models";
import { Funcionario } from "../../funcionarios-management/funcionarios-list/core/_models";
import Swal from "sweetalert2";
import { VideoAtendimento } from "./video-atendimento";
import { getFuncionarioLogado } from "../../funcionarios-management/funcionarios-list/core/_requests";
import { useSearchParams } from "react-router-dom";

const TeleAtendimento: FC = () => {
    const [searchParams] = useSearchParams();
    const [atendimento, setAtendimento] = useState<AtendimentoIniciado>(initialAtendimento)
    const idAtendimento = String(searchParams.get("atendimento"))
    const idAtendimentoPaciente = String(searchParams.get("atendimentoPaciente"))
    const [medico, setMedico] = useState<Funcionario | undefined>(undefined)

    useEffect(() => {
        if (idAtendimentoPaciente === 'null') {
            getFuncionarioLogado().then((result: Funcionario) => {
                setMedico(result)
            }).catch((err) => {
                Swal.fire({
                    text: err.response?.data.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            });
        }
    }, []);

    useEffect(() => {
        if (idAtendimentoPaciente !== 'null') {
            getAtendimentoPacienteById(Number(idAtendimentoPaciente)).then((atendimentoApi) => {
                if (atendimentoApi !== undefined) {
                    setAtendimento(atendimentoApi)
                }
            }).catch((error) => {
                Swal.fire({
                    text: error.response?.data.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            })
        } else {
            getAtendimentoById(Number(idAtendimento)).then((atendimentoApi) => {
                if (atendimentoApi?.estado?.id === 2) {
                    setAtendimento(atendimentoApi)
                } else {
                    alteraAtendimento(Number(idAtendimento), 2, Number(medico?.id), true, null).then((result) => {
                        if (result !== undefined) {
                            setAtendimento(result)
                        }
                    }).catch((error) => {
                        Swal.fire({
                            text: error.response?.data.data.message,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    })
                }
            }).catch((error) => {
                Swal.fire({
                    text: error.response?.data.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            })
        }

    }, []);
    return (
        <div style={{ marginTop: '-25px' }}>
            <VideoAtendimento atendimento={atendimento} />
        </div>
    )
}
export { TeleAtendimento }
