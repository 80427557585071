const QUERIES = {
  USERS_LIST: 'users-list',
  PACIENTE_LIST: 'paciente-list',
  EMPRESAS_LIST: 'empresas-list',
  ESPECIALIDADES_LIST: 'especialidades-list',
  FORMULARIOS_LIST: 'formularios-list',
  ATENDIMENTO_MEDICO_LIST: 'atendimento-medico-list'
}


export {QUERIES}
