/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {ativarEmpresa, inativarEmpresa} from '../../core/_requests'
import Swal from 'sweetalert2'
import {AxiosError} from 'axios'
import {Empresa} from '../../core/_models'
import {Row} from 'react-table'
type Props = {
  id: ID
  row: Row<Empresa>
}

const EmpresasActionsCell: FC<Props> = ({id, row}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [IdEmpresa, setIdEmpresa] = useState<string>('')

  useEffect(() => MenuComponent.reinitialization(), [])

  const openEditModal = () => setItemIdForUpdate(id)

  const pacientes = () => {
    window.location.href = '/apps/pacientes-management/pacientes?empresa=' + id
  }
  const deleteItem = useMutation(() => inativarEmpresa(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })

  const activeItem = useMutation(() => ativarEmpresa(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.EMPRESAS_LIST}-${query}`])
    },
    onError(error, variables, context) {
      const err = error as AxiosError
      Swal.fire({
        text: err.response?.data.message,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  })
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Alterar
          </a>
        </div>
        {/* end::Menu item */}

        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter={row.original.ativa ? 'delete_row' : 'active_row'}
            onClick={async () =>
              row.original.ativa ? await deleteItem.mutateAsync() : await activeItem.mutateAsync()
            }
          >
            {row.original.ativa ? 'Inativar' : 'Ativar'}
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={pacientes}>
            Pacientes
          </a>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {EmpresasActionsCell}
