// @ts-nocheck
import { Column } from 'react-table'
import { SetorCliente } from '../../../empresas-list/core/_models'
import { SetorSelectionHeader } from './SetorSelectionHeader'
import { SetorSelectionCell } from './SetorSelectionCell'
import { SetorCustomHeader } from './SetorCustomHeader'
import { SetorActionsCell } from './SetorActionsCell'


const SetorColumns: ReadonlyArray<Column<SetorCliente>> = [
  {
    Header: (props) => <SetorSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <SetorSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <SetorCustomHeader tableProps={props} title='Setor' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  }, 
  {
    Header: (props) => (
      <SetorCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <SetorActionsCell id={props.data[props.row.index].id} />,
  },
]

export { SetorColumns }
