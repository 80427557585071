import axios, { AxiosResponse } from 'axios'
import { ID,} from '../../../../../../_metronic/helpers'
import { EmpresasQueryResponse } from './_models'


const API_URL = process.env.REACT_APP_API_URL
const EMPRESA_URL = `${API_URL}/empresas`
const GET_EMPRESAS_URL = `${API_URL}/empresas`


const getEmpresas = (query: string): Promise<EmpresasQueryResponse> => {
  return axios
    .get(`${GET_EMPRESAS_URL}?${query}`)
    .then((d: AxiosResponse<EmpresasQueryResponse>) => d.data)
}

const webhook = (
  idEmpresa: ID,
  url: string
): Promise<undefined> => {
  return axios
    .put(EMPRESA_URL + '/' + idEmpresa, {
      apiWebhook: url
    }).then((response) => {
      return response.data
    })
}

const gerarToken = (
  idEmpresa: ID,
): Promise<string> => {
  return axios
    .post(EMPRESA_URL + '/integracao/' + idEmpresa).then((response) => {
      return response.data
    })
}


export { getEmpresas, webhook, gerarToken}
