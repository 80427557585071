import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FieldConfig, Options, FieldConfigUpdate} from '../../core/_models'
import {ID} from '../../../../../../../_metronic/helpers'
import {
  createCampo,
  createOpcao,
  deleteOpcoes,
  updateCampo,
  updateOpcao,
} from '../../core/_requests'
import Select from 'react-select'

interface FieldConfigModalProps {
  field: FieldConfig
  formId: ID
  onUpdate: (config: FieldConfig) => void
  closeModal: () => void
}

const FieldConfigModal: React.FC<FieldConfigModalProps> = ({
  field,
  onUpdate,
  formId,
  closeModal,
}) => {
  const [nextOrdem, setNextOrdem] = useState<number>(0)
  const [deletedOptions, setDeletedOptions] = useState<number[]>([])

  const formik = useFormik({
    initialValues: {
      rotulo: field.rotulo,
      instrucoes: field.instrucoes || '',
      respostaPadrao: field.respostaPadrao || '',
      tipo: field.tipo,
      obrigatorio: field.obrigatorio || true,
      multiplo: field.multiplo || false,
      opcoes: field.opcoes || [],
      ordem: field.ordem,
    },
    onSubmit: (values) => {
      const campo = {
        ordem: field.ordem,
        rotulo: values.rotulo,
        respostaPadrao: values.respostaPadrao,
        instrucoes: values.instrucoes,
        tipo: values.tipo,
        obrigatorio: values.obrigatorio,
        multiplo: values.multiplo,
        opcoes: [],
      }
      if (formId) {
        if (field.id) {
          updateCampo(campo, field.id)
          values.opcoes.forEach((option) =>
            option.id
              ? updateOpcao(option, field.id)
              : createOpcao({descricao: option.descricao, ordem: option.ordem}, field.id)
          )
          if (deletedOptions.length > 0) deleteOpcoes(deletedOptions, field.id)
        } else {
          createCampo({...campo, opcoes: values.opcoes.map((option) => option.descricao)}, formId)
        }
      }
      onUpdate({...field, ...values})

      closeModal()
    },
  })

  useEffect(() => {
    const maxOrdem = field.opcoes?.reduce((max, option) => Math.max(max, option.ordem || 0), 0)
    setNextOrdem(maxOrdem + 1)

    console.log('field COfing', field)
  }, [])

  const addOption = () => {
    formik.setFieldValue('opcoes', [...formik.values.opcoes, {descricao: '', ordem: nextOrdem}])
    setNextOrdem((prevOrdem) => prevOrdem + 1)
  }

  const updateOptions = (e: React.ChangeEvent<HTMLInputElement>, option: Options) => {
    const updatedOptions = formik.values.opcoes?.map((op) =>
      op.ordem === option.ordem ? {...op, descricao: e.target.value} : op
    )
    formik.setFieldValue('opcoes', updatedOptions)
  }

  const removeOption = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, option: Options) => {
    setDeletedOptions((prev) => (option.id ? [...prev, option.id] : [...prev]))
    const updatedOptions = formik.values.opcoes?.filter((op) => op.ordem !== option.ordem)
    formik.setFieldValue('opcoes', updatedOptions)
  }

  const renderOptionsDefault = (values: FieldConfig) => {
    const data = values.opcoes?.map((option) => ({
      value: option.descricao,
      label: option.descricao,
    }))
    return (
      <div className='fv-row mb-7'>
        <label className='form-label text-gray-700 fs-6 fw-bold'>Selecionar Reposta Padrão</label>
        <Select
          className='d-flex w-100'
          styles={{
            control: (provided) => ({
              ...provided,
              width: '100%',
            }),
          }}
          options={data}
          required={values.obrigatorio}
          onChange={(option) => formik.setFieldValue('respostaPadrao', option?.value)}
          value={{label: values.respostaPadrao, value: values.respostaPadrao}}
        />
      </div>
    )
  }

  return (
    <>
      <div className='fv-row mb-7'>
        <label className='form-label text-gray-700 fs-6 fw-bold'>Rótulo</label>
        <input
          type='text'
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.rotulo && formik.errors.rotulo},
            {
              'is-valid': formik.touched.rotulo && !formik.errors.rotulo,
            }
          )}
          {...formik.getFieldProps('rotulo')}
        />
        {formik.touched.rotulo && formik.errors.rotulo && (
          <div className='text-danger'>{formik.errors.rotulo}</div>
        )}
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label text-gray-700 fs-6 fw-bold'>Instruções</label>
        <input type='text' className='form-control' {...formik.getFieldProps('instrucoes')} />
      </div>
      {formik.values.tipo !== 'RADIO' && formik.values.tipo !== 'SELECT' && (
        <div className='fv-row mb-7'>
          <label className='form-label text-gray-700 fs-6 fw-bold'>Resposta Padrão</label>
          <input
            type={formik.values.tipo === 'NUMERO' ? 'number' : 'text'}
            className='form-control'
            onChange={(e) => formik.setFieldValue('respostaPadrao', String(e.target.value))}
            value={formik.values.respostaPadrao}
            /* {...formik.getFieldProps('respostaPadrao')} */
          />
        </div>
      )}

      <div className='fv-row mb-7'>
        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
          <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>Obrigatório</span>
          <input
            className='form-check-input'
            type='checkbox'
            checked={formik.values.obrigatorio}
            {...formik.getFieldProps('obrigatorio')}
          />
        </label>
      </div>

      {formik.values.tipo === 'SELECT' && (
        <div className='fv-row mb-7'>
          <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
              Permitir múltipla seleção
            </span>
            <input
              className='form-check-input'
              type='checkbox'
              checked={formik.values.multiplo}
              {...formik.getFieldProps('multiplo')}
            />
          </label>
        </div>
      )}

      {(formik.values.tipo === 'RADIO' || formik.values.tipo === 'SELECT') && (
        <>
          <div className='d-flex fv-row mb-7 align-items-center justify-content-between'>
            <label className='form-label text-gray-700 fs-6 fw-bold'>Opções</label>

            <Button onClick={addOption} className='btn btn-primary active btn-sm'>
              <i className='bi bi-plus'></i>
              Adicionar Opção
            </Button>
          </div>
          {formik.values.opcoes?.map((option: Options, index: number) => (
            <div key={index} className='d-flex align-items-center mb-4'>
              <input
                type='text'
                className='form-control me-2'
                value={option.descricao}
                onChange={(e) => updateOptions(e, option)}
                placeholder='Digite seu campo'
              />
              <Button variant='link' className='p-0' onClick={(e) => removeOption(e, option)}>
                <i className='bi bi-trash text-danger' style={{fontSize: '20px'}}></i>
              </Button>
            </div>
          ))}
          {renderOptionsDefault(formik.values)}
        </>
      )}

      <div className='d-flex justify-content-end'>
        <Button variant='primary' onClick={formik.submitForm}>
          Salvar
        </Button>
      </div>
    </>
  )
}

export default FieldConfigModal
