import * as Yup from 'yup'

import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { ListLoading } from '../../../commons/components/ListLoading'
import clsx from 'clsx'
import { ID, KTSVG, isNotEmpty } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import { ColaboradorPcmso, Empresa, EmpresaCliente } from '../../empresas-list/core/_models'
import { IMaskInput } from 'react-imask'
import { EditEmpresaClienteAso, createEmpresaClienteAso, getEmpresaClienteById, getEmpresas } from '../../empresas-list/core/_requests'
import { Coordenador } from './Coordenador'

type Props = {
  isEmpresaLoading: boolean
  cliente: EmpresaCliente | undefined
}

const EmpresaClienteEditModalForm: FC<Props> = ({ cliente, isEmpresaLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [idEmpresa, setIdEmpresa] = useState<ID>(undefined)
  const [statusVinc, setStatusVinc] = useState<boolean>(false)
  const [empresaCliente, setEmpresaCliente] = useState<EmpresaCliente | undefined>(cliente)
  const [tipoDocumento, setTipoDocumento] = useState<string>(cliente?.tipoDocumento || 'CNPJ')
  const [showCadastroCoord, setShowCadastroCoord] = useState<boolean>(false)
  const [coordenador, setCoordenador] = useState<ColaboradorPcmso>()

  //consulta pelo nome do responsavel
  const editEmpresaClienteSchema = Yup.object().shape({
    tipo: Yup.string().required('Tipo de documento é obrigatório'),
    cnpj: Yup
      .string()
      .nullable()
      .when([], {
        is: () => tipoDocumento === 'CNPJ',
        then: Yup.string().required('O campo CNPJ obrigatório'),
        otherwise: Yup.string().notRequired()
      }),
    cpf: Yup
      .string()
      .nullable()
      .when([], {
        is: () => tipoDocumento === 'CPF',
        then: Yup.string().required('O campo CPF obrigatório'),
        otherwise: Yup.string().notRequired()
      }),
    caepf: Yup
      .string()
      .nullable()
      .when([], {
        is: () => tipoDocumento === 'CAEPF',
        then: Yup.string().required('O campo CAEPF obrigatório'),
        otherwise: Yup.string().notRequired()
      }),
    nome: Yup.string().required('Nome é obrigatório'),
    empresa: Yup.string().required('Empresa é obrigatório')
  });

  const INITIAL_DATA = {
    value: empresaCliente?.empresa?.id !== undefined ? empresaCliente.empresa?.id : 0,
    label: empresaCliente?.empresa?.id !== undefined ? empresaCliente.empresa?.nome : 'Digite o nome da empresa!',
  };
  console.log(empresaCliente)
  const [selectData, setselectData] = useState(INITIAL_DATA);
  const empresaClienteForEdit = {
    ...empresaCliente,
    nome: empresaCliente?.nome,
    cnpj: empresaCliente?.tipoDocumento === 'CNPJ' ? empresaCliente?.documento : '',
    cpf: empresaCliente?.tipoDocumento === 'CPF' ? empresaCliente?.documento : '',
    caepf: empresaCliente?.tipoDocumento === 'CAEPF' ? empresaCliente?.documento : '',
    tipo: tipoDocumento,
    empresa: empresaCliente?.empresa?.nome || '',
    idEmpresa: empresaCliente?.empresa?.id !== undefined ? empresaCliente.empresa?.id : undefined,
  }
  const updateStateCliente = () => {
    if (empresaCliente !== undefined) {
      getEmpresaClienteById(empresaCliente.id).then((result) => {
        if (result) {
          setEmpresaCliente(result)
        } else {
          Swal.fire({
            text: "Não foi possível atualizar os dados do Empregador ASO!",
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        }
      })
    } else {
      Swal.fire({
        text: "Empregador ASO não informada!",
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary"
        }
      })
    }
  }
  const editCoordenador = (coord: ColaboradorPcmso) => {
    setCoordenador(coord)
    setShowCadastroCoord(true)
  }
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: empresaClienteForEdit,
    validationSchema: editEmpresaClienteSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          EditEmpresaClienteAso(values.id, String(values.nome), String(values?.cnpj), tipoDocumento, String(values.cpf), String(values.caepf)).then((result) => {
            Swal.fire({
              text: 'Empregador ASO alterada com sucesso!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            }).then(() => {
              window.location.reload()
            })
          }).catch((error) => {
            const errors = error.response.data.data.errors
            if (errors !== undefined) {
              setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
              setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              setFieldError('caepf', errors['caepf'] !== undefined ? errors.caepf[0] : '')
              setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')
            } else {
              Swal.fire({
                text: "Não foi possível alterar o Empregador ASO!",
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              })
            }
          })
        } else {
          createEmpresaClienteAso(idEmpresa, String(values.nome), String(values?.cnpj), tipoDocumento, String(values.cpf), String(values.caepf)).then((result) => {
            Swal.fire({
              text: 'Empregador ASO criada com sucesso!',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            }).then(() => {
              window.location.reload()
            })
          }).catch((error) => {
            const errors = error.response.data.data.errors
            if (errors !== undefined) {
              setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
              setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
              setFieldError('caepf', errors['caepf'] !== undefined ? errors.caepf[0] : '')
              setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')
            } else {
              Swal.fire({
                text: "Não foi possível criar o Empregador ASO!",
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              })
            }
          })
        }
      } catch (error: any) {
        const errors = error.response.data.data.errors
        setFieldError('cnpj', errors['cnpj'] !== undefined ? errors.cnpj[0] : '')
        setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
        setFieldError('caepf', errors['caepf'] !== undefined ? errors.caepf[0] : '')
        setFieldError('cpf', errors['cpf'] !== undefined ? errors.cpf[0] : '')

        console.error(error)
      } finally {
        setSubmitting(false)
      }
    },
  })
  useEffect(() => {

  }, [empresaCliente])

  const handleShowCoordenador = () => {
    setCoordenador(undefined)
    updateStateCliente()
    setShowCadastroCoord(!showCadastroCoord)
  }
  const mapResponseToValuesAndLabels = (data: Empresa) => ({
    label: data?.nomeFantasia,
    value: data.id,
  })
  async function callApi(value: string) {
    const data = await getEmpresas('page=0&size=100&search=' + value)
      .then((response) => response.data?.map(mapResponseToValuesAndLabels))
      .then((final: any) =>
        final.filter((i: any) => String(i.label).toLowerCase().includes(value.toLowerCase()))
      );
    return data;
  }

  console.log(formik)


  return (
    <>
      {showCadastroCoord ? (
        <Coordenador empresaCliente={empresaCliente} coordenador={coordenador} onCancel={handleShowCoordenador} />
      ) : (
        <form id='kt_modal_add_empresa_form' className='form' onSubmit={formik.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column me-n7 '
            id='kt_modal_add_empresa_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_empresa_header'
            data-kt-scroll-wrappers='#kt_modal_add_empresa_scroll'
          // data-kt-scroll-offset='300px'
          >
            {empresaCliente?.empresa !== undefined ? (
              <>
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='fw-bold fs-6 mb-2'>Empresa</label>
                  <span className='menu-icon d-flex align-items-center'>
                    <span >{empresaCliente.empresa?.nomeFantasia}</span>
                  </span>
                </div>
              </>
            ) : (
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Empresa</label>
                {/* end::Label */}

                {/* begin::Input */}
                <AsyncSelect
                  cacheOptions
                  loadOptions={callApi}
                  // {...formik.getFieldProps('cpf')}
                  onChange={(data: any) => {
                    setIdEmpresa(data.value)
                    setselectData(data)
                    formik.setFieldValue('empresa', data.value)
                  }}
                  value={selectData}
                  defaultOptions
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': idEmpresa === undefined },
                    {
                      'is-valid': idEmpresa !== undefined,
                    }
                  )}
                //onInputChange={}
                />
                {formik.errors.empresa && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.empresa}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            )}
            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Nome</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Nome'
                {...formik.getFieldProps('nome')}
                type='text'
                name='nome'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  { 'is-invalid': formik.touched.nome && formik.errors.nome },
                  {
                    'is-valid': formik.touched.nome && !formik.errors.nome,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmpresaLoading}
              />
              {formik.touched.nome && formik.errors.nome && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.nome}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            {/* end::Input group */}

            {/* end::Input group */}

            {/* begin::Input group */}
            <div className='fv-row mb-7'>
              <div className="mb-3">
                <label className="required fw-bold fs-6 mb-2 me-4">Tipo</label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input-sm"
                    type="radio"
                    id="tipoCPF"
                    value="CPF"
                    checked={tipoDocumento === 'CPF'}
                    onChange={(e) => {
                      setTipoDocumento(e.target.value)
                      formik.setFieldValue('tipo', e.target.value)
                    }}
                  />
                  <label className="form-check-label" htmlFor="tipoCPF">CPF</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input-sm"
                    type="radio"
                    id="tipoCNPJ"
                    value="CNPJ"
                    checked={tipoDocumento === 'CNPJ'}
                    onChange={(e) => {
                      setTipoDocumento(e.target.value)
                      formik.setFieldValue('tipo', e.target.value)
                    }}
                  />
                  <label className="form-check-label" htmlFor="tipoCNPJ">CNPJ</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input form-check-input-sm"
                    type="radio"
                    id="tipoCAEPF"
                    value="CAEPF"
                    checked={tipoDocumento === 'CAEPF'}
                    onChange={(e) => {
                      setTipoDocumento(e.target.value)
                      formik.setFieldValue('tipo', e.target.value)
                    }}
                  />
                  <label className="form-check-label" htmlFor="tipoCAEPF">CAEPF</label>
                </div>
              </div>
            </div>

            <div className='fv-row mb-7'>
              {tipoDocumento === 'CPF' && (
                <>
                  <label className='required fw-bold fs-6 mb-2'>CPF</label>
                  <IMaskInput
                    placeholder='Digite o CPF'
                    {...formik.getFieldProps('cpf')}
                    mask="000.000.000-00"
                    type='text'
                    name='cpf'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
                      { 'is-valid': formik.touched.cpf && !formik.errors.cpf }
                    )}
                    disabled={formik.isSubmitting || isEmpresaLoading}
                  />
                  {formik.touched.cpf && formik.errors.cpf && tipoDocumento === 'CPF' && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.cpf}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {tipoDocumento === 'CNPJ' && (
                <>
                  <label className='required fw-bold fs-6 mb-2'>CNPJ</label>
                  <IMaskInput
                    placeholder='Digite o CNPJ'
                    {...formik.getFieldProps('cnpj')}
                    mask="00.000.000/0000-00"
                    type='text'
                    name='cnpj'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                      { 'is-valid': formik.touched.cnpj && !formik.errors.cnpj }
                    )}
                    disabled={formik.isSubmitting || isEmpresaLoading}
                  />
                  {formik.touched.cnpj && formik.errors.cnpj && tipoDocumento === 'CNPJ' && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.cnpj}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {tipoDocumento === 'CAEPF' && (
                <>
                  <label className='required fw-bold fs-6 mb-2'>CAEPF</label>
                  <IMaskInput
                    placeholder='Digite o CAEPF'
                    {...formik.getFieldProps('caepf')}
                    mask="000.000.000/000-00"
                    type='text'
                    name='caepf'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.caepf && formik.errors.caepf },
                      { 'is-valid': formik.touched.caepf && !formik.errors.caepf }
                    )}
                    disabled={formik.isSubmitting || isEmpresaLoading}
                  />
                  {formik.touched.caepf && formik.errors.caepf && tipoDocumento === 'CAEPF' && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.caepf}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {/* </div> */}
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || isEmpresaLoading}
            >
              Cancelar
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isEmpresaLoading || formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Enviar</span>
              {(formik.isSubmitting || isEmpresaLoading) && (
                <span className='indicator-progress'>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>

      )}
      {(formik.isSubmitting || isEmpresaLoading) && <ListLoading />}
    </>
  )
}

export { EmpresaClienteEditModalForm }
