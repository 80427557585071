/* eslint-disable jsx-a11y/anchor-is-valid */
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Pagination from 'react-js-pagination'

const EmpresasClienteListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number) => {
    if (isLoading || pagination.page === page) {
      return
    }

    updateState({page, size: pagination.size || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_empresas_paginate'>
          <Pagination
              activePage={pagination.page + 1}
              itemsCountPerPage={pagination.size}
              totalItemsCount={pagination.totalElements}
              pageRangeDisplayed={5}
              onChange={(p) => updatePage(p - 1)}
              innerClass='pagination'
              itemClass='page-item'
              linkClass='page-link'
              hideFirstLastPages={true}
              prevPageText='Anterior'
              nextPageText='Próximo'
            />
        </div>
      </div>
    </div>
  )
}

export {EmpresasClienteListPagination}
