/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { initialQueryState, useDebounce } from '../../../../../../../_metronic/helpers'

import { useListView } from '../../../core/ListViewProvider';
import { useQueryRequest } from '../../../core/QueryRequestProvider';
import Select from 'react-select';
import { getEstados } from '../../../core/_requests';

const AtendimentosListSearchComponent = () => {
  const { updateState } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { idEmpresa, setIdEmpresa } = useListView()
  const [protocoloSel, setProtocoloSel] = useState<string>('')
  const [estadoSel, setEstadoSel] = useState<String | undefined>(undefined);
  const [dataSel, setDataSel] = useState<string>('')
  const [options, setOptions] = useState<any>([]);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const empresaFilter: Array<String> = idEmpresa ? new Array(String(idEmpresa)) : []
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  // Effect for API call
  useEffect(
    () => {
      getOptionsEstados()
      if (searchTerm !== undefined || idEmpresa !== undefined) {
        updateState({ filter: empresaFilter, search: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )
  //consulta pelo nome da empresa
  async function getOptionsEstados() {
    if (estadoSel === undefined) {
      const { data } = await getEstados()
      const estadoIni = data?.find(i => Math.min(Number(i.id)))
      setEstadoSel(String(estadoIni?.id))

      setSearchTerm('estado.id:' + String(estadoIni?.id))
      const results: any = []
      if (data !== undefined) {
        data.forEach((value) => {
          results.push({
            label: value.nome,
            value: value.id
          });
        });
        setOptions([
          ...results
        ])
      }
    }
  }
  const handleChangeEstado = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setEstadoSel(e.value)

    setSearchTerm('estado.id:' + e.value + ',' +
      (protocoloSel !== '' ? 'protocolo~*' + protocoloSel + '*,' : '') +
      (dataSel !== '' ? 'dataInicio>' + dataSel : ''))
  }
  const handleChangeProtocolo = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    if (e.target.value !== undefined) {
      setProtocoloSel(String(e.target.value))
      setSearchTerm('protocolo~*' + e.target.value + '*' +
        (estadoSel !== '' ? ',estado.id:' + estadoSel : '') +
        (dataSel !== '' ? ',dataInicio>' + dataSel : ''))
    }
  }
  const handleChangeData = (e: any) => {
    //Definir o campo de todos os inputs para filtro
    setDataSel(String(e.target.value))
    console.error(e.target.value)
    setSearchTerm('dataInicio>' + String(e.target.value) +
      (estadoSel !== '' ? ',estado.id:' + estadoSel : '') +
      (protocoloSel !== '' ? ',protocolo~*' + protocoloSel + '*' : ''))
  }
  // const [selectData, setselectData] = useState(INITIAL_DATA);
  // const mapResponseToValuesAndLabels = (data: Empresa) => ({
  //   label: data.nome,
  //   value: data.id,
  // });

  // async function callApi(value: string) {
  //   if (value.length > 2) {
  //     const data = await getEmpresas('page=0&size=30&search=' + value)
  //       .then((response) => response.data?.map(mapResponseToValuesAndLabels))
  //       .then((final: any) =>
  //         final.filter((i: any) => String(i.label).toLowerCase().includes(value.toLowerCase()))
  //       );
  //     return data;
  //   }

  // }
  return (
    <div className='p-2'>
      <div className='card-body p-0'>
        {/* begin::Search */}
        <div className='row mb-0'>
          <div className='col-lg-4'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-5'>Protocolo</label>
            <input
              type='text'
              data-kt-user-table-filter='nome'
              className='form-control form-control-solid ps-5'
              placeholder='Digite o protocolo'
              value={protocoloSel}
              onChange={handleChangeProtocolo}
            />
          </div>

          <div className='col-lg-4'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-5'>A partir</label>
            <input
              type='date'
              data-kt-user-table-filter='nome'
              max={new Date().toISOString().split("T")[0]}
              className='form-control form-control-solid ps-3'
              placeholder='Digite data inicial'
              value={dataSel}
              onChange={handleChangeData}
            />
          </div>

          <div className='col-lg-4'>
            <label className='form-label fs-6 fw-bolder mb-1 ps-5'>Estado</label>
            <Select
              placeholder='Selecione um estado'
              onChange={handleChangeEstado}
              options={options}
              className='form-control form-control-solid'
              defaultValue={{ label: "Aguardando", value: "1" }}
            />
          </div>
        </div>
        {/* end::Search */}
      </div>
    </div>
  )
}

export { AtendimentosListSearchComponent }
