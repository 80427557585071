import { FC, useEffect, useState } from "react"
import * as Yup from 'yup'
import { useFormik } from "formik"
import Swal from "sweetalert2"
import clsx from "clsx"
import Select from 'react-select';
import { IMaskInput } from "react-imask"
import { AxiosError } from "axios"
import { ColaboradorPcmso, EmpresaCliente } from "../../empresas-list/core/_models"
import { ID, KTSVG, toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { alteraCoordenadorPcmso, createColaboradorPcmso, getColaboradorPcmso } from "../../empresas-list/core/_requests"
import { vinculaCoordenadorPcmso } from "../../../atendimento/core/_requests"
import { useSearchParams } from "react-router-dom"
import { refreshEmpresaCliente } from "../../empresasClienteCoordenador-list/CoordenadorClienteList"


type Props = {
    empresaCliente: EmpresaCliente | undefined,
    coordenador?: ColaboradorPcmso | undefined,
    onCancel: Function
}

const Coordenador: FC<Props> = ({ empresaCliente, coordenador, onCancel }) => {
    const [searchParams] = useSearchParams();
    const cliente = Number(searchParams.get("cliente"))
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<any>([]);
    const empresaId = empresaCliente ? empresaCliente?.id : cliente;
    const [idColaborador, setIdColaborador] = useState<ID | undefined>(undefined);
    const [colaboradorEdit, setColaboradorEdit] = useState<ColaboradorPcmso | undefined>(coordenador)

    const [showCadastroCliente, setshowCadastroCliente] = useState(false);

    const handleShowCadastroCliente = () => {
        setColaboradorEdit(undefined)
        setIdColaborador(undefined)
        setshowCadastroCliente(!showCadastroCliente)
        formik.resetForm()
        onCancel()
    };
    const clienteASoSchema = Yup.object().shape({
        nome: Yup.string()
            .required('Nome é obrigatório'),
        crm: Yup.string()
            .required('Número do Conselho de Classe  é obrigatório'),
        rqe: Yup.string()
            .max(50, 'Máximo de 6 caracteres')
            .required('RQE é obrigatório'),
        uf: Yup.string()
            .required('UF é obrigatório')
    })
    const clienteAsoForEdit = {
        ...coordenador,
        nome: coordenador !== undefined ? coordenador.nome : '',
        crm: coordenador !== undefined ? coordenador.crm : '',
        rqe: coordenador !== undefined ? coordenador.rqe : '',
        uf: coordenador !== undefined ? coordenador.uf : ''
    }
    const defineColaborador = (id: ID) => {
        setIdColaborador(id)
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: clienteAsoForEdit,
        validationSchema: clienteASoSchema,
        onSubmit: async (values, { setSubmitting, setFieldError, validateForm }) => {
            setSubmitting(true)
            setLoading(true)
            try {
                if (colaboradorEdit !== undefined) {
                    if (empresaCliente === undefined && cliente === undefined) {
                        Swal.fire({
                            text: 'Cadastre um Empregador ASO antes de definir um coordenador',
                            icon: 'warning',
                            buttonsStyling: false,
                            confirmButtonText: "Ok",
                            customClass: {
                                confirmButton: "btn btn-primary"
                            }
                        })
                    } else {
                        alteraCoordenadorPcmso(empresaId, colaboradorEdit.id, String(values.nome), String(values.crm), String(values.rqe), String(values.uf))
                            .then((colaborador) => {
                                setColaboradorEdit(undefined)
                                setIdColaborador(colaborador?.id)
                                // vincularEmpresaCliente()
                                setshowCadastroCliente(false)
                                refreshEmpresaCliente();
                                Swal.fire({
                                    text: 'Coordenador alterado com sucesso!',
                                    icon: 'success',
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok",
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    }
                                }).then(() => {
                                    formik.resetForm()
                                    handleShowCadastroCliente()
                                })
                            }).catch((error) => {
                                const errors = error.response.data.data.errors
                                setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                                setFieldError('crm', errors['crm'] !== undefined ? errors.crm[0] : '')
                                setFieldError('rqe', errors['rqe'] !== undefined ? errors.rqe[0] : '')
                                setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                                const err = error as AxiosError;
                                Swal.fire({
                                    text: 'Erro ao alterar! ',
                                    icon: 'error',
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok",
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    }
                                })
                            }).finally(() => {
                                setSubmitting(false)
                                setLoading(false)
                            })
                    }
                } else {
                    //cadastra nova Empregador ASO
                    createColaboradorPcmso(empresaId, String(values.nome), String(values.crm), String(values.rqe), String(values.uf))
                        .then((colaborador) => {
                            setSubmitting(false)
                            setLoading(false)
                            refreshEmpresaCliente();
                            Swal.fire({
                                text: 'Coordenador adicionado com sucesso!',
                                icon: 'success',
                                buttonsStyling: false,
                                confirmButtonText: "Ok",
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                }
                            })
                                .then(() => {
                                    formik.resetForm()
                                    setIdColaborador(colaborador?.data.id)
                                    handleShowCadastroCliente()
                                })
                        }).catch((error) => {
                            const errors = error.response.data.data.errors
                            setFieldError('nome', errors['nome'] !== undefined ? errors.nome[0] : '')
                            setFieldError('crm', errors['crm'] !== undefined ? errors.crm[0] : '')
                            setFieldError('rqe', errors['rqe'] !== undefined ? errors.rqe[0] : '')
                            setFieldError('uf', errors['uf'] !== undefined ? errors.uf[0] : '')
                            const err = error as AxiosError;
                            Swal.fire({
                                text: 'Erro ao cadastrar! ' + err.response?.data.data.message,
                                icon: 'error',
                                buttonsStyling: false,
                                confirmButtonText: "Ok",
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                }
                            })
                        })
                }
            } finally {
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    //Carrega colaboradors PCMSO clientes registradas
    const handleEditCadastroCoordenador = () => {
        if (idColaborador === undefined) {
            Swal.fire({
                text: 'Selecione um Coordenador para edição',
                icon: 'warning',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            })
        } else {
            getColaboradorPcmso(empresaId).then((response) => {
                response?.map(colaborador => {
                    if (colaborador.id === idColaborador) {

                        setColaboradorEdit(colaborador)
                        formik.setFieldValue('nome', String(colaborador?.nome))
                        formik.setFieldValue('crm', String(colaborador?.crm))
                        formik.setFieldValue('rqe', String(colaborador?.rqe))
                        formik.setFieldValue('uf', String(colaborador?.uf))
                        setshowCadastroCliente(true)
                    }
                })
            })
        }
    }
    const vinculaColaborador = () => {
        if (idColaborador !== undefined) {
            //Altera ou adiciona uma Empregador ASO já cadastrada ao atendimento
            vinculaCoordenadorPcmso(empresaId, idColaborador).then((colaborador) => {
                Swal.fire({
                    text: 'Coordenador adicionado com sucesso ao atendimento!',
                    icon: 'success',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                }).then(() => {
                    formik.resetForm()
                    defineColaborador(colaborador?.id)
                })
            }).catch((error) => {
                const err = error as AxiosError;
                Swal.fire({
                    text: 'Erro ao ao víncular! ' + err.response?.data.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: "Ok",
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            })
        } else {
            Swal.fire({
                text: 'Erro ao ao víncular! Selecione um coordenador. ',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "btn btn-primary"
                }
            })
        }
    }
    return (
        <>
            {empresaCliente !== null ? (

                <form id='kt_modal_add_paciente_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                    {/* begin::Scroll */}
                    <div
                        className='d-flex flex-column me-n7 pe-7'
                        id='kt_modal_add_paciente_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_paciente_header'
                        data-kt-scroll-wrappers='#kt_modal_add_paciente_scroll'
                        data-kt-scroll-offset='300px'
                    >
                        <div className="fv-row mb-7">
                            <h2 className='fw-bolder mb-5'>
                                Cadastro Coordenador
                            </h2>
                        </div>
                        {/* begin::Input group */}
                        <>
                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Nome</label>
                                <input
                                    placeholder='Nome'
                                    {...formik.getFieldProps('nome')}
                                    type='text'
                                    name='nome'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        { 'is-invalid': formik.touched.nome && formik.errors.nome && colaboradorEdit === undefined },
                                        {
                                            'is-valid': formik.touched.nome && !formik.errors.nome,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || loading}
                                />
                                {formik.touched.nome && formik.errors.nome && colaboradorEdit === undefined && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.nome}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Número do Conselho de Classe </label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <IMaskInput
                                    placeholder='Número do Conselho de Classe '
                                    {...formik.getFieldProps('crm')}
                                    mask="00000000000"
                                    type='text'
                                    name='crm'
                                    // hidden={hideFind}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        { 'is-invalid': formik.touched.crm && formik.errors.crm && colaboradorEdit === undefined },
                                        {
                                            'is-valid': formik.touched.crm && !formik.errors.crm,
                                        }
                                    )}
                                    disabled={formik.isSubmitting || loading}
                                />
                                {formik.touched.crm && formik.errors.crm && colaboradorEdit === undefined && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.crm}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-7'>
                                <label className='required fw-bold fs-6 mb-2'>Registro de Qualificação de Especialista (RQE)</label>
                                <IMaskInput
                                    placeholder='RQE'
                                    {...formik.getFieldProps('rqe')}
                                    mask="000000"
                                    type='text'
                                    name='rqe'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        { 'is-invalid': formik.touched.rqe && formik.errors.rqe && colaboradorEdit === undefined },
                                        {
                                            'is-valid': formik.touched.rqe && !formik.errors.rqe,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || loading}
                                />
                                {formik.touched.rqe && formik.errors.rqe && colaboradorEdit === undefined && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.rqe}</span>
                                        </div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>Estado</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('uf')}
                                >
                                    <option value=''>Selecione um estado...</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                                {formik.touched.uf && formik.errors.uf && colaboradorEdit === undefined && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.uf}</div>
                                    </div>
                                )}
                                {/* end::Input */}
                            </div>
                            <div className='card-footer d-flex justify-content-end py-2 px-8'>
                                <button
                                    type='reset'
                                    //className='btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-2 rounded-bottom-2 me-2 '
                                    className='btn btn-light me-3'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    onClick={handleShowCadastroCliente}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-kt-users-modal-action='submit'
                                    disabled={loading || formik.isSubmitting || !formik.isValid}
                                >
                                    <span className='indicator-label'>Enviar</span>
                                    {(formik.isSubmitting || loading) && (
                                        <span className='indicator-progress'>
                                            Aguarde...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </>

                    </div>

                </form>
            ) : (
                <div id="msgUser" className='mb-10 symbol-label bg-danger text-inverse-danger fw-bold rounded '>
                    Defina a Empregador ASO previamente! Não é possível definir coordenador.
                </div >
            )}

        </>
    )
}

export { Coordenador }
