import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, InviteUsers, UpgradePlan, UploadImagemPerfil} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import { PacienteFindModal } from '../../app/modules/apps/paciente-management/pacientes-list/paciente-edit-modal/PacienteFindModal'
import { FuncionarioEditModalForm } from '../../app/modules/apps/funcionarios-management/funcionarios-list/funcionario-edit-modal/FuncionarioEditModalForm'
import { FuncionarioFindModal } from '../../app/modules/apps/funcionarios-management/funcionarios-list/funcionario-edit-modal/FuncionarioFindModal'
import { AtendimentoModal } from '../../app/modules/apps/atendimento/AtendimentoModal'
import { DispositivosModal } from '../../app/modules/apps/atendimento_medicos/atendimento-video/dispositivos/DispositivosModal'


const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      <UploadImagemPerfil />
      <AtendimentoModal />
      <DispositivosModal />
      <PacienteFindModal />
      <FuncionarioFindModal />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
