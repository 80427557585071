import { AxiosError } from 'axios';
import { ReactNode, useState } from 'react'; // Importe ReactNode se você estiver usando React version >= 17
import { Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AtendimentoIniciado } from '../../../../atendimento/core/_models';
import { cancelarAtendimentoById } from '../../../../atendimento/core/_requests';
import { useQueryResponse } from '../../core/QueryResponseProvider';

interface CancelarAtendimentoModalProps {
  atendimento: AtendimentoIniciado;
  showCancelarAtendimento: boolean;
  onClose: () => void;
}

const CancelarAtendimentoModal: React.FC<CancelarAtendimentoModalProps> = ({ atendimento, showCancelarAtendimento, onClose }) => {
  const [motivoCancelamento, setMotivoCancelamento] = useState('');
  const navigate = useNavigate();
  const { refetch } = useQueryResponse()


  const handleCancelAtendimento = () => {
    if (!motivoCancelamento) {
      Swal.fire({
        text: 'O motivo do cancelamento é obrigatório.',
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });
      return;
    }

    try {
      cancelarAtendimentoById(atendimento.id, motivoCancelamento);
      refetch()
      Swal.fire({
        text: 'Atendimento cancelado com sucesso',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      })
      onClose();
      navigate('/apps/atendimentosMedicos/lista')
    } catch (error) {
      const err = error as AxiosError;
      Swal.fire({
        text: `Falha ao cancelar atendimento: ${err.response?.data.data.message}`,
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary'
        }
      });
    }
  };

  return (
    <Modal className="modal-md" show={showCancelarAtendimento} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancelar atendimento de {atendimento?.paciente?.nome}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="motivoCancelamento">
          <Form.Label>Motivo do cancelamento</Form.Label>
          <Form.Control
            type="text"
            placeholder="Digite o motivo do cancelamento"
            value={motivoCancelamento}
            required={true}
            className={motivoCancelamento ? '' : 'is-invalid'}
            onChange={(e) => setMotivoCancelamento(e.target.value)}
          />
        </Form.Group>

        <div className="d-flex justify-content-end mt-6">
          <Button variant="secondary" onClick={onClose} className="me-3">
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleCancelAtendimento}>
            Confirmar Cancelamento
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { CancelarAtendimentoModal };
