import {useState, useEffect} from 'react'
import {initialQueryState, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../formularios-list/core/QueryRequestProvider'
import {useNavigate} from 'react-router-dom'

const FormulariosListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const navigate = useNavigate()

  const [nomeSel, setNomeSel] = useState<string>('')
  const [active, setActive] = useState<string>('')

  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    console.log(searchTerm)
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({
        filter: [],
        search: searchTerm,
        sort: 'nome',
        order: 'asc',
        ...initialQueryState,
      })
    }
  }, [searchTerm, debouncedSearchTerm])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNomeSel(e.target.value)
    let search = ''

    const statusQuery = active !== '' ? `ativo:${active},` : ''
    const searchQuery = e.target.value !== '' ? `nome~*${e.target.value}*` : ''

    search = `${statusQuery}${searchQuery}`

    setSearchTerm(search)
  }

  const handleStatus = (e: any) => {
    const status = String(e.target.value)
    const statusQuery = status !== '' ? `ativo:${status},` : ''
    const searchQuery = nomeSel !== '' ? `nome~*${nomeSel}*` : ''

    setActive(status)
    setSearchTerm(`${statusQuery}${searchQuery}`)
  }

  const handleFormulario = () => navigate('criar')

  return (
    <div className='d-flex align-items-center justify-content-center w-100 col-md-12'>
      <div className='d-flex align-items-center col-md-10'>
        <div className='mb-5 me-2 col-5 col-md-4'>
          <label className='form-label fw-bold ps-1'>Formulário</label>
          <input
            type='text'
            data-kt-user-table-filter='nome'
            className='form-control form-control-solid w-100'
            placeholder='Digite o nome'
            onChange={onChange}
          />
        </div>
        <div className='mb-5 mx-1'>
          <label className='form-label fw-bold ps-1'>Situação</label>
          <select
            name='situacao'
            aria-label='Selecione uma situação'
            data-control='select2'
            className='form-select ps-1'
            onChange={handleStatus}
            value={active}
          >
            <option value=''>Todos</option>
            <option value='true'>Ativos</option>
            <option value='false'>Inativos</option>
          </select>
        </div>
      </div>

      <div className='ms-auto'>
        <button className='btn btn-primary' onClick={handleFormulario}>
          Novo Formulario
        </button>
      </div>
      {/*  <Modal show={showModal} onHide={handleCloseModal} centered size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{'Novo Formulário'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Inputs />
        </Modal.Body>
      </Modal> */}
    </div>
  )
}

export {FormulariosListSearchComponent}
