import {Editor} from '@tinymce/tinymce-react'

interface TextEditorProps {
  anotacao: string
  value: (content: string) => void
  disabled?: boolean // Add the disabled property
}

const TextEditorInput: React.FC<TextEditorProps> = ({value, anotacao, disabled = false}) => {
  const handleEditorChange = (newContent: string) => {
    value(newContent)
  }

  return (
    <div style={{overflowY: 'auto'}}>
      <Editor
        apiKey='b3kr4jc0xuxie3hbbkg90coaz2upbtb9exea7afa7rroyzrb'
        init={{
          language: 'pt_BR',
          // ... other initialization options
        }}
        disabled={disabled}
        onEditorChange={handleEditorChange}
        value={anotacao}
        initialValue=''
      />
    </div>
  )
}

export default TextEditorInput
