// @ts-nocheck
import { Column } from 'react-table'
import { EmpresaInfoCell } from './EmpresaInfoCell'
import { EmpresaSelectionCell } from './EmpresaSelectionCell'
import { EmpresaCustomHeader } from './EmpresaCustomHeader'
import { EmpresaSelectionHeader } from './EmpresaSelectionHeader'
import { EmpresasActionsCell } from './EmpresaActionsCell'

const empresasColumns: ReadonlyArray<Column<Empresa>> = [
  {
    Header: (props) => <EmpresaSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <EmpresaSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <EmpresaCustomHeader tableProps={props} title='Empregador ASO' className='min-w-125px' />,
    id: 'nome',
    accessor: 'nome'
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='CNPj, CPF ou CAEPF' className='min-w-125px' />
    ),
    accessor: 'documento',
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='Empresa vínculo' className='min-w-125px' />
    ),
    accessor: 'empresa.nomeFantasia',
  },
  {
    Header: (props) => (
      <EmpresaCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <EmpresasActionsCell id={props.data[props.row.index].id} />,
  },
]

export { empresasColumns }
