import { FC,  useEffect,  } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG} from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import Swal from 'sweetalert2'
import { deleteRiscoCliente } from '../../../empresas-list/core/_requests'
import { useQueryResponse } from '../../core/QueryResponseProvider'
type Props = {
  id: ID
}

const RiscoActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const {refetch} = useQueryResponse()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem  = async () => {
    await deleteRiscoCliente(id).then(() => {
      refetch()
      Swal.fire('Removido com sucesso!', '', 'success')
    }).catch((error) => {
      Swal.fire('Não foi possível remover o risco', '', 'error')
    })
  }


  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}       
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Alterar
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => deleteItem()}>
            Remover
          </a>
        </div>       
      </div>
      {/* end::Menu */}
    </>
  )
}

export { RiscoActionsCell }
