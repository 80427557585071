import { FC} from "react";
import { AtendimentoIniciado } from "../../../core/_models";



type Props = {
  atendimento: AtendimentoIniciado
}

const DataColumn: FC<Props> = ({atendimento}) => {
  const data = atendimento?.inicio !== null ? atendimento.inicioFormatado : atendimento.solicitacaoFormatada;

  return (
    <p>{data}</p>
  );
}

export {DataColumn}
