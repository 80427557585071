import { EspecialidadeListSearchComponent } from "./EspecialidadeListSearchComponent"

const EspecialidadeListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <EspecialidadeListSearchComponent />
    </div>
  )
}

export {EspecialidadeListHeader}
