/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useRef, useState } from 'react'

import AsyncSelect from 'react-select/async'
import { Empresa } from '../../../empresa/empresas-list/core/_models'
import { getEmpresas } from '../../../empresa/empresas-list/core/_requests'
import { useFormik } from 'formik'
import { useListView } from '../core/ListViewProvider'
import { vincularPacienteEmpresa } from '../core/_requests'

const initialValues = {
  prontuario: '',
}
type Props = {
  idEmpresa: string,
  cpf: string,
  nome: string,
  onCancel: Function
}
function refreshPage() {
  window.location.reload();
}
var msgErro = ''
var showDiv = false
const PacienteProntuarioForm: FC<Props> = ({ idEmpresa, cpf, nome, onCancel }) => {
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [empresa, setEmpresa] = useState([])
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()

  //consulta pelo nome da empresa
  const INITIAL_DATA = {
    value: 0,
    label: 'Digite o nome da empresa',
  };

  const [selectData, setselectData] = useState(INITIAL_DATA);
  const mapResponseToValuesAndLabels = (data: Empresa) => ({
    value: data.id,
    label: data.nomeFantasia,
  });
  const resetData = () => {
    initialValues.prontuario = ''
    setHasErrors(undefined)
    onCancel()
  }
  async function callApi(value: string) {
    if (value.length > 2) {
      const data = await getEmpresas('page=0&size=30&search=' + value)
        .then((response) => response.data?.map(mapResponseToValuesAndLabels))
        .then((final: any) =>
          final.filter((i: any) => String(i.label).toLowerCase().includes(value.toLowerCase()))
        );
      return data;
    }

  }
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        vincularPacienteEmpresa(cpf, values.prontuario, String(selectData.value))
          .then((result) => {
            refreshPage()
            setHasErrors(false)
          })
          .catch((error: any) => {
            setHasErrors(true)
            msgErro = error.response.data.data.errors.email
            showDiv = true
            setStatus('Não foi possível vincular o paciente')
          }).finally(() => {
            //handleClick(btnAddRef)
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  return (

    <div>
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>
            Paciente não possui cadastro.
          </div>
        </div>
      )}
      {hasErrors === false && (
        <div id="msgUser" className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Os dados foram alterados com sucesso.</div>
        </div>
      )}
      <div id="container">
        {/*This element's contents will be replaced with your component. --*/}
      </div>
      {/* begin::Form group */}
      <div>
        <form onSubmit={formik.handleSubmit} className='form'>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Nome</label><br />
            <label className='form-label fw-bolder text-gray-900 fs-6'>{nome}</label>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>CPF</label><br />
            <label className='form-label fw-bolder text-gray-900 fs-6'>{cpf}</label>
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Empresa</label>
            <AsyncSelect
              className='form-select-transparent'
              cacheOptions
              loadOptions={callApi}
              onChange={(data: any) => {
                setEmpresa(data.value)
                setselectData(data);
              }}
              value={selectData}
              defaultOptions
            //onInputChange={}
            />
          </div>
          {/* end::Form group */}
          <div className='d-flex flex-center flex-row-fluid pt-12'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-bs-dismiss='modal'
              onClick={() => resetData()}
            >
              Cancelar
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading} >
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { PacienteProntuarioForm }
