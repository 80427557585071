// @ts-nocheck
import { Column } from 'react-table'
import { AtendimentoIniciado } from '../../../../atendimento/core/_models'
import { HistoricoCustomHeader } from './HistoricoCustomHeader'
import { HistoricoActionsCell } from './HistoricoActionsCell'

const HistoricoColumns: ReadonlyArray<Column<AtendimentoIniciado>> = [
  // {
  //   Header: (props) => <HistoricoSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({ ...props }) => <HistoricoSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <HistoricoCustomHeader tableProps={props} title='Protocolo' className='min-w-125px' />
    ),
    id: 'protocolo',
    accessor: 'protocolo',
  },
  {
    Header: (props) => <HistoricoCustomHeader tableProps={props} title='Nome Paciente' className='min-w-100px' />,
    id: 'paciente.pessoa.nome',
    accessor: 'paciente.pessoa.nome'
  },
  {
    Header: (props) => (
      <HistoricoCustomHeader tableProps={props} title='Sinais' className='min-w-150px' />
    ),
    id: 'sinais',
    accessor: 'sinais',
  },
  {
    Header: (props) => (
      <HistoricoCustomHeader tableProps={props} title='Estado' className='min-w-125px' />
    ),
    id: 'estado.descricao',
    accessor: 'estado.descricao',
  },
  {
    Header: (props) => <HistoricoCustomHeader tableProps={props} title='Especialidade'  />,
    id: 'especialidade',
    accessor: 'especialidade.nome'
  },
  {
    Header: (props) => (
      <HistoricoCustomHeader tableProps={props} title='Data' className='min-w-125px' />
    ),
    id: 'inicioFormatado',
    accessor: 'inicioFormatado',
  },
  {
    Header: (props) => (
      <HistoricoCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <HistoricoActionsCell id={props.data[props.row.index].id} />,

  },
]

export { HistoricoColumns }
