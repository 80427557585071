import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask'
import * as Yup from 'yup'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { useAuth } from '../../../../auth'
import { PasswordMeterComponent } from '../../../../../../_metronic/assets/ts/components'
import { useSearchParams } from 'react-router-dom'
import { getEmpresas } from '../../../empresa/empresas-list/core/_requests'
import { Empresa } from '../../../empresa/empresas-list/core/_models'
import AsyncSelect from 'react-select/async'
import { AtendimentoIniciado } from '../../core/_models'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { AtendimentoListLoading } from '../components/loading/AtendimentoListLoading'

type Props = {
  isAtendimentoLoading: boolean
  atendimento: AtendimentoIniciado

}
function refreshPage() {
  window.location.reload();
}
const editAtendimentoSchema = Yup.object().shape({
  id: Yup.number(),
  nome: Yup.string()
    .min(3, 'Minimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('O campo nome é obrigatório'),
  email: Yup.string()
    .email('Formato inválido')
    .min(3, 'Minimo de 5 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .when('showEmail', {
      is: true,
      then: Yup.string().required('O campo e-mail é obrigatório')
    }),
  cpf: Yup.string().required('O campo CPF é obrigatório'),
  sexo: Yup.string().required('O campo sexo é obrigatório'),
  nascimento: Yup.string().required('O campo data de nascimento é obrigatório'),
  senha: Yup.string()
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Campo senha obrigatório'),
  confirmaSenha: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('senha')], "As senhas não são identicas"),
    }),
  prontuario: Yup.string()
    .min(3, 'Minimo de 3 caracteres')
})

const AtendimentoEditModalForm: FC<Props> = ({ atendimento, isAtendimentoLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  const [searchParams] = useSearchParams()
  const [empresa, setEmpresa] = useState([])
  const idEmpresa = String(searchParams.get("empresa"))
  isAtendimentoLoading = false;
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refreshPage()
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const formatDate = (dateStr: string) => {
    var data = new Date(dateStr)
    let newDate = `${data.getDay}/${data.getMonth}/${data.getFullYear} - ${data.getHours}:${data.getMinutes}`;
    return newDate;
  }
  return (
    <>
      <div id='kt_modal_add_atendimento'>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Protocolo</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{atendimento?.protocolo}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Estado</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{atendimento.estado?.descricao}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Data de Início</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{formatDate(String(atendimento?.inicio))}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Data de Término</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{formatDate(String(atendimento?.termino))}</span>
            </div>
          </div>
          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={isAtendimentoLoading}
            >
              Cancelar
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={isAtendimentoLoading}
            >
              <span className='indicator-label'>Enviar</span>
              {(isAtendimentoLoading) && (
                <span className='indicator-progress'>
                  Aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      {/* end::Actions */}
      {(isAtendimentoLoading) && <AtendimentoListLoading />}
    </>
  )
}

export { AtendimentoEditModalForm }
