import React, { FC, useEffect, useState } from 'react';
import { AtendimentoIniciado, Dispositivo, Informacoes } from '../../../atendimento/core/_models';
import { getAtendimentoById } from '../../../atendimento/core/_requests';
import { getArquivoAnexo, ID } from '../../../../../../_metronic/helpers';

const AtendimentoShowDispositivos: FC<{ atendimento?: AtendimentoIniciado }> = ({ atendimento }) => {
  const [loading, setLoading] = useState(false);
  const [dispositivos, setDispositivos] = useState<Dispositivo[]>([]);

  const atualizarAtendimento = async () => {
    if (!atendimento) return;

    setLoading(true);
    try {
      const result = await getAtendimentoById(atendimento.id);
      const dispAux = processInformacoes(result?.informacoes as Informacoes[]);
      setDispositivos(dispAux);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const processInformacoes = (informacoes: Informacoes[] = []) => {
    const dispAux: Dispositivo[] = [];

    informacoes.forEach((informacao) => {
      const leitura = {
        descricao: String(informacao.descricao),
        anexo: informacao.anexo || null,
        valor: String(informacao.valor),
        unidade: String(informacao.unidade),
        dataFormatada: String(informacao.dataFormatada),
        data: new Date(String(informacao.data)),
      };

      const dispositivoId = informacao.dispositivo?.id;
      let info = dispAux.find(d => d.id === dispositivoId);

      if (info) {
        info.leituras.push(leitura);
      } else {
        //@ts-ignore
        dispAux.push({
          id: Number(dispositivoId),
          descricao: String(informacao.dispositivo?.descricao),
          leituras: [leitura],
        });
      }
    });
    

    return dispAux;
  };


  useEffect(() => {
    if (atendimento) {
      atualizarAtendimento();
    }
  }, [atendimento]);



  return (
    <div className='hover-scroll-overlay-y' data-kt-scroll='true' data-kt-scroll-height='auto' data-kt-scroll-offset='5px'>
      <div>
        <button type='button' className='btn btn-primary mb-3' disabled={loading} onClick={atualizarAtendimento}>
          {loading ? (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Aguarde... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            'Atualizar Informações'
          )}
        </button>
      </div>
      <div className='d-flex flex-row-fluid'>
        <ul>
          {dispositivos.map((dispositivo) => (
            <li key={dispositivo.id as ID}>
              {dispositivo.descricao}
              <ul>
                {dispositivo.leituras.map((leitura: { anexo: ID; descricao: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; valor: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; unidade: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; dataFormatada: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => {
                  return (
                    <li key={index}>
                     
                      {!!leitura.anexo ? (
                        <>
                         {leitura.descricao}: ({leitura.dataFormatada}) 
                         <button className='btn btn-primary btn-sm m-2' onClick={() => getArquivoAnexo(atendimento?.id, leitura.anexo)}>Ver imagem</button>
                        </>
                        
                      ) : (
                        <> {leitura.descricao}: {leitura.valor} {leitura.unidade} ({leitura.dataFormatada})</>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export { AtendimentoShowDispositivos };
