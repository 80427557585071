import { KTCard } from "../../../../../_metronic/helpers"
import { EspecialidadeListHeader } from "./component/header/EspecialidadeListHeader"
import { ListViewProvider} from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import {  EspecialidadesTable } from "./table/EspecialidadesTable"



const EspecialidadeList = () => {
    return (
      <>
        <KTCard>
          <EspecialidadeListHeader />
          <EspecialidadesTable />
        </KTCard>
      </>
    )
  }
  
  const EspecialidadeListWrapper = () => (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <EspecialidadeList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
  
  export {EspecialidadeListWrapper}