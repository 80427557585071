import {EmpresasListSearchComponent} from './EmpresasListSearchComponent'

const EmpresasListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <EmpresasListSearchComponent />
    </div>
  )
}

export {EmpresasListHeader}
